import http from "@/http";

export class Getnet {
  paymentId = "";

  storeId = "";

  cardData = {};

  constructor(storeId, paymentId) {
    this.paymentId = paymentId;
    this.storeId = storeId;
    this.cardData = {};
  }

  async encryptCard(cardData) {
    this.cardData = cardData;

    const url = this.#getUrl("tokens/card");
    const card = await http.post(url, { card_number: this.cardData.cardNumber })
      .then((r) => r.data)
      .then((data) => {
        if (data.number_token) {
          return { encryptedCard: data.number_token };
        }
        return {
          hasErrors: true,
          errors: [{
            code: "INVALID_CARD_NUMBER", message: "Número do cartão inválido",
          }],
        };
      })
      .catch(() => {
        return {
          hasErrors: true,
          errors: [{
            code: "INTERNAL_SERVER_ERROR", message: "Verifique os dados e tente novamente",
          }],
        };
      });

    if (card.hasErrors) {
      const errors = card.errors.map?.((e) => e.message).join?.(", ");
      throw new Error(
        `Não foi possível validar os dados do cartão: ${errors}`
      );
    }

    this.cardData.encrypted = card.encryptedCard;
    return this.cardData;
  }

  #getUrl(path = "") {
    const id = this.paymentId;
    return `api/store/${this.storeId}/payment-methods/${id}/${path}`;
  }
};

export default Getnet;
