var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"href":`${_vm.baseUrl}/item?id=${_vm.item.id}`,"height":_vm.cardHeight,"ripple":false,"elevation":"1"}},[_c('div',{staticClass:"pt-3",style:(`background: ${_vm.hasPicture(_vm.computedItem) ? '' : '#eaedf1'};`)},[_c('v-img',{attrs:{"height":_vm.imageHeight,"lazy-src":_vm.getPicture(_vm.computedItem, true),"src":_vm.getPicture(_vm.computedItem),"contain":"contain"}})],1),_c('v-card-text',[_c('div',{class:[
          'item-title',
          'grey--text',
          'text--darken-3',
          _vm.computedItem.discountInfo ? 'two-lines' : 'three-lines',
        ]},[_vm._v(" "+_vm._s(_vm.$utils.titleCase(_vm.computedItem.name))+" ")]),(_vm.hasAttrs(_vm.computedItem))?_c('div',{class:[
          'item-title',
          'item-subtitle mt-1',
          'grey--text',
          'text--darken-1',
          _vm.computedItem.discountInfo ? 'two-lines' : 'three-lines',
        ]},[_vm._v(" "+_vm._s(_vm.getAttrs(_vm.computedItem))+" ")]):_vm._e()]),_c('v-card-text',{staticStyle:{"position":"absolute","bottom":"0px"}},[(
          (_vm.computedItem.promotionalPrice || _vm.computedItem.discountInfo) &&
          _vm.hasEstoque(_vm.computedItem) &&
          !_vm.item.listPrice
        )?_c('div',{class:_vm.getItemClass(_vm.computedItem),style:(_vm.getItemStyle(_vm.computedItem))},[_vm._v(" "+_vm._s(_vm.getItemValue(_vm.computedItem))+" ")]):_vm._e(),_c('div',{class:_vm.getItemClass(),style:(_vm.getItemStyle())},[_vm._v(" "+_vm._s(_vm.getItemValue( _vm.computedItem, _vm.computedItem.promotionalPrice, _vm.computedItem.discountInfo ))+" ")]),(_vm.computedItem.discountInfo)?_c('div',{class:['green--text', 'discount-info-text']},[_vm._v(" "+_vm._s(_vm.computedItem.discountInfo.label)+" ")]):_vm._e(),(_vm.computedItem.discountInfo)?_c('div',{class:['grey--text', 'text--darken-3', 'discount-info-subtext']},[_vm._v(" ou "+_vm._s(_vm.getItemSubValue(_vm.computedItem))+" nas demais formas ")]):_vm._e()]),(_vm.hasEstoque(_vm.computedItem))?_c('v-btn',{class:_vm.cartBtnClass,attrs:{"color":"primary","icon":"","title":"Por no carrinho"},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddCart.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-cart-arrow-down")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }