import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import VueTheMask from "vue-the-mask";
import { PiniaVuePlugin } from "pinia";

import EventBus from "./events";
import VueAlert from "./alert";
import VueBottomSheet from "./bottomSheet";
import VueColors from "./colors";
import VueDay from "./day";
import VueHelper from "./helper";
import VueInputAlert from "./inputAlert";
import VueInputAlertChange from "./inputAlertChange";
import VueDelivery from "./delivery";
import VueLocation from "./location";
import VueMessages from "./messages";
import VueRegisterTheme from "./registerTheme";
import VueSnackbar from "./snackbar";
import VueUtils from "./utils";
import VueVars from "./vars";
import "./registerServiceWorker";
import "./vueCurrencyInput";

Vue.use(PiniaVuePlugin);
Vue.use(EventBus);
Vue.use(VueAlert);
Vue.use(VueBottomSheet);
Vue.use(VueColors);
Vue.use(VueDay);
Vue.use(VueHelper);
Vue.use(VueInputAlert);
Vue.use(VueInputAlertChange);
Vue.use(VueDelivery);
Vue.use(VueLocation);
Vue.use(VueObserveVisibility);
Vue.use(VueMessages);
Vue.use(VueRegisterTheme);
Vue.use(VueSnackbar);
Vue.use(VueTheMask);
Vue.use(VueUtils);
Vue.use(VueVars);
