<template>
  <v-row justify="center">
    <v-dialog
      :value="accountStore.verifiedAccountDialog"
      @input="accountStore.setVerifiedAccountDialog($event)"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5"> Olá! 👋 </v-card-title>
        <v-card-text class="text-subtitle-1 mt-2">
          <div>
            Sua conta <strong>{{ config.storeTitle }}</strong> foi verificada
            com <strong>sucesso</strong>.
          </div>
          <div class="mt-1">Aproveite as melhores ofertas 🤩🤩 🥳🥳</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="mb-2" color="primary" text @click="handleClick">
            Vamo lá 🚀
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";
import { useAccountStore } from "@/storex/accountStore";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "verified-account-dialog",

  setup() {
    const config = useConfigStore();
    const accountStore = useAccountStore();
    return {
      accountStore,
      config,
    };
  },

  methods: {
    handleClick() {
      this.accountStore.setVerifiedAccountDialog(false);
      if (this.$store.getters.isAuthenticated) {
        this.$router.replace({ name: "store" });
      } else {
        this.$router.replace({ name: "account", query: { login: true } });
      }
    },
  },
});
</script>
