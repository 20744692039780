export default {
  shopping: (state, value) => {
    state.total = value?.total ?? 0;
    state.items = value?.items || [];
  },

  loading: (state, value) => {
    state.loading = value;
  },
};
