import { defineStore } from "pinia";

export const useValidationStore = defineStore("validationStore", {
  state: () => ({
    loading: true,
    ready: false,
    data: [
      {
        btn: "Verificar email",
        icon: "mdi-email-alert-outline",
        key: "status",
        message:
          "Seu email de cadastro ainda não foi verificado. Por favor, acesse seu email para confirmar seu cadastro.",
        visible: false,
      },
      {
        btn: "Contato loja",
        icon: "mdi-briefcase-clock-outline",
        key: "company",
        message:
          "Seu cadastro pessoa jurídica ainda está com aprovação pendente. Entre em contato com a loja para verificar a situação.",
        visible: false,
      },
      {
        btn: "SALVAR",
        icon: "mdi-account-alert-outline",
        key: "account",
        message: "Complete os dados cadastrais abaixo",
        visible: false,
      },
    ],
  }),

  actions: {
    validate(user) {
      const data = [...this.data];
      try {
        const validName = window.$utils.validateFullName(user?.name);
        const pendingUserData = !user?.cpfCnpj || !validName || !user?.phone || !user?.postalCode;
        data[0].visible = !user?.emailVerifiedAt;
        if (user?.company) {
          data[1].visible = !user.companyVerifiedAt;
        }
        data[2].visible = pendingUserData;
        const userValid =
          user?.emailVerifiedAt &&
          !!user?.cpfCnpj &&
          validName &&
          !!user?.phone &&
          !!user?.postalCode;

        this.data = data;

        if (user?.company) {
          this.ready = userValid && !!user.companyVerifiedAt;
        } else {
          this.ready = userValid;
        }
      } catch {
        // ignore
      } finally {
        this.loading = false;
      }
    },

    setLoading(data) {
      this.loading = data;
    },

    setReady(data) {
      this.ready = data;
    },
  },
});
