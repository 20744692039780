<template>
  <v-list>
    <v-subheader>{{ listTitle }}</v-subheader>
    <v-list-item
      :key="item.title"
      :href="item.href"
      @click="() => handleClick(item)"
      v-for="item in list"
    >
      <v-list-item-avatar>
        <v-avatar size="36px" tile="tile">
          <v-icon :color="item.color" v-text="item.icon" size="26"></v-icon>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </v-list-item>
    <v-list-item key="blank">
      <v-list-item-title>{{ "" }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "app-bottom-list",
  props: ["options"],

  computed: {
    list() {
      return this.options?.values || [];
    },

    listTitle() {
      return this.options?.title || "";
    },
  },

  methods: {
    handleClick(v) {
      if (v.href) {
        return;
      }
      this.$bottomSheet.dismiss();
      requestAnimationFrame(() => {
        v?.onPress?.();
      });
    },
  },
});
</script>
