<template>
  <div class="text-center">
    <v-dialog :value="dialog.visible" @input="dialog.dismiss" width="500">
      <v-card>
        <v-card-title v-if="dialog.title" class="text-h6">
          {{ dialog.title }}
        </v-card-title>

        <v-card-text class="text-body-1">
          <div v-html="dialog.message"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-for="btn in dialog.actions"
            :key="`dialog-action-${btn.text}`"
            :color="btn.color || 'primary'"
            text
            @click="handleClick(btn)"
          >
            {{ btn.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useDialogStore } from "../storex/dialogStore";

export default defineComponent({
  setup() {
    const dialog = useDialogStore();

    const handleClick = (btn) => {
      if (btn.handler) {
        btn.handler();
      }
      dialog.dismiss();
    };

    return { dialog, handleClick };
  },
});
</script>
