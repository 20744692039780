import brands from "./brandsSchema";
import config from "./configSchema";
import coupon from "./couponSchema";
import discountRules from "./discountRulesSchema";
import items from "./itemsSchema";
import store from "./storeSchema";
import user from "./userSchema";
import pet from "./petSchema";
import appointment from "./appointmentSchema";

export default {
  brands,
  config,
  coupon,
  discountRules,
  items,
  store,
  user,
  pet,
  appointment,
};
