import storage from "../storage";

export default {
  "modal-container": (state, value) => {
    state.modalContainer = value;
  },
  "app-bottom-sheet": (state, value) => {
    state.appBottomSheet = value;
  },
  "map-view-modal": (state, value) => {
    state.mapViewModal = value;
  },
  "delivery-modal": (state, value) => {
    state.deliveryModal = value;
  },
  appBadge(state, value) {
    state.appBadge = value;
    storage.setAppBadge(value);
  },
  settings(state, value) {
    state.settings = value;
    storage.setSettings(value);
  },
  appLoading(state, value) {
    if (!value) {
      state.appLoadingLabel = "";
    }
    state.appLoading = value;
  },
  appLoadingLabel(state, value) {
    state.appLoadingLabel = value;
  },
  accessToken(state, value) {
    state.accessToken = value;
    state.isAuthenticated = !!value;
    storage.setAccessToken(value);
  },
  refreshToken(state, value) {
    state.refreshToken = value;
    storage.setRefreshToken(value);
  },
  // Store
  "order-success": (state, value) => {
    state.orderSuccess = !!value;
  },
};
