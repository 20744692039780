import { gql } from "@apollo/client/core";

export const BRANDS_QUERY = gql`
  query($first: Int! $page: Int! $storeId: ID!) {
    brands(
      first: $first
      page: $page
      storeId: $storeId
      orderBy: [
        {
          column: "sequence"
          order: ASC
        }
      ]
      where: {
        AND: [
          {
            column: ACTIVE
            operator: EQ
            value: true
          }
          {
            column: SEQUENCE
            operator: IS_NOT_NULL
          }
        ]
      }
    ) {
      data {
        id
        name
        imageUrl
        active
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
`;

const schema = {
  BRANDS_QUERY,
};

export default schema;
