/* eslint-disable no-underscore-dangle */
import { AddressFactory } from "@/lib/account";

function ShippingFactory(options, addressOptions, instance) {
  this.options = options;
  this.addressOptions = new AddressFactory(addressOptions);
  this.address = instance?.address || null;
  this.shipping = instance?.shipping || null;
}

ShippingFactory.prototype = {
  setAddress(v) {
    if (!v) {
      this.address = null;
    } else if (
      this.shipping?.type === "delivery" || this.shipping?.type === "shipping"
    ) {
      this.address = {
        id: v.__typename === "UserAddress" ? v.id : null,
        title: v.title,
        subtitle: v.subtitle,
      };
    }
    this.setAmount(null);
  },
  setAmount(v) {
    if (this.shipping) {
      this.shipping.amount = v;
    }
  },
  setShipping(v) {
    this.address = null;
    this.shipping = JSON.parse(JSON.stringify(v));
  },

  getAmount() {
    if (!this.shipping || !this.address) {
      return null;
    }
    if (this.shipping.amount === null || this.shipping.amount === undefined) {
      return null;
    }

    const v = this.shipping.amount;
    const err = v < 0;
    let title;

    if (err) {
      title = "Não entregamos nesta região";
    } else if (v > 0) {
      title = `Frete ${window.$utils.BRL(v)}`;
    } else {
      title = "Frete grátis";
    }

    return {
      action: err ? "mdi-alert-circle-outline" : "mdi-check-circle-outline",
      class: err ? "red--text" : "green--text",
      subtitle: err ? "Selecione retirar na loja" : undefined,
      title,
    };
  },

  isPickup() {
    return this.shipping?.type === "pickup";
  },

  isReady() {
    if (this.isPickup()) {
      return true;
    }
    if (this.shipping && this.address) {
      const v = this.shipping.amount ?? 0;
      return v > -1;
    }
    return false;
  },

  get shippingTitleClass() {
    return !this.shipping ? "red--text" : undefined;
  },

  get addressTitleClass() {
    return !this.address ? "red--text" : undefined;
  },

  get shippingTitle() {
    return this.shipping?.label || "Escolha a forma de entrega";
  },

  get addressTitle() {
    return this.address?.title || "Selecione o endereço para entrega";
  },

  get addressSubtitle() {
    return this.address?.subtitle;
  },

  get addressVisible() {
    return this.shipping && this.shipping?.type !== "pickup";
  },
};

export default ShippingFactory;
