import http from "@/http";
import schema from "@/http/apollo/schema";

const PAGSEGURO_SCRIPT = window.Smart.env.PAGSEGURO_SCRIPT;
let pagseguroInstance;

export function getPagSeguro() {
  return new Promise((resolve, reject) => {
    if (pagseguroInstance) {
      resolve(pagseguroInstance);
      return;
    }
    if (!PAGSEGURO_SCRIPT) {
      resolve(null);
      return;
    }
    const pagScript = document.createElement("script");
    document.head.appendChild(pagScript);
    pagScript.setAttribute("src", PAGSEGURO_SCRIPT);
    pagScript.onload = () => {
      pagseguroInstance = window.PagSeguro;
      resolve(pagseguroInstance);
    };
    pagScript.onerror = () => {
      reject(new Error("PagSeguro script not loaded"));
    };
  });
}

export class PagSeguro {
  session = "";

  publicKey = "";

  paymentId = "";

  storeId = "";

  cardData = {};

  constructor(storeId, paymentId) {
    this.paymentId = paymentId;
    this.storeId = storeId;
    this.session = null;
    this.publicKey = null;
    this.cardData = {};
  }

  async authenticate3DS(cardData, orderAmountValue) {
    await this.getSession();
    this.cardData = cardData;

    const { data: { me } } = await window.$vm.$apollo.query({
      query: schema.user.ME,
    });
    const { data: { address } } = await window.$vm.$apollo.query({
      query: schema.user.ADDRESS,
    });

    let billingAddress = address.addresses.find((v) => {
      if (v.id === this.cardData.billingAddressId) {
        return v;
      }
      if (v.type === "cobrança") {
        return v;
      }
      return null;
    }) || address;

    billingAddress = {
      street: billingAddress.address,
      number: billingAddress.addressNumber,
      complement: billingAddress.complement,
      regionCode: billingAddress.state,
      country: "BRA",
      city: billingAddress.city,
      postalCode: billingAddress.postalCode?.replace?.(/\D/g, ""),
    };

    let tel = me.phone.replace(/\D/g, "");
    let country = "55";
    let area = "";
    if (tel.length > 11) {
      country = tel.substring(0, 2);
      area = tel.substring(2, 4);
      tel = tel.substring(4);
    } else {
      area = tel.substring(0, 2);
      tel = tel.substring(2);
    }

    const request = {
      data: {
        customer: {
          name: me.name,
          email: me.email,
          tax_id: me.cpfCnpj?.replace?.(/\D/g, ""),
          phones: [
            {
              country,
              area,
              number: tel,
              type: "MOBILE"
            },
          ]
        },
        paymentMethod: {
          type: "DEBIT_CARD",
          installments: 1,
          card: {},
        },
        amount: {
          value: orderAmountValue,
          currency: "BRL"
        },
        shippingAddress: billingAddress,
        dataOnly: false
      }
    };

    if (this.cardData.userPaymentCardId) {
      request.data.paymentMethod.card.id = this.cardData.userPaymentCardId;
    } else if (this.cardData.encrypted) {
      request.data.paymentMethod.card.encrypted = this.cardData.encrypted;
    } else {
      request.data.paymentMethod.card = {
        number: this.cardData.cardNumber,
        expMonth: this.cardData.expiryMonth,
        expYear: this.cardData.expiryYear,
        holder: {
          name: this.cardData.cardHolderName
        }
      };
    }

    const PagSeguroLib = await getPagSeguro();
    await PagSeguroLib.setUp({
      session: this.session,
      env: window.Smart.env.ENV,
    });

    const result3DS = await PagSeguroLib
      .authenticate3DS(request)
      .catch(() => null);

    if (result3DS && result3DS.status !== "AUTH_FLOW_COMPLETED") {
      let error = "Não foi possível validar os dados do cartão";
      if (result3DS.status === "AUTH_NOT_SUPPORTED") {
        error = "O cartão informado não é suportado no modo DÉBITO";
      } else if (result3DS.status === "CHANGE_PAYMENT_METHOD") {
        error = "Não foi possível validar, utilize outro cartão";
      } else if (result3DS.status === "REQUIRE_CHALLENGE") {
        error = "Modo não suportado, tente novamente mais tarde";
      }
      throw new Error(error);
    }

    if (result3DS && result3DS.id) {
      this.cardData.authenticationMethodId = result3DS.id;
      this.cardData.authenticationMethodType = "THREEDS";
      this.cardData.type = "DEBIT_CARD";
    }

    return this.cardData;
  }

  async encryptCard(cardData) {
    await this.getPublicKey();
    this.cardData = cardData;

    if (!this.publicKey) {
      throw new Error(
        "Não foi possível validar os dados do cartão: Invalid publicKey"
      );
    }

    const card = await getPagSeguro()
      .then((lib) => {
        return lib ? lib.encryptCard({
          publicKey: this.publicKey,
          holder: this.cardData.cardHolderName,
          number: this.cardData.cardNumber,
          expMonth: this.cardData.expiryMonth,
          expYear: this.cardData.expiryYear,
          securityCode: this.cardData.cardSecurityCode
        }) : null;
      })
      .catch(() => null);

    if (!card) {
      throw new Error([
        "Não foi possível validar os dados do cartão: ",
        "Configuração da loja inválida",
      ].join(""));
    }

    if (card.hasErrors) {
      const errors = card.errors.map?.((e) => e.message).join?.(", ");
      throw new Error(
        `Não foi possível validar os dados do cartão: ${errors}`
      );
    }

    this.cardData.encrypted = card.encryptedCard;
    return this.cardData;
  }

  async getPublicKey() {
    if (this.publicKey) {
      return this.publicKey;
    }

    try {
      const { data } = await http.get(this.#getUrl("public-key"));
      this.publicKey = data.public_key || null;
      await getPagSeguro();
    } catch {
      this.publicKey = null;
    }
    return this.publicKey;
  }

  async getSession() {
    if (this.session) {
      return this.session;
    }

    try {
      const { data } = await http.get(this.#getUrl("sessions"));
      this.session = data.session || null;
      await getPagSeguro();
    } catch {
      this.session = null;
    }
    return this.session;
  }

  #getUrl(path = "") {
    const id = this.paymentId;
    return `api/store/${this.storeId}/payment-methods/${id}/${path}`;
  }
};

export default getPagSeguro;
