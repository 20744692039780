import { gql } from "@apollo/client/core";

export const ADDRESS = gql`
  {
    address: me {
      province
      postalCode
      complement
      address
      addresses {
        province
        postalCode
        complement
        address
        deleted
        id
        latitude
        longitude
        city
        addressNumber
        type
        state
        references
        latitude
        longitude
      }
      id
      city
      addressNumber
      state
      references
      latitude
      longitude
    }
  }
`;

export const ME = gql`
  {
    me {
      province
      postalCode
      complement
      updatedAt
      cpfCnpj
      email
      address
      id
      avatar
      city
      name
      addressNumber
      phone
      state
      emailVerifiedAt
      company
      companyVerifiedAt
      companyName
      companyCreatedAt
      purchaseResponsibleName
    }
  }
`;

export const CARDS = gql`
  {
    cards: me {
      paymentCards(first: 10) {
        data {
          id
          createdAt
          externalId
          cardBrand
          cardFirstDigits
          cardLastDigits
          cardExpYear
          cardExpMonth
          cardHolderName
          billingAddressId
          isDefault
        }
      }
    }
  }
`;

export const SET_DEFAULT_CARD = gql`
  mutation($id: Int!) {
    setPaymentCardAsDefault(
      id: $id
    )
  }
`;

export const REMOVE_CARD = gql`
  mutation($id: Int!) {
    unsetPaymentCard(
      id: $id
    )
  }
`;

export const NOTIFICATIONS = gql`
  query getNotifications(
    $first: Int!
    $page: Int!
  ) {
    me {
      notifications(
        first: $first
        page: $page
      ) {
        data {
          id
          createdAt
          type
          title
          message
          payload
          readed
        }
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
      }
    }
  }
`;

export const EMAIL = gql`
  {
    me {
      email
    }
  }
`;

export const NOME_DOCUMENTO = gql`
  {
    me {
      cpfCnpj
      name
    }
  }
`;

export const TELEFONE = gql`
  {
    me {
      phone
    }
  }
`;

export const CEP_DOCUMENTO_TELEFONE = gql`
  {
    me {
      postalCode
      cpfCnpj
      name
      phone
      emailVerifiedAt
      company
      companyVerifiedAt
      companyName
    }
  }
`;

export const UNREAD_NOTIFICATIONS = gql`
  query unreadNotifications {
    total: me {
      unreadNotifications
    }
  }
`;

export const READ_NOTIFICATIONS = gql`
  mutation {
    readNotifications
  }
`;

export const SET_ENDERECO = gql`
  mutation (
    $id: ID
    $province: String
    $postalCode: String
    $complement: String
    $address: String
    $latitude: Float
    $longitude: Float
    $city: String
    $addressNumber: String
    $references: String
    $type: String!
    $state: String
  ) {
    address: setAddress(
      id: $id
      province: $province
      postalCode: $postalCode
      complement: $complement
      address: $address
      latitude: $latitude
      longitude: $longitude
      city: $city
      addressNumber: $addressNumber
      references: $references
      type: $type
      state: $state
    ) {
      id
    }
  }
`;

export const UNSET_ENDERECO = gql`
  mutation ($id: ID!) {
    success: unsetAddress(id: $id)
  }
`;

export const USER_MUTATION = gql`
  mutation (
    $province: String
    $postalCode: String
    $complement: String
    $cpfCnpj: String
    $address: String
    $latitude: Float
    $longitude: Float
    $name: String
    $city: String
    $addressNumber: String
    $references: String
    $phone: String
    $state: String
  ) {
    me: updateMe(
      province: $province
      postalCode: $postalCode
      complement: $complement
      cpfCnpj: $cpfCnpj
      address: $address
      latitude: $latitude
      longitude: $longitude
      city: $city
      name: $name
      addressNumber: $addressNumber
      references: $references
      phone: $phone
      state: $state
    ) {
      id
    }
  }
`;

export const SET_ENDPOINT = gql`
  mutation(
    $type: UserEndpointType!
    $registration: Json!
    $description: String
    $set: Boolean!
    $unset: Boolean!
  ) {
    unset: unsetUserEndpoint(
      type: $type
      registration: $registration
    ) @include(if: $unset)
    set: setUserEndpoint(
      type: $type
      registration: $registration
      description: $description
    ) @include(if: $set)
  }
`;

export const PROFILE_IMAGE = gql`
  mutation ($file: Upload!) {
    success: updateUserAvatar(file: $file)
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation(
    $currentPassword: String!
    $newerPassword: String!
  ) {
    success: updateUserPassword(
      currentPassword: $currentPassword
      newerPassword: $newerPassword
    )
  }
`;

const schema = {
  ADDRESS,
  CEP_DOCUMENTO_TELEFONE,
  CARDS,
  EMAIL,
  ME,
  NOME_DOCUMENTO,
  NOTIFICATIONS,
  PROFILE_IMAGE,
  READ_NOTIFICATIONS,
  SET_ENDERECO,
  SET_ENDPOINT,
  TELEFONE,
  UNREAD_NOTIFICATIONS,
  UNSET_ENDERECO,
  UPDATE_USER_PASSWORD,
  USER_MUTATION,
  SET_DEFAULT_CARD,
  REMOVE_CARD,
};

export default schema;
