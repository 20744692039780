import { onMounted, ref, onBeforeUnmount } from "vue";
import { GraphQLClient } from "graphql-request";
import { isTokenValid } from "@/http/apollo/anonymously";

const url = `${window.Smart.env.BASE_URL}/${window.Smart.env.GRAPHQL_API}`;

const getRequestConfig = async () => {
  const $store = window.$vm.$store;
  let token = "";
  if ($store.getters.isAuthenticated) {
    if (!isTokenValid($store.getters.accessToken)) {
      await $store.dispatch("refresh");
    }
    token = $store.getters.accessToken;
  }
  if (!token) {
    throw new Error("Invalid token");
  }
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};

const fetcher = async (query) => {
  const config = await getRequestConfig();
  const client = new GraphQLClient(url, config);
  return client.request(query);
};

const configOptions = {
  dedupingInterval: 250,
};

export const useSWR = (key = "", options = configOptions) => {
  const data = ref();
  const error = ref();
  const timeout = ref();

  const fetchData = async () => {
    try {
      const response = await fetcher(key);
      data.value = response;
    } catch (e) {
      data.value = null;
      error.value = e?.message;
    }
  };

  const handleFetch = () => {
    clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      fetchData();
    }, options?.dedupingInterval ?? 250);
  };

  onMounted(() => {
    handleFetch();
    window.addEventListener("focus", handleFetch, false);
  });

  onBeforeUnmount(() => {
    clearTimeout(timeout.value);
    window.removeEventListener("focus", handleFetch, false);
  });

  return { data, error };
};