import storage from "@/plugins/storage";

const defaultSettings = {
  notification: false,
};

export default {
  // Getters
  appBadge() {
    const value = storage.getItem("app_badge");
    return value ? Number(value) : 0;
  },
  accessToken() {
    return storage.getItem("api:access_token");
  },
  refreshToken() {
    return storage.getItem("api:refresh_token");
  },
  userLocation() {
    return storage.getItem("user_location")
      ? JSON.parse(storage.getItem("user_location"))
      : null;
  },
  config() {
    return storage.getItem("app:config")
      ? JSON.parse(storage.getItem("app:config"))
      : null;
  },
  storeDetails() {
    return storage.getItem("store_details")
      ? JSON.parse(storage.getItem("store_details"))
      : null;
  },
  termsPolicy() {
    return storage.getItem("terms_policy")
      ? JSON.parse(storage.getItem("terms_policy"))
      : false;
  },

  settings() {
    const settings = storage.getItem("app:settings")
      ? JSON.parse(storage.getItem("app:settings"))
      : defaultSettings;
    return settings;
  },

  appendAuthUrl() {
    storage.getItem("api:append-auth-url") || "";
  },

  couponCode() {
    const value = storage.getItem("coupon/coupon_code");
    return value ? JSON.parse(value) : null;
  },

  // Setters
  setAppBadge(value) {
    if (!value) {
      storage.removeItem("app_badge");
    } else {
      storage.setItem("app_badge", `${value}`);
    }
  },
  setAccessToken(value) {
    if (!value) {
      storage.removeItem("api:access_token");
    } else {
      storage.setItem("api:access_token", value);
    }
  },
  setRefreshToken(value) {
    if (!value) {
      storage.removeItem("api:refresh_token");
    } else {
      storage.setItem("api:refresh_token", value);
    }
  },
  setConfig(value) {
    if (!value) {
      storage.removeItem("app:config");
    } else {
      storage.setItem("app:config", JSON.stringify(value));
    }
  },
  setUserLocation(value) {
    if (!value) {
      storage.removeItem("user_location");
    } else {
      storage.setItem("user_location", JSON.stringify(value));
    }
  },
  setStoreDetails(value) {
    if (!value) {
      storage.removeItem("store_details");
    } else {
      storage.setItem("store_details", JSON.stringify(value));
    }
  },
  setTermsPolicy(value) {
    if (!value) {
      storage.removeItem("terms_policy");
    } else {
      storage.setItem("terms_policy", JSON.stringify(value));
    }
  },
  setSettings(value) {
    storage.setItem("app:settings", JSON.stringify(value));
  },
  setAppendAuthUrl(value) {
    storage.setItem("api:append-auth-url", value);
  },

  setCouponCode(value) {
    if (!value) {
      storage.removeItem("coupon/coupon_code");
    } else {
      storage.setItem("coupon/coupon_code", JSON.stringify(value));
    }
  },

  // Options
  clear() {
    storage.clear();
  },
};
