<template>
  <div>
    <v-app-bar :color="config.themeColor" :dark="false" flat>
      <AppBarLogo />

      <template v-slot:extension v-if="appointmentRoute">
        <div class="toolbar-extension">
          <AppointmentsTabs />
        </div>
      </template>

      <v-spacer />

      <TopNavigation :data="topNavigationItems" />
    </v-app-bar>

    <app-alerts />

    <div style="margin-bottom: 90px">
      <slot></slot>
    </div>

    <v-bottom-navigation
      :value="bottomNavigationIndex"
      color="primary"
      fixed
      height="72px"
      grow
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-btn
        :to="navItem.to"
        v-for="navItem in bottomNavigationItems"
        :key="navItem.id"
      >
        <span>{{ navItem.title }}</span>
        <v-icon :size="navItem.icon.size">{{ navItem.icon.name }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AppAlerts from "@/components/Alerts";
import { useConfigStore } from "@/storex/configStore";
import AppBarLogo from "./AppBarLogo";
import TopNavigation from "./TopNavigation";
import AppointmentsTabs from "./AppointmentsTabs";

export default defineComponent({
  name: "AppointmentsLayout",
  components: {
    AppAlerts,
    AppBarLogo,
    AppointmentsTabs,
    TopNavigation,
  },

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    theme() {
      return this.config.theme;
    },
    dark() {
      return this.theme === "dark";
    },

    appointmentsActive() {
      return this.config.appointmentsActive;
    },

    bottomNavigationIndex() {
      const includes = (paths = []) => {
        for (let i = 0; i < paths.length; i += 1) {
          if (this.$route.path.includes(paths[i])) {
            return true;
          }
        }
        return false;
      };

      if (includes(["appointments", "pets", "vaccines"])) {
        return 0;
      }
      if (includes(["account", "notifications"])) {
        return 1;
      }
      if (includes(["about"])) {
        return 2;
      }
      return -1;
    },

    bottomNavigationItems() {
      const items = [
        {
          id: "bottom-navigation-item-store",
          title: this.$t("route.store.title"),
          to: "/",
          icon: {
            name: "mdi-store",
            size: 24,
          },
        },
        {
          id: "bottom-navigation-item-appointments",
          title: this.$t("route.appointment.title"),
          to: "/appointments",
          icon: {
            name: "mdi-paw",
            size: 24,
          },
        },
        {
          id: "bottom-navigation-item-account",
          title: this.$t("route.account.title"),
          to: "/account",
          icon: {
            name: "mdi-account",
            size: 24,
          },
        },
        {
          id: "bottom-navigation-item-about",
          title: this.$t("route.about.title"),
          to: "/about",
          icon: {
            name: "mdi-face-agent",
            size: 24,
          },
        },
      ];

      if (!this.config.ecommerceActive) {
        items.splice(0, 1);
      }

      return items;
    },

    topNavigationItems() {
      return [
        {
          id: "navigation-item-appointments",
          title: this.$t("route.appointment.title"),
          to: "/appointments",
          hidden: this.$vuetify.breakpoint.xsOnly,
          icon: {
            name: "mdi-paw",
            size: 22,
          },
        },
        {
          id: "navigation-item-account",
          title: this.$t("route.account.title"),
          to: "/account",
          hidden: this.$vuetify.breakpoint.xsOnly,
          icon: {
            name: "mdi-account",
            size: 22,
          },
        },
        {
          id: "navigation-item-notifications",
          title: this.$t("route.notifications.title"),
          to: "/notifications",
          badge: this.$store.getters.appBadge,
          hidden: false,
          icon: {
            name: "mdi-bell-ring",
            size: 22,
          },
        },
        {
          id: "navigation-item-about",
          title: this.$t("route.about.title"),
          to: "/about",
          hidden: this.$vuetify.breakpoint.smAndDown,
          icon: {
            name: "mdi-face-agent",
            size: 22,
          },
        },
      ].filter((item) => !item.hidden);
    },

    appointmentRoute() {
      if (!this.config.ecommerceActive) {
        return this.bottomNavigationIndex === 0;
      }
      return this.bottomNavigationIndex === 1;
    },
  },
});
</script>

<style scoped>
.menu-horizontal {
  display: flex;
  flex: 1;
  flex-direction: "column";
  justify-content: center;
}

.toolbar-extension {
  flex: 1;
}
</style>
