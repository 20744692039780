import { render, staticRenderFns } from "./AppMessages.vue?vue&type=template&id=5c609932&"
import script from "./AppMessages.vue?vue&type=script&lang=js&"
export * from "./AppMessages.vue?vue&type=script&lang=js&"
import style0 from "./AppMessages.vue?vue&type=style&index=0&id=5c609932&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VFlex,VLayout})
