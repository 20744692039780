import Vue from "vue";
import { getAppleAuth, signInWithApple } from "@/http/apple";
import { googleLogOut, signInWithGoogle } from "@/http/google";
import { useConfigStore } from "@/storex/configStore";

export default {
  async authenticate(context, provider) {
    if (context.getters.isAuthenticated) {
      return true;
    }

    if (provider === "google") {
      const ok = await signInWithGoogle(context);
      if (ok) {
        context.dispatch("shopping/setShoppingCartServer");
      }
      return ok;
    }

    if (provider === "apple") {
      if (!window.isNativeApp) {
        getAppleAuth();
      }
      context.commit("appLoading", true);
      context.commit("appLoadingLabel", "message.loading.auth");
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          signInWithApple(context)
            .then((r) => {
              if (!r) {
                reject(Error("Falha ao entrar com a conta Apple"));
              } else {
                context.dispatch("shopping/setShoppingCartServer");
                resolve(r);
              }
            })
            .catch(reject);
        }, 250);
      });
    }

    return false;
  },

  login(context, payload) {
    return new Promise((resolve, reject) => {
      if (context.getters.isAuthenticated) {
        resolve(true);
        return;
      }
      const config = useConfigStore();

      payload.data.grant_type = "password";
      payload.data.store_id = config.currentStore;

      Vue.prototype.$auth.login(payload.data, payload.requestOptions)
        .then(({ data }) => {
          context.commit("accessToken", data.access_token);
          context.commit("refreshToken", data.refresh_token);
          window.$snackbar.show({
            t: "message.success.auth.login",
            action: { text: "OK" },
          });
          context.dispatch("shopping/setShoppingCartServer");
          resolve(true);
        })
        .catch((error) => {
          context.commit("accessToken");
          context.commit("refreshToken");
          reject(error);
        });
    });
  },

  logout(context) {
    return new Promise((resolve) => {
      if (!context.getters.isAuthenticated) {
        resolve();
        return;
      }

      googleLogOut();

      // Vue.prototype.$auth.logout();
      context.dispatch("shopping/removeAllShoppingCart")
        .catch(() => null)
        .finally(() => {
          context.commit("accessToken");
          context.commit("refreshToken");
          resolve();
        });
    });
  },

  refresh(context) {
    return new Promise((resolve, reject) => {
      if (!context.getters.refreshToken) {
        context.dispatch("logout");
        reject();
        return;
      }

      Vue.prototype.$auth.login({
        grant_type: "refresh_token",
        refresh_token: context.getters.refreshToken,
      }).then(({ data }) => {
        context.commit("accessToken", data.access_token);
        resolve();
      }).catch(() => {
        context.dispatch("logout");
        reject();
      });
    });
  },
};
