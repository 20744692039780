import { defineStore } from "pinia";

export const useDialogStore = defineStore("dialog", {
  state: () => ({
    visible: false,
    title: "",
    message: "",
    actions: [],
  }),

  actions: {
    show({
      title = "",
      message = "",
      actions = [{
        text: "Fechar",
        color: "primary",
        handler: () => this.dismiss(),
      }],
    }) {
      this.visible = true;
      this.title = title;
      this.message = message;
      this.actions = actions;
    },

    dismiss() {
      this.visible = false;
      setTimeout(() => {
        this.title = "";
        this.message = "";
        this.actions = [];
      }, 250);
    },
  },
});