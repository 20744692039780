import { defineStore } from "pinia";
import { BRANDS_QUERY } from "@/http/apollo/schema/brandsSchema";
import { useConfigStore } from "./configStore";

export const useBrandsStore = defineStore("brandsStore", {
  state: () => ({
    data: [],
    loaded: false,
  }),

  getters: {
    hasBrands({ data }) {
      return !!data.length;
    },
  },

  actions: {
    async fetchData() {
      if (this.data.length) {
        return;
      }
      const config = useConfigStore();

      try {
        const query = BRANDS_QUERY;
        const variables = {
          first: 16,
          page: 1,
          storeId: config.currentStore,
        };

        const { data } = await window.$apollo.query({ query, variables });
        this.data = data.brands.data;
      } finally {
        setTimeout(() => {
          this.loaded = true;
        }, 300);
      }
    },

    resetLoader() {
      setTimeout(() => {
        this.loaded = false;
      }, 150);
    }
  },
});