export default {
  ASPECT_9_16: 0.5625,
  ASPECT_9_21: 0.428571428571429,
  ASPECT_16_9: 1.777777777777778,
  ASPECT_21_9: 2.333333333333333,
  ASPECT_43_9: 4.8,
  ASPECT_CALC_16_9: 0.315,
  ASPECT_CALC_21_9: 0.17857,
  ASPECT_CALC_43_9: 0.054253,
};
