import Vue from "vue";

const url = window.Smart.env.OAUTH_API;

const instance = {
  payload: null,
};

export const isTokenValid = (token) => {
  if (!token) {
    return false;
  }

  const data = token ? JSON.parse(atob(token.split(".")[1])) : null;
  const exp = (data ? (data.exp * 1000) : 0) - Date.now();
  const left = 5 * 60 * 1000;

  return exp > left;
};

export default {
  async accessToken() {
    try {
      let payload = await this.getInstance();

      if (!isTokenValid(payload.accessToken)) {
        instance.payload = null;
        payload = await this.getInstance();
      }

      return payload.accessToken;
    } catch (e) {
      instance.payload = null;
      return null;
    }
  },

  async getInstance() {
    if (!instance.payload) {
      instance.payload = await this.getPayload();
    }
    return instance.payload;
  },

  async getPayload() {
    try {
      const resp = await Vue.axios.post(url, { grant_type: "client_credentials" });
      return {
        accessToken: resp.data.access_token,
        refreshToken: resp.data.refresh_token,
      };
    } catch (e) {
      return null;
    }
  },
};
