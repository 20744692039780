import { render, staticRenderFns } from "./TopNavigation.vue?vue&type=template&id=625352f6&scoped=true&"
import script from "./TopNavigation.vue?vue&type=script&lang=js&"
export * from "./TopNavigation.vue?vue&type=script&lang=js&"
import style0 from "./TopNavigation.vue?vue&type=style&index=0&id=625352f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625352f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})
