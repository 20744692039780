import { defineStore } from "pinia";
import http from "@/http";
import schema from "@/http/apollo/schema";

const url = `${window.Smart.env.BASE_URL}/${window.Smart.env.REGISTER_API}`;
const msg = [
  "Cadastro realizado com sucesso!",
  "Você receberá um email para confirmar seu cadastro.",
  "Após confirmado, entre com seu email e senha.",
];
const errorMessage = "Não foi possível criar sua conta no momento. Tente novamente mais tarde.";

export const useAccountStore = defineStore("accountStore", {
  state: () => ({
    loaded: false,
    registerModal: false,
    verifiedAccountDialog: false,
    verificationAlert: false,
    user: {},
    userVerified: false,
    loadingUser: true,
  }),

  getters: {
    verifiedCompanyUser(state) {
      const { company, companyVerifiedAt } = state.user;
      return company && !!companyVerifiedAt;
    },
  },

  actions: {
    setRegisterModal(value) {
      this.registerModal = value;
    },

    setVerifiedAccountDialog(value) {
      this.verifiedAccountDialog = value;
    },

    async register(payload) {
      const isCompany = payload.isCompany;
      try {
        await http.post(url, {
          email: payload.email,
          password: payload.passwordVerify,
          password_confirmation: payload.passwordVerify,
          name: payload.name,
          phone: payload.phone,
          store_id: window.Smart.store?.id,
          ...(isCompany && {
            company_name: payload.companyName,
            cpf_cnpj: payload.cpfCnpj,
            company_created_at: window.$day(
              payload.companyCreatedAt, "DD/MM/YYYY",
            ).format("YYYY-MM-DD"),
            purchase_responsible_name: payload.purchaseResponsibleName,
          }),
        });
        setTimeout(() => {
          window.$messages.success({ text: msg.join(" "), duration: 15 * 1000 });
        }, 450);
      } catch (e) {
        throw new Error(
          e.response?.data?.message ||
          e.message ||
          errorMessage
        );
      }
    },

    async loadInitialUserData() {
      if (this.loaded) {
        return;
      }
      try {
        const { data } = await window.$apollo.query({ query: schema.user.ME });
        this.user = data.me;
        this.userVerified = !!data.me?.emailVerifiedAt;
      } catch {
        // ignore
      } finally {
        this.loaded = true;
      }
    },

    handleUserDetails(data) {
      const prevUser = JSON.parse(JSON.stringify(this.user));
      if (data === null) {
        this.user = {};
        this.userVerified = false;
        setTimeout(() => {
          prevUser.id && window.$events.$emit("user-change");
        }, 100);
      } else if (data) {
        this.user = data.me;
        this.userVerified = !!data.me?.emailVerifiedAt;
        setTimeout(() => {
          !prevUser.id && window.$events.$emit("user-change");
        }, 100);
      }
      this.loadingUser = false;
    },

    async getUserDetails() {
      try {
        const { data } = await window.$apollo.query({ query: schema.user.ME });
        this.handleUserDetails(data);
      } catch {
        this.handleUserDetails(null);
      } finally {
        this.loadingUser = false;
      }
    },
  },
});