<template>
  <div>
    <AppointmentsLayout v-if="!config.ecommerceActive">
      <slot></slot>
    </AppointmentsLayout>
    <StoreLayout v-else-if="!config.appointmentsActive">
      <slot></slot>
    </StoreLayout>
    <StoreAppointmentsLayout v-else>
      <slot></slot>
    </StoreAppointmentsLayout>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";
import StoreLayout from "./StoreLayout";
import StoreAppointmentsLayout from "./StoreAppointmentsLayout";
import AppointmentsLayout from "./AppointmentsLayout";

export default defineComponent({
  name: "app-layout",
  components: {
    AppointmentsLayout,
    StoreLayout,
    StoreAppointmentsLayout,
  },

  setup() {
    const config = useConfigStore();
    return { config };
  },
});
</script>
