const delivery = {
  show({ orderId }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("delivery-modal", { orderId });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("delivery-modal");
  },
};

window.$delivery = delivery;

export default {
  install(Vue) {
    Vue.prototype.$delivery = delivery;
  },
};
