/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import http from "./index";
import {
  postMessageAsync,
  postMessageSync,
  MessageEvents,
} from "../plugins/nativeBridge";

const OAUTH_API = window.Smart.env.OAUTH_API;
const STORE_ID = window.Smart?.store?.id;

function getGoogleAuth2() {
  return new Promise((resolve, reject) => {
    try {
      window.gapi.load("auth2", () => {
        const auth2 = window.gapi.auth2.init({
          client_id: window.Smart.env.GOOGLE_CLIENT_ID,
        });
        resolve(auth2);
      });
    } catch (e) {
      reject(e);
    }
  });
}

function getValueFromKey(obj, key) {
  if (typeof obj !== "object") {
    return null;
  }

  if (obj?.hasOwnProperty(key)) {
    return obj[key];
  }

  for (const i in obj) {
    const foundObj = getValueFromKey(obj[i], key);
    if (foundObj) {
      return foundObj;
    }
  }

  return null;
}

const getIdToken = async () => {
  if (window.isNativeApp) {
    return postMessageAsync(MessageEvents.AUTH_GOOGLE);
  }

  const auth2 = await getGoogleAuth2();
  let currentUser = null;

  if (auth2.isSignedIn.get()) {
    currentUser = auth2.currentUser.get();
  } else {
    currentUser = await auth2.signIn({ scope: "profile email" });
  }

  return getValueFromKey(currentUser, "access_token");
};

export async function signInWithGoogle(context) {
  try {
    const idToken = await getIdToken();

    context.commit("appLoading", true);
    context.commit("appLoadingLabel", "message.loading.auth");

    const { data } = await http.post(OAUTH_API, {
      grant_type: "social",
      provider: "google",
      access_token: idToken,
      store_id: STORE_ID,
    });

    if (data) {
      context.commit("accessToken", data.access_token);
      context.commit("refreshToken", data.refresh_token);
      window.$snackbar.show({
        t: "message.success.auth.login",
        action: { text: "OK" },
      });
      return true;
    }

    context.commit("accessToken");
    context.commit("refreshToken");
    window.$snackbar.show({
      t: "message.error.auth.google",
      action: { text: "OK" },
    });
    return false;
  } catch (e) {
    postMessageSync(MessageEvents.AUTH_ERROR, e?.message);
    window.$snackbar.show({
      t: "message.error.auth.google",
      action: { text: "OK" },
    });
    return false;
  } finally {
    setTimeout(() => {
      context.commit("appLoading", false);
    }, 250);
  }
}

export function googleLogOut() {
  if (window.isNativeApp) {
    postMessageSync(MessageEvents.AUTH_LOGOUT);
    return;
  }

  getGoogleAuth2().then((auth2) => {
    if (auth2.isSignedIn.get()) {
      auth2.signOut().catch(() => null);
    }
  }).catch(() => null);
}
