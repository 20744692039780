import VueJS from "vue";

const events = new VueJS();

window.$events = events;

export default {
  install(Vue) {
    Vue.prototype.$events = events;
  },
};
