export const APROVADO = "aprovado";
export const CANCELADO = "cancelado";
export const CONCLUIDO = "concluido";
export const FINALIZADO = "finalizado";
export const PAGAMENTO_PENDENTE = "pagamento_pendente";
export const PAGAMENTO_APROVADO = "pagamento_aprovado";
export const PAGAMENTO_AUTORIZADO = "pagamento_autorizado";
export const PAGAMENTO_REJEITADO = "pagamento_rejeitado";
export const PENDENTE = "pendente";
export const PROCESSANDO = "processando";
export const REJEITADO = "rejeitado";
export const SAIU_ENTREGA = "saiu_entrega";
export const EM_ROTA_ENTREGA = "em_rota_entrega";
export const ENTREGUE = "entregue";
export const NAO_ENTREGUE = "nao_entregue";

export default {
  getLabel(v) {
    switch (v) {
      case PENDENTE:
        return "Pedido criado";
      case PAGAMENTO_PENDENTE:
        return "Aguardando pagamento";
      case PAGAMENTO_APROVADO:
        return "Pagamento aprovado";
      case PAGAMENTO_AUTORIZADO:
        return "Pagamento autorizado";
      case PAGAMENTO_REJEITADO:
        return "Pagamento rejeitado";
      case CANCELADO:
        return "Cancelado";
      case PROCESSANDO:
        return "Processando pedido";
      case APROVADO:
        return "Aprovado";
      case SAIU_ENTREGA:
        return "Saiu para entrega";
      case EM_ROTA_ENTREGA:
        return "Entrega em andamento";
      case NAO_ENTREGUE:
        return "Pedido não entregue";
      case ENTREGUE:
        return "Entrega realizada";
      case CONCLUIDO:
        return "Concluído";
      case FINALIZADO:
        return "Finalizado";
      case REJEITADO:
        return "Pedido rejeitado";
      default:
        return "";
    }
  },
  getColor(v) {
    switch (v) {
      case PENDENTE:
        return "orange";
      case PAGAMENTO_PENDENTE:
        return "amber";
      case PAGAMENTO_APROVADO:
      case PAGAMENTO_AUTORIZADO:
      case PROCESSANDO:
      case APROVADO:
        return "green lighten-1";
      case PAGAMENTO_REJEITADO:
      case REJEITADO:
        return "red";
      case CANCELADO:
        return "grey";
      case SAIU_ENTREGA:
      case EM_ROTA_ENTREGA:
        return "light-blue lighten-1";
      case NAO_ENTREGUE:
        return "orange";
      case ENTREGUE:
        return "light-blue darken-1";
      case CONCLUIDO:
      case FINALIZADO:
        return "green darken-1";
      default:
        return "grey";
    }
  },
  getClass(v) {
    const s = "font-weight-bold";
    switch (v) {
      case PENDENTE:
        return `${s} orange--text`;
      case PAGAMENTO_PENDENTE:
        return `${s} amber--text`;
      case PAGAMENTO_APROVADO:
      case PAGAMENTO_AUTORIZADO:
      case PROCESSANDO:
      case APROVADO:
        return `${s} green--text text--lighten-1`;
      case PAGAMENTO_REJEITADO:
        return `${s} red--text`;
      case CANCELADO:
        return `${s} grey--text`;
      case SAIU_ENTREGA:
      case EM_ROTA_ENTREGA:
        return `${s} light-blue--text text--lighten-1`;
      case NAO_ENTREGUE:
        return `${s} orange--text`;
      case ENTREGUE:
        return `${s} light-blue--text text--darken-1`;
      case CONCLUIDO:
      case FINALIZADO:
        return `${s} green--text text--darken-1`;
      case REJEITADO:
        return `${s} red--text`;
      default:
        return `${s} grey--text`;
    }
  },
};
