const defaultThemes = {
  dark: {
    primary: "#0098db",
    secondary: "#ff7a2b",
    accent: "#fff",
    theme_color: "#0098db",
    background_color: "#fff",
    app_bar_theme: "dark",
  },
  light: {
    primary: "#0098db",
    secondary: "#ff7a2b",
    accent: "#fff",
    theme_color: "#0098db",
    background_color: "#fff",
    app_bar_theme: "dark",
  },
};

const registerTheme = (vue, theme) => {
  const t = theme || defaultThemes;
  vue.$vuetify.theme.themes.light.primary = t.light.primary;
  vue.$vuetify.theme.themes.light.secondary = t.light.secondary;
  vue.$vuetify.theme.themes.light.accent = t.light.accent;
  vue.$vuetify.theme.themes.dark.primary = t.dark.primary;
  vue.$vuetify.theme.themes.dark.secondary = t.dark.secondary;
  vue.$vuetify.theme.themes.dark.accent = t.dark.accent;
};

export default {
  install(Vue) {
    Vue.prototype.$registerTheme = registerTheme;
  },
};
