<template>
  <v-card
    :height="cardHeight"
    :href="`${baseUrl}/search?brand=${value}`"
    :ripple="false"
    class="brand-card"
    elevation="1"
  >
    <v-img :height="imageHeight" :src="src" contain="contain" v-if="src" />
    <div class="label-box" v-else>
      <div :class="labelClass" v-text="label"></div>
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "brand-card",
  props: {
    value: String,
    label: String,
    src: String,
  },

  computed: {
    cardHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100px";
      }
      return "180px";
    },

    imageHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "80px";
      }
      return "140px";
    },

    labelClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "label-text label-text-sm";
      }
      return "label-text label-text-lg";
    },

    baseUrl() {
      return `${this.$store.getters.baseUrl}/store`;
    },
  },
});
</script>

<style scoped>
.item-title {
  font-size: 1.3em;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.brand-card {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.label-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 290px;
}
.label-text {
  color: #212121;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.label-text-sm {
  font-size: 1.3rem;
  line-height: 26px;
  max-width: 148px;
}
.label-text-lg {
  font-size: 2.3rem;
  line-height: 44px;
  max-width: 260px;
}
</style>
