import validator from "./CardValidator";
import Order, { getOrderTotal } from "./OrderFactory";
import Payment from "./PaymentFactory";
import Shipping from "./ShippingFactory";
import status from "./orderStatus";

export const CardValidator = validator;
export const OrderFactory = Order;
export const PaymentFactory = Payment;
export const ShippingFactory = Shipping;
export const orderStatus = status;

export { getOrderTotal };

export default {
  CardValidator,
  OrderFactory,
  PaymentFactory,
  ShippingFactory,
  orderStatus,
  getOrderTotal,
};
