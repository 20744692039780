import { gql } from "@apollo/client/core";

export const DISCOUNT_RULES = gql`
  query($storeId: ID!) {
    discountRules(storeId: $storeId) {
      id
      discountType
      discountValue
      isDefault
      paymentMethod {
        id
        label
        type
        gateway
        supportedTypes
      }
    }
  }
`;

const schema = {
  DISCOUNT_RULES
};

export default schema;