import Vue from "vue";
import VueApollo from "vue-apollo";

import { ApolloClient, from, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import ApolloLinkTimeout from "apollo-link-timeout";

import anonymously, { isTokenValid } from "./anonymously";

Vue.use(VueApollo);

const baseUrl = window.Smart.env.BASE_URL;

const handleMessages = ({ message, detail }) => {
  window.$vm.$messages.warning({
    text: `${message}: ${detail}`,
  });
};

const httpLink = createUploadLink({
  uri: `${baseUrl}/${window.Smart.env.GRAPHQL_API}`,
});

const timeoutLink = new ApolloLinkTimeout(30 * 1000);
const timeoutHttpLink = timeoutLink.concat(httpLink);

const authLink = setContext(async () => {
  const $store = window.$store;
  let accessToken = "";

  if ($store.getters.isAuthenticated) {
    if (!isTokenValid($store.getters.accessToken)) {
      await $store.dispatch("refresh");
    }
    accessToken = $store.getters.accessToken;
  } else {
    accessToken = await anonymously.accessToken();
  }

  return {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const errorLink = onError((p) => {
  const $store = window.$store;
  if (p?.networkError && p?.networkError?.statusCode === 401) {
    $store.dispatch("logout");
    return;
  }

  if (!p?.graphQLErrors || !p?.graphQLErrors[0]) {
    return;
  }

  const e = p?.graphQLErrors[0];

  if (e.extensions.category === "authentication") {
    $store.dispatch("logout");
    return;
  }

  if (e.extensions.category === "graphql") {
    handleMessages({
      message: "Atenção",
      detail: e.message || "Algo deu errado. Tente novamente mais tarde.",
    });
    return;
  }

  if (e.extensions.category === "validation") {
    const s = Object.values(e.extensions.validation).map((v) => {
      return Array.isArray(v) ? v.join(", ") : v;
    });
    handleMessages({
      message: "As informações estão incompletas",
      detail: s.join(", "),
    });
    return;
  }

  if (e.extensions.category === "payment") {
    const { reason } = e.extensions;
    handleMessages({
      message: e.message || "As informações estão incompletas",
      detail: reason,
    });
    return;
  }

  handleMessages({
    message: "Algo deu errado",
    detail: "Tente novamente mais tarde",
  });
});

const cache = new InMemoryCache({});

export const apolloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    timeoutHttpLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      // errorPolicy: 'all',
    },
    query: {
      fetchPolicy: "no-cache",
      // errorPolicy: 'all',
    },
  },
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

window.$apollo = apolloClient;

export default apolloProvider;
