import { gql } from "@apollo/client/core";

export const CONFIG = gql`
  query getConfig(
    $url: String
  ) {
    config(
      url: $url
    ) {
      banners
      config
      manifest
      name
      storeId
      providers
      themes
      trackers
      urls
    }
  }
`;

const schema = {
  CONFIG,
};

export default schema;