const bottomSheet = {
  show({
    title,
    values,
  }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("app-bottom-sheet", {
        component: "app-bottom-list",
        title,
        values,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("app-bottom-sheet", { dismiss: true });
  },
};

window.$bottomSheet = bottomSheet;

export default {
  install(Vue) {
    Vue.prototype.$bottomSheet = bottomSheet;
  },
};
