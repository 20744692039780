<template>
  <div :style="rootStyle">
    <v-btn
      :class="titleClass"
      @click="$emit('click-title')"
      color="primary"
      text
      v-if="title"
    >
      {{ title }}
      <v-icon class="ml-1" size="20" :color="iconColor" v-if="icon">
        {{ icon }}
      </v-icon>
    </v-btn>
    <v-slide-group :show-arrows="showArrows" center-active v-model="model">
      <v-slide-item
        :key="title + 'slide-item-' + item.id"
        v-for="item in computedData"
        v-slot="{ active, toggle }"
      >
        <slot
          name="item"
          v-bind:item="item"
          v-bind:toggle="toggle"
          v-bind:active="active"
        >
          <span class="font-weight-light">
            {{ item.name }}
          </span>
        </slot>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "slide-group-animated",
  props: {
    title: String,
    icon: String,
    iconColor: String,
    data: {
      type: Array,
      default: () => [],
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    intervalId: -1,
  }),

  created() {
    if (!this.animated) {
      return;
    }
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const init = mobile ? 2 : 4;
    const sum = mobile ? 1 : 2;

    this.intervalId = setInterval(() => {
      if (!this.model) {
        this.model = init;
      } else {
        const next = this.model + sum;
        if (next > this.data.length - 1) {
          this.model = 0;
        } else {
          this.model = next;
        }
      }
    }, 4000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  computed: {
    computedData() {
      if (this.reverse) {
        const next = JSON.parse(JSON.stringify(this.data));
        next.reverse?.();
        return next;
      }
      return this.data;
    },

    rootStyle() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "margin: 6px -10px 12px -10px";
      }
      return "margin: 12px -48px 24px -48px";
    },

    titleClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "btn-title text-none";
      }
      return "btn-title btn-title-margin text-none";
    },

    showArrows() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
});
</script>

<style scoped>
.btn-title {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.9px;
}
.btn-title-margin {
  margin-bottom: 6px;
  margin-left: 44px;
}

:deep(.v-slide-group__content) {
  justify-content: center;
}
</style>
