import { useValidationStore } from "../../validationStore";

export default {
  loading(state, value) {
    state.loading = value;
  },
  paymentMethods(state, value) {
    state.paymentMethods = value;
  },
  shippingOptions(state, value) {
    state.shippingOptions = value;
  },
  subTotal(state, value) {
    state.subTotal = value;
  },
  totalWithoutPromoItems(state, value) {
    state.totalWithoutPromoItems = value;
  },
  subTotalLabel(state, value) {
    state.subTotalLabel = value;
  },
  userNotes(state, value) {
    state.userNotes = value;
  },
  currentPostalCode(state, value) {
    state.currentPostalCode = value;
  },
  stepper(state, value) {
    state.stepper = value;
  },
  recaptcha(state, value) {
    state.recaptcha = value;
  },
  reset(state) {
    const validation = useValidationStore();
    validation.setLoading(true);
    validation.setReady(false);
    state.loading = true;
    state.paymentMethods = {};
    state.shippingOptions = {};
    state.subTotal = 0;
    state.subTotalLabel = "";
    state.userNotes = "";
    state.couponCode = null;
    state.recaptcha = false;
    state.currentPostalCode = null;
    state.stepper = 1;
  },

  discountRules(state, data) {
    state.discountRules = data;
  },

  defaultDiscountRule(state, data) {
    state.defaultDiscountRule = data;
  },
};
