import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    options: {
      variations: true,
    },
    themes: {
      dark: {
        primary: "#0098db",
        secondary: "#606163",
        accent: "#FFFFFF",
      },
      light: {
        primary: "#0098db",
        secondary: "#606163",
        accent: "#FFFFFF",
      },
    },
  },
});
