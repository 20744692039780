<template>
  <v-list-item
    :key="item.id"
    :style="itemStyle"
    @click="$emit('click', $event)"
    class="cursor-pointer"
  >
    <v-list-item-icon>
      <v-icon :color="config.primaryColor">{{
        item.icon || "mdi-square-rounded-outline"
      }}</v-icon>
    </v-list-item-icon>

    <v-list-item-title :class="config.primaryTextClass">
      {{ item.name }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "menu-item-simple",
  props: ["item", "level"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    itemStyle() {
      if (!this.level) {
        return undefined;
      }

      const factor = (this.level - 1) * 20;
      const val = 28 + factor;
      return `padding: 0 0 0 ${val}px;`;
    },
  },
});
</script>
