export default (pass) => {
  if (!pass || pass.length < 8) {
    return false;
  }
  if (!/\d/.test(pass)) {
    return false;
  }
  if (!/[A-Za-z]/.test(pass)) {
    return false;
  }
  return true;
};
