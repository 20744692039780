<template>
  <v-expansion-panel-content :color="color">
    <v-list-item
      :key="`${item.id}-all`"
      :style="getItemStyle"
      @click="$emit('click', $event)"
      class="cursor-pointer"
      dense
    >
      <v-list-item-icon>
        <v-icon :color="config.primaryColor" size="18"
          >mdi-arrow-right-bottom</v-icon
        >
      </v-list-item-icon>

      <v-list-item-title :class="config.primaryTextClass"
        >Todos</v-list-item-title
      >
    </v-list-item>

    <slot></slot>
  </v-expansion-panel-content>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "menu-item-content",
  props: ["item", "level", "color"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    getItemStyle() {
      const val = 28 + this.level * 20;
      return `padding: 0 0 0 ${val}px;`;
    },
  },
});
</script>
