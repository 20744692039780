import schema from "../http/apollo/schema";
import { postMessageAsync, MessageEvents } from "./nativeBridge";

const WEBPUSH_API = window.Smart.env.WEBPUSH_API;
const endpointType = {
  webpush: "WEBPUSH",
  firebaseApp: "FIREBASE_APP",
  expoApp: "EXPO_APP",
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const registerNativePushNotifications = async ($vue, remove) => {
  try {
    const { prevToken, token, type } = await postMessageAsync(
      MessageEvents.NOTIFICATION_TOKEN,
    );
    const notificationEndpointType = type ?? endpointType.firebaseApp;

    if (remove) {
      const variables = {
        set: false,
        unset: true,
        type: notificationEndpointType,
        registration: JSON.stringify({
          endpoint: prevToken !== token ? prevToken : token,
        }),
      };
      await $vue.$apollo.query({ query: schema.user.SET_ENDPOINT, variables });
      return;
    }

    if (prevToken !== token) {
      const variables = {
        set: false,
        unset: true,
        type: notificationEndpointType,
        registration: JSON.stringify({
          endpoint: prevToken,
        }),
      };
      await $vue.$apollo.query({ query: schema.user.SET_ENDPOINT, variables });
    }

    if (token) {
      const variables = {
        set: true,
        unset: false,
        type: notificationEndpointType,
        registration: JSON.stringify({
          endpoint: token,
        }),
        description: JSON.stringify({
          userAgent: navigator?.userAgent || "unknown",
        }),
      };
      await $vue.$apollo.query({ query: schema.user.SET_ENDPOINT, variables });
    }
  } catch {
    // ignore
  }
};

const registerWebPushNotifications = ($vue, remove) => {
  navigator.serviceWorker.ready
    .then((registration) => {
      return registration.pushManager.getSubscription().then(async (sub) => {
        if (sub) {
          if (remove) {
            const res = await sub.unsubscribe();
            throw `unsubscribe > ${res}`;
          }
          return sub;
        }

        const url = `${WEBPUSH_API}/public-key`;
        const response = await $vue.$http.get(url);
        const vapidPublicKey = response?.data?.data;
        const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        });
      });
    }).then((subscription) => {
      const payload = subscription;

      if (!payload) {
        return;
      }

      const variables = remove
        ? {
          set: false,
          unset: true,
          type: endpointType.webpush,
          registration: payload ? JSON.stringify(payload) : "unknown",
        }
        : {
          set: true,
          unset: false,
          type: endpointType.webpush,
          registration: JSON.stringify(payload),
          description: JSON.stringify({
            userAgent: navigator?.userAgent || "unknown",
          }),
        };

      $vue.$apollo.query({ query: schema.user.SET_ENDPOINT, variables });
    }).catch(() => null);
};

export default ($vue, remove = false) => {
  if (window.isNativeApp) {
    registerNativePushNotifications($vue, remove);
    return;
  }

  if (process.env.NODE_ENV !== "production") {
    return;
  }

  registerWebPushNotifications($vue, remove);
};
