/* eslint-disable no-param-reassign */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.locale("pt-br");

window.$day = dayjs;

export { dayjs };

export default {
  install(Vue) {
    Vue.prototype.$day = dayjs;
  },
};
