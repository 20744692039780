import "./publicPath";
import Vue from "vue";
import { createPinia } from "pinia";
import App from "./App";

import store from "./storex";
import router from "./router";

import apolloProvider from "./http/apollo";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import "./http";
import "./plugins";

Vue.config.productionTip = false;

const pinia = createPinia();

const vm = new Vue({
  router,
  store,
  pinia,
  apolloProvider,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.$vm = vm;
