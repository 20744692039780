<template>
  <div>
    <v-btn
      :color="hasLocation ? 'light-blue' : '#757575'"
      @click.stop="click"
      small
      text
      v-if="$vuetify.breakpoint.smAndUp || isLarge"
    >
      <v-icon left>mdi-crosshairs-gps</v-icon>Meu Local
    </v-btn>

    <v-btn
      :color="hasLocation ? 'light-blue' : '#757575'"
      @click.stop="click"
      fab
      text
      v-else
      x-small
    >
      <v-icon>mdi-crosshairs-gps</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "location-button",
  props: {
    position: Object,
    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasLocation() {
      return !!this.position?.coords;
    },

    isLarge() {
      if (this.large) {
        return true;
      }
      return !this.position?.postalCode && !this.hasLocation;
    },
  },

  methods: {
    click() {
      this.$emit("local-click");
    },
  },
});
</script>
