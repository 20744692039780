import Address from "./AddressFactory";
import parserUtils from "./parser";

export const AddressFactory = Address;
export const parser = parserUtils;

export default {
  AddressFactory,
  parserUtils,
};
