export const MessageEvents = {
  AUTH_APPLE: "auth_apple",
  AUTH_ERROR: "auth_error",
  AUTH_GOOGLE: "auth_google",
  AUTH_INFO: "auth_info",
  AUTH_LOGOUT: "auth_logout",
  CLIPBOARD: "clipboard",
  IMAGE_PICKER_CAMERA: "image_picker_camera",
  IMAGE_PICKER_LIBRARY: "image_picker_library",
  LOG_ERROR: "log_error",
  LOG_INFO: "log_info",
  OPEN_URL: "open_url",
  NOTIFICATION_TOKEN: "notification_token",
  NOTIFICATION_MESSAGE: "notification_message",
};

/* export interface ImagePickerResult {
  assets?: {
    assetId?: string;
    base64?: string;
    duration?: string;
    exif?: Record<string, any>;
    height: number;
    rotation?: any;
    type?: "image" | "video";
    uri: string;
    width: number;
  }[];
  canceled: boolean;
} */

const postMessage = (data) => {
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(data));
};

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const postMessageSync = (messageEvent, data) => {
  // Request native auth
  postMessage({ event: messageEvent, data });
};

export const postMessageAsync = (messageEvent, data) => {
  return new Promise((resolve, reject) => {
    const handleResponse = (event) => {
      if (!event?.data) {
        reject(new Error("Invalid data"));
        return;
      }

      const json = parseData(event.data);
      if (json?.event !== messageEvent) {
        reject(new Error("Invalid event type"));
        return;
      }
      if (window.nativeOS && window.nativeOS === "ios") {
        window.removeEventListener("message", handleResponse);
      } else {
        document.removeEventListener("message", handleResponse);
      }

      if (json.type === "error") {
        reject(new Error(json.message));
        return;
      }
      resolve(json.data);
    };

    if (window.nativeOS && window.nativeOS === "ios") {
      window.addEventListener("message", handleResponse);
    } else {
      document.addEventListener("message", handleResponse);
    }

    // Request native auth
    postMessage({ event: messageEvent, data });
  });
};