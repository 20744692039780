<template>
  <v-dialog
    v-model="visible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat>
        <v-spacer />
        <v-icon color="light-blue"> mdi-map-marker-outline </v-icon>
        <v-spacer />
      </v-toolbar>

      <v-card-title>
        <v-spacer />
        <span> Salve a sua localização </span>
        <v-spacer />
      </v-card-title>

      <v-card-text class="pb-1">
        <p class="text-center">
          Selecione o seu local no mapa ou utilize o botão "Meu local".
        </p>
      </v-card-text>

      <v-container>
        <v-card-actions style="margin-right: -8px">
          <v-spacer />
          <v-btn
            :loading="loading"
            @click="getLocal"
            color="light-blue"
            small
            text
          >
            <v-icon small left> mdi-crosshairs-gps </v-icon>
            Meu local
          </v-btn>
        </v-card-actions>

        <map-view
          :next-position.sync="nextPosition"
          :position="position"
          class="map-view-modal"
          v-if="mapVisible"
        />

        <v-card-actions :style="bottomStyle">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <v-btn
            :disabled="loading"
            @click="visible = false"
            class="ma-2"
            color="primary"
            small="small"
            text="text"
          >
            Cancelar
          </v-btn>
          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
          <v-btn
            :large="$vuetify.breakpoint.smAndUp"
            @click="saveClick"
            class="ma-2"
            color="primary"
            depressed="depressed"
            min-width="150px"
            rounded="rounded"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import MapView from "./MapView";

export default defineComponent({
  name: "map-view-modal",
  components: {
    MapView,
  },

  data: () => ({
    visible: false,
    mapVisible: false,
    loading: false,
    position: null,
    nextPosition: null,
  }),

  created() {
    this.nudgeTimeoutId = -1;
    this.$events.$on("map-view-modal", this.handleEvent);
  },
  beforeDestroy() {
    this.$events.$off("map-view-modal", this.handleEvent);
  },

  computed: {
    bottomStyle() {
      const bottom = this.$vuetify.breakpoint.xsOnly ? "34px" : "20px";
      let mr = "0";
      if (this.$vuetify.breakpoint.mdAndUp) {
        mr = "32px";
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        mr = "64px";
      }

      return `
        bottom: ${bottom};
        flex-direction: row;
        left: 0;
        margin-right: ${mr};
        position: fixed;
        right: 0;
      `;
    },
  },

  watch: {
    visible(v) {
      this.$nextTick(() => this.$store.commit("map-view-modal", v));
      setTimeout(() => {
        this.mapVisible = v;
      }, 400);
    },
  },

  methods: {
    handleEvent(e) {
      if (!e) {
        this.visible = false;
      } else {
        this.position = e.position;
        this.nextPosition = null;
        this.visible = true;
        this.positionCallback = e.positionCallback;
        e.shouldGetPositionOnShow && this.getLocal();
      }
    },

    getLocal() {
      this.nudgeTimeoutId = setTimeout(this.showNudgeBanner, 15 * 1000);
      this.geoOptions = {
        enableHighAccuracy: true,
        maximumAge: 30 * 1000,
        timeout: 20 * 1000,
      };
      this.getCurrentPosition();
    },

    geoSuccess(position) {
      this.loading = false;
      clearTimeout(this.nudgeTimeoutId);
      this.position = this.getCoords(position.coords);
    },

    geoError(error) {
      this.loading = false;
      clearTimeout(this.nudgeTimeoutId);

      switch (error.code) {
        case error.TIMEOUT:
          this.showNudgeBanner();
          break;
        case error.PERMISSION_DENIED:
          this.snackbar("Permissão para localização negada");
          break;
        case error.POSITION_UNAVAILABLE:
          this.snackbar("Não foi possível obter sua posição no momento");
          break;
        default:
          break;
      }
    },

    getCurrentPosition() {
      if (!this.isLocationSupported()) {
        return;
      }

      this.loading = true;
      navigator.geolocation.getCurrentPosition(
        this.geoSuccess,
        this.geoError,
        this.geoOptions
      );
    },

    getCoords(coords) {
      return {
        accuracy: coords.accuracy,
        altitude: coords.altitude,
        altitudeAccuracy: coords.altitudeAccuracy,
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    },

    showNudgeBanner() {
      const onPress = () => {
        this.getCurrentPosition();
      };
      const t = [
        "Deseja utilizar sua localização atual",
        "para salvar como local de entrega?",
      ];

      this.$alert.show({
        text: t.join(" "),
        actions: [{ text: "Ativar", onPress }],
      });
    },

    isLocationSupported() {
      if (!navigator.geolocation) {
        this.snackbar("Localização não suportada neste dispositivo");
        return false;
      }
      return true;
    },

    saveClick() {
      this.visible = false;
      this.positionCallback?.(this.nextPosition || this.position);
    },

    snackbar(text) {
      this.$snackbar.show({
        text,
        action: {
          text: "OK",
        },
      });
    },
  },
});
</script>

<style scoped>
.icon-left {
  visibility: hidden;
}

.map-view-modal {
  margin: 10px 0;
}
</style>
