const alert = {
  show({
    actions = [],
    onDismiss = null,
    text = "",
    t = null,
  }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("app-bottom-sheet", {
        actions,
        component: "app-alert",
        onDismiss,
        text,
        t,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("app-bottom-sheet", { dismiss: true });
  },
};

window.$alert = alert;

export default {
  install(Vue) {
    Vue.prototype.$alert = alert;
  },
};
