import Vue from "vue";
import Vuex from "vuex";
// modules
import root from "./modules/root";
import checkout from "./modules/checkout";
import shopping from "./modules/shopping";

Vue.use(Vuex);

const $store = new Vuex.Store({
  actions: root.actions,
  getters: root.getters,
  mutations: root.mutations,
  state: root.state,
  modules: {
    checkout,
    shopping,
  },
});

window.$store = $store;

export function useStore() {
  const state = $store.state;
  const getters = $store.getters;
  const dispatch = $store.dispatch;
  const commit = $store.commit;

  return { state, getters, dispatch, commit };
}

export default $store;
