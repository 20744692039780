<template>
  <div>
    <v-app-bar
      :color="config.themeColor"
      :dark="false"
      :dense="$vuetify.breakpoint.xsOnly"
      app
      fixed
      flat
      v-if="isCheckoutLayout"
    >
      <AppBarLogo :actionDisabled="true" />
      <v-spacer />
      <v-btn
        :color="appBarIconColor"
        @click="$events.$emit('descartAction')"
        text
      >
        CANCELAR
      </v-btn>
    </v-app-bar>
    <v-app-bar
      :color="config.themeColor"
      :dark="false"
      :dense="$vuetify.breakpoint.xsOnly"
      flat
      prominent
      v-else
    >
      <AppBarLogo />

      <template v-slot:extension>
        <div class="toolbar-extension">
          <app-search :class="appSearchClass" />

          <v-btn
            :color="appBarIconColor"
            @click="setDrawer(true)"
            text
            v-if="$vuetify.breakpoint.xsOnly"
          >
            <v-icon size="24" left> mdi-menu </v-icon>
            Todas as categorias
          </v-btn>

          <div class="menu-horizontal" v-else>
            <v-btn :color="appBarIconColor" text @click="setDrawer(true)">
              <v-icon size="24" left> mdi-menu </v-icon>
              Todas as categorias
            </v-btn>
            <v-btn
              :color="appBarIconColor"
              :key="item.name"
              @click="handleItemPress(item)"
              text
              v-for="item in categoriesItems"
            >
              {{ item.name }}
            </v-btn>
          </div>
        </div>
      </template>

      <v-spacer />

      <TopNavigation :data="topNavigationItems" />
    </v-app-bar>

    <app-drawer />
    <app-alerts />

    <div style="margin-bottom: 90px">
      <slot></slot>
    </div>

    <v-bottom-navigation
      :value="bottomNavigationIndex"
      color="primary"
      fixed
      height="72px"
      grow
      v-if="$vuetify.breakpoint.xsOnly && !isCheckoutLayout"
    >
      <v-btn
        :to="navItem.to"
        v-for="navItem in bottomNavigationItems"
        :key="navItem.id"
      >
        <span>{{ navItem.title }}</span>
        <v-icon :size="navItem.icon.size">{{ navItem.icon.name }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AppDrawer from "@/components/common/Drawer";
import AppSearch from "@/components/AppSearch";
import AppAlerts from "@/components/Alerts";
import { useCategoriesStore } from "@/storex/categoriesStore";
import { useConfigStore } from "@/storex/configStore";
import AppBarLogo from "./AppBarLogo";
import TopNavigation from "./TopNavigation";

export default defineComponent({
  name: "StoreLayout",
  components: {
    AppAlerts,
    AppDrawer,
    AppSearch,
    TopNavigation,
    AppBarLogo,
  },

  setup() {
    const config = useConfigStore();
    const categories = useCategoriesStore();
    return { categories, config };
  },

  computed: {
    ...mapGetters("shopping", ["shopping"]),

    theme() {
      return this.config.theme;
    },
    dark() {
      return this.theme === "dark";
    },

    appBarIconColor() {
      return this.theme === "light" ? "primary" : "#fff";
    },

    isCheckoutLayout() {
      return (
        (this.$route.path.includes("checkout") ||
          this.$route.query?.redirect?.includes("checkout")) &&
        !this.$route.path.includes("login-options")
      );
    },

    categoriesItems() {
      if (!this.categories.data?.length) {
        return [];
      }
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return this.categories.data.slice(0, 4);
        case "md":
          return this.categories.data.slice(0, 5);
        case "lg":
          return this.categories.data.slice(0, 8);
        default:
          return this.categories.data.slice(0, 10);
      }
    },

    appSearchClass() {
      return this.$vuetify.breakpoint.xsOnly ? "mb-1" : "mb-3";
    },

    bottomNavigationIndex() {
      const includes = (paths = []) => {
        for (let i = 0; i < paths.length; i += 1) {
          if (this.$route.path.includes(paths[i])) {
            return true;
          }
        }
        return false;
      };

      if (this.$route.path.includes("store")) {
        return 0;
      }
      if (includes(["account", "notifications"])) {
        return 1;
      }
      if (this.$route.path.includes("about")) {
        return 2;
      }
      return -1;
    },

    bottomNavigationItems() {
      return [
        {
          id: "bottom-navigation-item-store",
          title: this.$t("route.store.title"),
          to: "/",
          icon: {
            name: "mdi-store",
            size: 24,
          },
        },
        {
          id: "bottom-navigation-item-account",
          title: this.$t("route.account.title"),
          to: "/account",
          icon: {
            name: "mdi-account",
            size: 24,
          },
        },
        {
          id: "bottom-navigation-item-about",
          title: this.$t("route.about.title"),
          to: "/about",
          icon: {
            name: "mdi-face-agent",
            size: 24,
          },
        },
      ];
    },

    topNavigationItems() {
      return [
        {
          id: "navigation-item-account",
          title: this.$t("route.account.title"),
          to: "/account",
          hidden: this.$vuetify.breakpoint.xsOnly,
          icon: {
            name: "mdi-account",
            size: 22,
          },
        },
        {
          id: "navigation-item-shopping",
          title: "Carrinho",
          to: "/store/shopping",
          badge: this.shopping.items.length,
          hidden: !this.shopping.items.length,
          icon: {
            name: "mdi-cart-variant",
            size: 22,
          },
        },
        {
          id: "navigation-item-notifications",
          title: this.$t("route.notifications.title"),
          to: "/notifications",
          badge: this.$store.getters.appBadge,
          hidden: false,
          icon: {
            name: "mdi-bell-ring",
            size: 22,
          },
        },
        {
          id: "navigation-item-about",
          title: this.$t("route.about.title"),
          to: "/about",
          hidden: this.$vuetify.breakpoint.smAndDown,
          icon: {
            name: "mdi-face-agent",
            size: 22,
          },
        },
      ].filter((item) => !item.hidden);
    },
  },

  methods: {
    setDrawer(value) {
      this.config.setDrawer(value);
    },

    handleItemPress(v) {
      this.setDrawer(false);
      requestAnimationFrame(() => {
        this.$router.push({
          name: "search",
          query: {
            ...this.$route.query,
            c: v.id || undefined,
          },
        });
      });
    },
  },
});
</script>

<style scoped>
.menu-horizontal {
  display: flex;
  flex: 1;
  flex-direction: "column";
  justify-content: center;
}

.toolbar-extension {
  flex: 1;
}

:deep(.v-toolbar__extension) {
  height: 110px !important;
  transform: translateY(-64px);
}

@media only screen and (max-width: 600px) {
  :deep(.v-toolbar__extension) {
    height: 100px !important;
    transform: translateY(-46px);
  }
}
</style>
