<template>
  <div style="flex: 1">
    <div v-for="item in computedItems" :key="item.id">
      <v-expansion-panel v-if="item.isExpandable">
        <menu-item-header
          :color="dark ? themeComputed : undefined"
          :dark="dark"
          :item="item"
          :level="level"
        />

        <menu-item-content
          :color="dark ? themeComputed : undefined"
          :item="item"
          :level="level"
          @click="handlePress(item)"
        >
          <menu-items
            :items="item.childs"
            :level="level + 1"
            :levelBreakpoint="levelBreakpoint"
            @close="$emit('close')"
          />
        </menu-item-content>
      </v-expansion-panel>

      <menu-item-simple
        :item="item"
        :level="level"
        @click="handlePress(item)"
        v-else
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { createColor } from "@/lib/colors";
import { useConfigStore } from "@/storex/configStore";

import MenuItemContent from "./MenuItemContent";
import MenuItemHeader from "./MenuItemHeader";
import MenuItemSimple from "./MenuItemSimple";

export default defineComponent({
  name: "menu-items",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 0,
    },
    levelBreakpoint: {
      type: Number,
      default: 4,
    },
  },
  components: {
    MenuItemContent,
    MenuItemHeader,
    MenuItemSimple,
  },

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    theme() {
      return this.config.theme;
    },

    themeColor() {
      return this.config.themeColor;
    },

    dark() {
      return this.theme === "dark";
    },

    computedItems() {
      return this.items.map((item) => {
        const next = {
          ...item,
          isExpandable: !!item.childs.length,
        };
        if (this.level >= this.levelBreakpoint) {
          next.isExpandable = false;
        }
        return next;
      });
    },

    themeComputed() {
      return createColor(this.themeColor).darken(0.2).toString();
    },
  },

  methods: {
    handlePress(v) {
      this.$emit("close");

      requestAnimationFrame(() => {
        this.$router.push({
          name: "search",
          query: {
            ...this.$route.query,
            c: v.id || undefined,
          },
        });
      });
    },
  },
});
</script>
