<template>
  <v-bottom-sheet max-width="998" v-model="visible">
    <component
      class="app-bottom-sheet-root"
      :is="componentComputed"
      :options="options"
    ></component>
  </v-bottom-sheet>
</template>

<script>
import { defineComponent } from "vue";
import AppAlert from "./AppAlert";
import AppBottomList from "./AppBottomList";
import AppInputAlert from "./AppInputAlert";
import AppInputAlertChange from "./AppInputAlertChange";

export default defineComponent({
  name: "app-bottom-sheet",
  components: {
    AppAlert,
    AppBottomList,
    AppInputAlert,
    AppInputAlertChange,
  },

  data() {
    return {
      currentComponent: "",
      options: {},
      timeout: -1,
      visible: false,
    };
  },

  created() {
    this.$events.$on("app-bottom-sheet", this.handleEvent);
  },
  beforeDestroy() {
    this.$events.$off("app-bottom-sheet", this.handleEvent);
  },

  computed: {
    componentComputed() {
      switch (this.currentComponent) {
        case "app-alert":
          return "app-alert";
        case "app-input-alert-change":
          return "app-input-alert-change";
        case "app-input-alert":
          return "app-input-alert";
        case "app-bottom-list":
          return "app-bottom-list";
        default:
          return "";
      }
    },
  },

  watch: {
    visible(v) {
      if (!v) {
        this.cleanAttrs();
        setTimeout(() => this.options?.onDismiss?.(), 250);
      }
      this.$nextTick(() => this.$store.commit("app-bottom-sheet", v));
    },
  },

  methods: {
    handleEvent(e) {
      if (!e || e.dismiss) {
        this.visible = false;
      } else {
        this.currentComponent = e.component;
        this.options = e;
        this.visible = true;
      }
    },

    cleanAttrs() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.options = {};
        this.currentComponent = "";
      }, 500);
    },
  },
});
</script>

<style scoped>
.app-bottom-sheet-root {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
</style>
