import styled, { keyframes } from "vue-styled-components";

const props = {
  color: String,
};

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingCircle = styled("div", props)`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: ${(p) => `6px solid ${p.color || "#fff"}`};
    border-color: ${(p) => `${p.color || "#fff"} transparent ${p.color || "#fff"} transparent`};
    animation: ${rotate} 1.2s linear infinite;
  }
`;

export default LoadingCircle;
