const snackbar = {
  show({
    action = {},
    color = "",
    duration = 3000,
    multiline = false,
    top = false,
    onDismiss = null,
    t = null,
    text = "",
  }, delay = 250) {
    setTimeout(() => {
      window.$events.$emit("snackbar", {
        action,
        color,
        duration,
        multiline,
        top,
        onDismiss,
        t,
        text,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("snackbar", { dismiss: true });
  },
};

window.$snackbar = snackbar;

export default {
  install(Vue) {
    Vue.prototype.$snackbar = snackbar;
  },
};
