<template>
  <div>
    <div class="top-navigation">
      <v-btn
        :color="appBarIconColor"
        :icon="$vuetify.breakpoint.xsOnly"
        :key="`item-menu-${item.id}`"
        :to="item.to"
        text
        v-for="item in data"
      >
        <v-badge
          :content="item.badge"
          :value="true"
          bordered
          class="mr-2"
          color="error"
          overlap
          v-if="item.badge"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" :size="item.icon.size">
            {{ item.icon.name }}
          </v-icon>
        </v-badge>
        <v-icon
          :left="$vuetify.breakpoint.smAndUp"
          :size="item.icon.size"
          v-else
        >
          {{ item.icon.name }}
        </v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ item.title }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "TopNavigation",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    appBarIconColor() {
      return this.config.theme === "light" ? "primary" : "#fff";
    },
  },
});
</script>

<style scoped>
.top-navigation {
  display: flex;
  flex-direction: row;
  padding: 8px 2px 0 0;
}

@media only screen and (max-width: 600px) {
  .top-navigation {
    display: flex;
    flex-direction: row;
    padding: 4px 6px 0 0;
  }
}

span {
  text-transform: capitalize;
  margin-left: 6px;
}
</style>
