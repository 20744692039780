import { postMessageAsync, postMessageSync, MessageEvents } from "@/plugins/nativeBridge";
import { useConfigStore } from "@/storex/configStore";
import http from "./index";

const OAUTH_API = window.Smart.env.OAUTH_API;
const STORE_ID = window.Smart?.store?.id;

export function getAppleAuth() {
  const config = useConfigStore();
  window?.AppleID?.auth.init({
    clientId: config.appleClientId,
    scope: window.Smart.env.APPLE_SCOPE,
    redirectURI: config.appleUri,
    state: window.Smart.env.APPLE_STATE,
    usePopup: true,
  });
}

const getResponseAuth = async () => {
  if (window.isNativeApp) {
    return postMessageAsync(MessageEvents.AUTH_APPLE);
  }

  const response = await window?.AppleID?.auth.signIn();

  if (response?.authorization?.state !== window.Smart.env.APPLE_STATE) {
    throw new Error("Invalid authorization");
  }

  return response;
};

export async function signInWithApple(context) {
  try {
    const response = await getResponseAuth();

    const { data } = await http.post(OAUTH_API, {
      grant_type: "social",
      provider: "apple",
      access_token: JSON.stringify(response),
      store_id: STORE_ID,
    });

    if (data) {
      context.commit("accessToken", data.access_token);
      context.commit("refreshToken", data.refresh_token);
      window.$snackbar.show({
        t: "message.success.auth.login",
        action: { text: "OK" },
      });
      return true;
    }

    context.commit("accessToken");
    context.commit("refreshToken");
    window.$snackbar.show({
      t: "message.error.auth.apple",
      action: { text: "OK" },
    });
    return false;
  } catch (e) {
    postMessageSync(MessageEvents.AUTH_ERROR, e?.message);
    window.$snackbar.show({
      t: "message.error.auth.apple",
      action: { text: "OK" },
    });
    return false;
  } finally {
    setTimeout(() => {
      context.commit("appLoading", false);
    }, 250);
  }
}
