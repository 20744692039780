<template>
  <v-tabs
    :background-color="config.themeColor"
    :dark="config.theme === 'dark'"
    centered
    height="42px"
    class="mt-1"
  >
    <v-tab to="/appointments">
      <v-icon dense left>mdi-calendar-clock</v-icon>
      Agenda
    </v-tab>
    <v-tab to="/pets">
      <v-icon dense left>mdi-paw</v-icon>
      Pets
    </v-tab>
    <v-tab to="/vaccines">
      <v-icon dense left>mdi-pill</v-icon>
      Vacinas
    </v-tab>
  </v-tabs>
</template>

<script>
import { useConfigStore } from "@/storex/configStore";

export default {
  name: "AppointmentsTabs",

  setup() {
    const config = useConfigStore();
    return { config };
  },
};
</script>
