const id = window.Smart?.name || "smartdelivery";

export const prefix = `@smartdelivery:${id}`;

function getItem(key) {
  return localStorage.getItem(`${prefix}:${key}`);
}

function getKeys() {
  const keys = [];
  for (let i = 0; i < localStorage.length; i += 1) {
    keys.push(localStorage.key(i));
  }
  return keys;
}

function clear() {
  getKeys().forEach((key) => localStorage.removeItem(key));
}

function removeItem(key) {
  return localStorage.removeItem(`${prefix}:${key}`);
}

function multiRemove(keys = []) {
  keys.forEach((key) => removeItem(key));
}

function setItem(key, value) {
  return localStorage.setItem(`${prefix}:${key}`, value);
}

function setTheme({ dark }) {
  return localStorage.setItem(`${prefix}:theme`, JSON.stringify({ dark }));
}

function getTheme() {
  const theme = localStorage.getItem(`${prefix}:theme`);
  if (!theme) {
    setTheme({ dark: false });
    return { dark: false };
  }
  return JSON.parse(theme);
}

export default {
  clear,
  getItem,
  getKeys,
  getTheme,
  multiRemove,
  removeItem,
  setItem,
  setTheme,
};
