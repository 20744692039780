import { getDiscountInfo, calcTotal, calcTotalWithoutPromoItems } from "@/lib/items";

export default {
  shopping(state, getters) {
    return {
      items: getters.items,
      total: getters.total,
      totalWithDiscountRules: getters.totalWithDiscountRules,
      totalWithoutPromoItems: getters.totalWithoutPromoItems,
    };
  },
  shoppingState(state, getters) {
    return {
      items: state.items,
      total: state.total,
      totalWithDiscountRules: getters.totalWithDiscountRules,
      totalWithoutPromoItems: getters.totalWithoutPromoItems,
    };
  },
  total(state) {
    return state.total;
  },
  totalWithoutPromoItems(_, getters) {
    return calcTotalWithoutPromoItems(getters.itemsWithDiscountRule);
  },
  totalWithDiscountRules(state, getters, rs, rootGetters) {
    if (!rootGetters["checkout/discountRulesEnabled"]) {
      return state.total;
    }
    return calcTotal(getters.items);
  },
  items(state, g, rs, rootGetters) {
    const discountRule = rootGetters["checkout/defaultDiscountRule"];
    if (!rootGetters["checkout/discountRulesEnabled"] || !discountRule) {
      return state.items;
    }
    return state.items.map((item) => getDiscountInfo({ ...item }, discountRule));
  },
  itemsWithDiscountRule(state, g, rs, rootGetters) {
    const discountRule = rootGetters["checkout/discountRulesSelected"];
    if (!rootGetters["checkout/discountRulesEnabled"] || !discountRule) {
      return state.items;
    }
    return state.items.map((item) => getDiscountInfo({ ...item }, discountRule));
  },
  loading(state) {
    return state.loading;
  },
};
