<template>
  <v-navigation-drawer
    :color="themeComputed"
    :dark="dark"
    :value="drawer"
    :width="drawerWidth"
    @input="setDrawer($event)"
    app
    temporary
  >
    <v-list>
      <v-list-item @click="goHome">
        <v-list-item-icon>
          <v-icon :color="config.primaryColor">mdi-storefront</v-icon>
        </v-list-item-icon>

        <v-list-item-title :class="config.primaryTextClass">
          {{ storeTitle }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <menu-items-container :dark="dark" v-model="panels">
      <menu-items :items="categories.data" @close="handleMenuClose" />
    </menu-items-container>
  </v-navigation-drawer>
</template>

<script>
import { defineComponent, ref } from "vue";
import { createColor } from "@/lib/colors";
import { useCategoriesStore } from "@/storex/categoriesStore";
import { useConfigStore } from "@/storex/configStore";

import MenuItemsContainer from "../MenuItems/MenuItemsContainer";
import MenuItems from "../MenuItems";

export default defineComponent({
  name: "app-drawer",
  components: { MenuItemsContainer, MenuItems },

  setup() {
    const config = useConfigStore();
    const categories = useCategoriesStore();
    const panels = ref([]);
    return { categories, panels, config };
  },

  computed: {
    drawer() {
      return this.config.drawer;
    },

    storeTitle() {
      return this.config.storeTitle;
    },
    theme() {
      return this.config.theme;
    },
    themeColor() {
      return this.config.themeColor;
    },

    dark() {
      return this.theme === "dark";
    },

    drawerWidth() {
      return this.$vuetify.breakpoint.xsOnly ? "315" : "356";
    },

    themeComputed() {
      if (this.dark) {
        return createColor(this.themeColor).darken(0.2).toString();
      }
      return "#fff";
    },
  },

  watch: {
    drawer(v) {
      if (!v) {
        this.panels = [];
      }
    },
  },

  methods: {
    setDrawer(value) {
      this.config.setDrawer(value);
    },

    getGroupVisibility({ id }) {
      return this.groups?.[id];
    },

    goHome() {
      this.setDrawer(false);
      if (this.$route.name !== "store") {
        this.$router.push({ name: "store" });
      }
    },

    handleMenuClose() {
      this.setDrawer(false);
    },
  },
});
</script>

<style scoped>
:deep(.v-expansion-panel-content__wrap) {
  padding: 0;
}
:deep(.v-expansion-panel::before) {
  box-shadow: unset;
}
:deep(.v-expansion-panel:not(:first-child)::after) {
  box-shadow: unset;
  border-top: unset;
}
</style>
