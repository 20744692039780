<template>
  <v-expansion-panel-header
    :class="panelHeaderClass"
    :color="color"
    :style="panelHeaderStyle"
  >
    <div style="max-width: 56px">
      <v-icon :color="config.primaryColor">
        {{ item.icon || "mdi-square-rounded-outline" }}
      </v-icon>
    </div>
    {{ item.name }}
  </v-expansion-panel-header>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "menu-item-header",
  props: ["item", "level", "color", "dark"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    panelHeaderClass() {
      if (this.level) {
        return this.config.primaryTextClass;
      }
      return `${this.config.primaryTextClass} px-4`;
    },

    panelHeaderStyle() {
      if (!this.level) {
        return undefined;
      }

      const factor = (this.level - 1) * 20;
      const val = 28 + factor;
      return `padding-left: ${val}px; padding-right: 16px;`;
    },
  },
});
</script>
