function show(options, delay) {
  setTimeout(() => {
    window.$events.$emit("app-messages", options);
  }, delay);
}

const messages = {
  success({
    action = {},
    duration = 6000,
    text = "",
    onDismiss = null,
  }, delay = 250) {
    show({
      action,
      duration,
      text,
      onDismiss,
      type: "success",
    }, delay);
  },
  info({
    action = {},
    duration = 6000,
    text = "",
    onDismiss = null,
  }, delay = 250) {
    show({
      action,
      duration,
      text,
      onDismiss,
      type: "info",
    }, delay);
  },
  warning({
    action = {},
    duration = 6000,
    text = "",
    onDismiss = null,
  }, delay = 250) {
    show({
      action,
      duration,
      text,
      onDismiss,
      type: "warning",
    }, delay);
  },
  error({
    action = {},
    duration = 6000,
    text = "",
    onDismiss = null,
  }, delay = 250) {
    show({
      action,
      duration,
      text,
      onDismiss,
      type: "error",
    }, delay);
  },
  dismiss() {
    window.$events.$emit("app-messages", { dismiss: true });
  },
};

window.$messages = messages;

export default {
  install(Vue) {
    Vue.prototype.$messages = messages;
  },
};
