import axios from "axios";

const http = axios.create({
  baseURL: "https://consultas.zettabrasil.com.br/api/cep",
  headers: { "Access-Control-Allow-Origin": "*" },
});

export default async (postalCode, fields = {}) => {
  const response = await http.get(postalCode);

  if (response.status !== 200) {
    throw new Error("Nenhuma informação recuperada");
  }

  const { data } = response;

  if (data && data.cep) {
    fields.postalCode = data.cep;
    data.logradouro && (fields.address = data.logradouro);
    data.complemento && (fields.complement = data.complemento);
    data.bairro && (fields.province = data.bairro);
    if (data.municipio) {
      fields.city = data.municipio.nome;
      data.municipio.uf && (fields.state = data.municipio.uf.sigla);
    }
  }

  return fields;
};
