const colors = {
  apple: "#000",
  facebook: "#3B5998",
  google: "#4285F4",
  gmail: "#DE433D",
  whatsapp: "#25D366",
};

window.$colors = colors;

export default {
  install(Vue) {
    Vue.prototype.$colors = colors;
  },
};
