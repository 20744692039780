<template>
  <v-sheet :height="sheetHeight">
    <v-layout justify-center="justify-center">
      <div class="text-center" :style="bottomSheetWidth">
        <div class="d-flex">
          <v-btn
            class="mt-4"
            text="text"
            large="large"
            color="grey darken-2"
            @click="onCancelAction"
            >{{ cancelActionTextComputed }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="mt-4"
            text="text"
            large="large"
            color="primary"
            @click="onConfirmAction"
            >{{ confirmActionTextComputed }}</v-btn
          >
        </div>
        <div class="pa-3" v-if="$store.getters.appBottomSheet">
          <v-text-field
            :label="label"
            :placeholder="placeholder"
            :type="typeComputed"
            v-mask="'#####-###'"
            @keypress.enter="onConfirmAction"
            color="primary"
            ref="input"
            style="font-size: 22px"
            v-if="cepMask"
            v-model="value"
          ></v-text-field>
          <v-text-field
            :label="label"
            :placeholder="placeholder"
            :type="typeComputed"
            @keypress.enter="onConfirmAction"
            color="primary"
            ref="input"
            style="font-size: 22px"
            v-else
            v-model="value"
          ></v-text-field>
        </div>
      </div>
    </v-layout>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "app-input-alert",
  props: ["options"],

  data() {
    return {
      cancelAction: {},
      confirmAction: {},
      label: "",
      placeholder: "",
      value: "",
      type: "text",
      timeout: -1,
    };
  },

  mounted() {
    this.handleOptions(this.options);
  },

  computed: {
    bottomSheetWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "width: 650px;";
      }
      return "width: 550px;";
    },

    sheetHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "250px";
      }
      return "350px";
    },

    cancelActionTextComputed() {
      return this.cancelAction.t
        ? this.$t(this.cancelAction.t)
        : this.cancelAction.text;
    },

    confirmActionTextComputed() {
      return this.confirmAction.t
        ? this.$t(this.confirmAction.t)
        : this.confirmAction.text;
    },

    cepMask() {
      return this.type === "postalCode";
    },

    maskComputed() {
      switch (this.type) {
        case "postalCode":
          return "#####-###";
        default:
          return undefined;
      }
    },

    typeComputed() {
      switch (this.type) {
        case "postalCode":
          return "tel";
        default:
          return "text";
      }
    },
  },

  watch: {
    options(v) {
      this.handleOptions(v);
    },
  },

  methods: {
    handleOptions(v) {
      if (v?.actions?.length) {
        const [cancel, confirm] = v.actions;
        this.confirmAction = confirm ?? cancel ?? { t: "alert.button.confirm" };
        const cnc = confirm && cancel;
        this.cancelAction = cnc ? cancel : { t: "alert.button.cancel" };
      }
      this.type = v?.type;
      this.value = v?.value || "";
      this.label = v?.label;
      this.placeholder = v?.placeholder;

      setTimeout(() => {
        this.$refs?.input?.focus?.();
      }, 500);
    },

    onCancelAction() {
      this.cancelAction?.onPress?.();
      this.$nextTick(() => {
        this.$inputAlert.dismiss();
      });
    },

    onConfirmAction() {
      if (this.confirmAction.onPress) {
        this.confirmAction.onPress(this.value);
      }
      this.$nextTick(() => {
        this.$inputAlert.dismiss();
      });
    },
  },
});
</script>
