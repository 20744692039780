import { watch } from "vue";
import schema from "@/http/apollo/schema";
import { useAccountStore } from "@/storex/accountStore";
import { useSWR } from "./swr";

const configOptions = {
  dedupingInterval: 250,
};

export const useAccount = (options = configOptions) => {
  const { data, error } = useSWR(schema.user.ME, options);
  const accountStore = useAccountStore();

  watch(data, (value) => {
    accountStore.handleUserDetails(value);
  });

  return { data, error };
};