<template>
  <div>
    <keep-alive>
      <v-img
        class="shrink mx-2 mt-2"
        :src="logoAppBar"
        :height="logoHeight"
        :max-width="logoWidth"
        @click="goHome"
        contain="contain"
        style="cursor: pointer"
        transition="scale-transition"
      />
    </keep-alive>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "AppBarLogo",
  props: ["actionDisabled"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    logoAppBar() {
      return this.config.logoAppBar;
    },

    logoHeight() {
      return this.$vuetify.breakpoint.xsOnly ? 48 : 60;
    },

    logoWidth() {
      return this.$vuetify.breakpoint.xsOnly ? 96 : 144;
    },
  },

  methods: {
    goHome() {
      if (this.actionDisabled) {
        return;
      }
      if (!this.config.ecommerceActive && !this.config.appointmentsActive) {
        return;
      }
      if (!this.config.ecommerceActive && this.$route.name === "appointments") {
        return;
      }
      if (!this.config.ecommerceActive && this.$route.name !== "appointments") {
        this.$router.push({ name: "appointments" });
        return;
      }
      if (this.$route.name !== "store") {
        this.$router.push({ name: "store" });
      }
    },
  },
});
</script>
