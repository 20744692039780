import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    total: 0,
    items: [],
    loading: true,
  }),
  getters,
  actions,
  mutations,
};
