import Big from "big.js";

/**
 * mesmo método utilizado pelo siggma

  Big(1.005).toFixedNumber(), 1.01;
  Big(1.004).toFixedNumber(), 1.00;
  Big(947.99499).toFixedNumber(), 947.99;
  Big(947.99599).toFixedNumber(), 948;
  Big(0.305).toFixedNumber(), 0.31;
  Big(0.315).toFixedNumber(), 0.32;
  Big(256.055).toFixedNumber(), 256.06;
  Big(0.305).toFixedNumber(), 0.31;
  Big(1).toFixedNumber(), 1.00;

  // teste que não fecha pelo Big
  Big(24.45 * 16.90).toFixedNumber(), 413.21;
*/
// eslint-disable-next-line func-names
Big.prototype.roundNumber = function (c) {
  function shift(number, precision) {
    const numArray = (`${number}`).split("e");
    return +(
      `${numArray[0]}e${numArray[1] ? (+numArray[1] + precision) : precision}`
    );
  };

  const num = shift(Math.round(shift(this, +10)), -10);
  return shift(Math.round(shift(num, +c)), -c);
};

// eslint-disable-next-line func-names
Big.prototype.toFixedNumber = function (dp = 2) {
  // const n = this.toFixed(dp);
  const n = this.roundNumber(dp);
  return Big(n).toNumber();
};

export { Big };

export default Big;
