import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  const basepath = document.body.dataset.basepath || "";

  register(`${basepath}/sw.js`, {
    ready() {
      // Service worker is active
    },
    registered() {
      // Service worker has been registered
    },
    cached() {
      // Content has been cached for offline use
    },
    updatefound() {
      // Update found service worker
    },
    updated(registration) {
      // Updated service worker
      const newWorker = registration.waiting || registration.installing;

      newWorker && window.$alert && window.$alert.show({
        text: "Novo conteúdo disponível",
        actions: [
          {
            text: "Atualizar",
            onPress: () => newWorker.postMessage({ action: "skipWaiting" }),
          },
        ],
      });
    },
    offline() {
      // No internet connection found. App is running in offline mode
    },
    error() {
      // Error during service worker registration
    },
  });

  // The event listener that is fired when the service worker updates
  // Here we reload the page
  if (navigator.serviceWorker) {
    let refreshing;
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });

    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      if (data?.event && window.$events) {
        window.$events.$emit(data.event, data.payload);
      }
    });
  }
}

// const channel = new BroadcastChannel('sw');
// channel.addEventListener('message', event => {
//   'Recebido do service-worker', event.data
// });
