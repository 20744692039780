import styled from "vue-styled-components";

const ImageIndicator = styled.div`
  background-color: rgba(0,0,0,0.75);
  border-radius: 14px;
  height: 28px;
  justify-content: center;
  min-width: 28px;
  padding: 1px 10px 0 10px;
  position: absolute;
  right: 8px;
  top: 8px;
`;

export default ImageIndicator;
