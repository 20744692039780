import { gql } from "@apollo/client/core";

export const VALIDATE_DISCOUNT_COUPON = gql`
  query(
    $couponCode: String!
    $storeId: Int!
    $skipUserValidation: Boolean
  ) {
    validateDiscountCoupon(
      couponCode: $couponCode
      storeId: $storeId
      skipUserValidation: $skipUserValidation
    ) {
      valid
      data {
        couponCode
        description
        discountType
        discountValue
        ignorePromotionalItems
        ignoreDiscountRules
        minimalOrderValue
        categories {
          id
          categoryId
        }
      }
    }
  }
`;

const schema = {
  VALIDATE_DISCOUNT_COUPON
};

export default schema;