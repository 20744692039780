import SHA256 from "./sha256";

// Informações estáticas do User Agent
const userAgent = navigator.userAgent;
const browserName = navigator.appName;
const browserVersion = navigator.appVersion;
const os = navigator.platform;
const deviceType = /Mobile/.test(userAgent) ? "Mobile" : "Desktop";
const preferredLanguage = navigator.language;

// Informações estáticas sobre o dispositivo
const screenResolution = `${window.screen.availWidth}x${window.screen.availHeight}`; // resolução da tela
const cpuCores = navigator.hardwareConcurrency; // quant. nucleos da cpu
const colorDepth = window.screen.colorDepth; // densidade de cores
const pixelDepth = window.screen.pixelDepth; // densidade de pixel

// Informações sobre a GPU
const gpuInfo = navigator.gpu ? `${navigator.gpu.deviceName}, ${navigator.gpu.vendorName}` : "N/A";
const webglVersion = navigator.gpu ? navigator.gpu.webglVersion || "N/A" : "N/A";

// Informações do dispositivo móvel
const mobileInfo = {
  model: "N/A",
  manufacturer: "N/A",
  os: "N/A",
  browserVersion: "N/A",
  screenResolution: "N/A"
};

if (/Mobile/.test(userAgent)) {
  mobileInfo.model = navigator.platform;
  mobileInfo.manufacturer = "SeuFabricanteAqui"; // Substitua pelo fabricante real
  mobileInfo.os = navigator.appVersion;
  mobileInfo.browserVersion = "SeuVersaoAqui"; // Substitua pela versão real
  mobileInfo.screenResolution = `${window.screen.availWidth}x${window.screen.availHeight}`;
}

const htmlVersion = document.doctype ? document.doctype.name : "Unknown"; // Versão do HTML
const userAgentMasked = navigator.userAgent !== userAgent ? "Masked" : "Not Masked"; // Agente do usuário mascarado ou não
const tlsCompatibility = navigator.userAgent.includes("Chrome") ? "TLS 1.3" : "TLS 1.2"; // Compatibilidade de TLS/SSL

// Informações do navegador
const supportsWebSockets = "WebSocket" in window ? "Supported" : "Not Supported";
const ecmascriptVersion = (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") ? "ES6+" : "ES5";
const supportsServiceWorkers = navigator.onLine ? "Supported" : "Not Supported";
const supportsWebWorkers = "Worker" in window ? "Supported" : "Not Supported";
const supportsWebRTC = "RTCPeerConnection" in window ? "Supported" : "Not Supported";
const browserVendor = navigator.vendor; // Fabricante do navegador
const browserProduct = navigator.product; // Produto do navegador (por exemplo, 'Gecko', 'Presto', 'WebKit')
const browserBuildID = navigator.buildID; // ID de compilação do navegador

// Informações sobre a plataforma
const platform = navigator.platform; // Plataforma em que o navegador está sendo executado (por exemplo, Win32, MacIntel, Linux x86_64)
const osVersion = navigator.appVersion; // Versão específica do sistema operacional



// Informações adicionais
const fonts = (() => {
  const fontList = [];
  const fontTester = document.createElement("span");
  fontTester.style.visibility = "hidden";
  fontTester.style.position = "absolute";
  fontTester.style.top = "-9999px";
  document.body.appendChild(fontTester);
  const defaultFonts = fontTester.style.fontFamily;

  const fontData = [
    { name: "Arial", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Arial Black", variations: ["Regular"] },
    { name: "Book Antiqua", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Calibri", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Cambria", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Candara", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Comic Sans MS", variations: ["Regular", "Bold"] },
    { name: "Consolas", variations: ["Regular", "Bold"] },
    { name: "Courier New", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Georgia", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Helvetica", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Impact", variations: ["Regular"] },
    { name: "Lucida Console", variations: ["Regular"] },
    { name: "Lucida Sans Unicode", variations: ["Regular", "Bold"] },
    { name: "Microsoft Sans Serif", variations: ["Regular"] },
    { name: "Monospace", variations: ["Regular"] },
    { name: "MS Sans Serif", variations: ["Regular"] },
    { name: "MS Serif", variations: ["Regular"] },
    { name: "Noto Sans", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Open Sans", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Palatino Linotype", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Roboto", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Tahoma", variations: ["Regular", "Bold"] },
    { name: "Trebuchet MS", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Verdana", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Webdings", variations: ["Regular"] },
    { name: "Wingdings", variations: ["Regular"] },
    { name: "Yu Gothic", variations: ["Regular", "Bold"] },
    { name: "Yu Gothic UI", variations: ["Regular", "Bold"] },
    { name: "Yu Gothic UI Semibold", variations: ["Regular"] },
    { name: "Zapf Dingbats", variations: ["Regular"] },
    { name: "Webdings", variations: ["Regular"] },
    { name: "Wingdings", variations: ["Regular"] },
    { name: "Yu Gothic", variations: ["Regular", "Bold"] },
    { name: "Yu Gothic UI", variations: ["Regular", "Bold"] },
    { name: "Yu Gothic UI Semibold", variations: ["Regular"] },
    { name: "Zapf Dingbats", variations: ["Regular"] },
    { name: "Andale Mono", variations: ["Regular"] },
    { name: "Courier New", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Lucida Console", variations: ["Regular"] },
    { name: "Lucida Sans Typewriter", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Lucida Typewriter", variations: ["Regular", "Bold", "Italic", "Bold Italic"] },
    { name: "Monaco", variations: ["Regular"] },
    { name: "OCR-A", variations: ["Regular"] },
    { name: "OCR-B", variations: ["Regular"] },
    { name: "Papyrus", variations: ["Regular"] },
    { name: "Playbill", variations: ["Regular"] },
    { name: "Symbol", variations: ["Regular"] },
  ];


  fontData.forEach(font => {
    font.variations.forEach(variation => {
      const fullFontName = `${font.name} ${variation}`;
      fontTester.style.fontFamily = `${defaultFonts}, ${fullFontName}`;
      if (fontTester.style.fontFamily !== defaultFonts) {
        fontList.push(fullFontName);
      }
    });
  });

  document.body.removeChild(fontTester);
  return fontList.join(", ");
})();




const domBlockers = (() => {
  const blockList = [
    "AdBlock", "AdGuard", "uBlock", "Adblock Plus", "NoScript"
    // Adicione outros bloqueadores de DOM aqui
  ];
  const detectedBlockers = blockList.filter(blocker => window[blocker]);
  return detectedBlockers.join(", ");
})();

const fontPreferences = (() => {
  const fontPrefs = [];
  if (document.fonts && document.fonts.length > 0) {
    document.fonts.forEach(font => {
      fontPrefs.push(`${font.family} ${font.style}`);
    });
  }
  return fontPrefs.join(", ");
})();



// Informações adicionais (continuação)
const audio = "AudioContext" in window ? "Supported" : "Not Supported";
const screenFrame = window.screen.orientation ? "Supported" : "Not Supported";
const canvas = (() => {
  const canvasEl = document.createElement("canvas");
  const context = canvasEl.getContext("webgl") || canvasEl.getContext("experimental-webgl");
  return context ? "Supported" : "Not Supported";
})();
const osCpu = window.navigator.oscpu || "N/A";
const deviceMemory = window.navigator.deviceMemory || "N/A";
const hardwareConcurrency = window.navigator.hardwareConcurrency || "N/A";
const timezone = window.Intl ? window.Intl.DateTimeFormat().resolvedOptions().timeZone : "N/A";
const sessionStorage = "sessionStorage" in window ? "Supported" : "Not Supported";
const localStorage = "localStorage" in window ? "Supported" : "Not Supported";
const indexedDB = "indexedDB" in window ? "Supported" : "Not Supported";
const openDatabase = "openDatabase" in window ? "Supported" : "Not Supported";
const cpuClass = window.navigator.cpuClass || "N/A";
const plugins = (() => {
  const pluginList = [];
  for (let i = 0; i < navigator.plugins.length; i += 1) {
    pluginList.push(navigator.plugins[i].name);
  }
  return pluginList.join(", ");
})();
const touchSupport = "ontouchstart" in window ? "Supported" : "Not Supported";
const vendor = window.navigator.vendor || "N/A";
const vendorFlavors = window.navigator.vendorSub || "N/A";
const cookiesEnabled = window.navigator.cookieEnabled ? "Enabled" : "Disabled";
const colorGamut = window.matchMedia("(color-gamut: srgb)").matches ? "sRGB" : "Not sRGB";
const invertedColors = window.matchMedia("(inverted-colors: inverted)").matches ? "Inverted" : "Not Inverted";
const forcedColors = window.matchMedia("(forced-colors: active)").matches ? "Forced" : "Not Forced";
const monochrome = window.matchMedia("(monochrome)").matches ? "Monochrome" : "Not Monochrome";
const contrast = window.matchMedia("(prefers-contrast)").matches ? "High Contrast" : "Not High Contrast";
const reducedMotion = window.matchMedia("(prefers-reduced-motion)").matches ? "Reduced Motion" : "Not Reduced Motion";
const hdr = window.matchMedia("(prefers-color-scheme: hdr)").matches ? "HDR" : "Not HDR";
const math = (() => {
  const mathObject = {
    maxInteger: Number.MAX_SAFE_INTEGER,
    pi: Math.PI,
    sqrt2: Math.SQRT2
  };
  return JSON.stringify(mathObject);
})();
const pdfViewerEnabled = "PDFViewer" in window ? "Enabled" : "Disabled";
const architecture = window.navigator.userAgent.includes("WOW64") ? "x64" : "x86";
const applePay = "ApplePaySession" in window ? "Supported" : "Not Supported";
const privateClickMeasurement = "webkit" in window && "privateClickMeasurement" in window.webkit ? "Supported" : "Not Supported";
const webGlBasics = "WebGLRenderingContext" in window ? "Supported" : "Not Supported";
const webGlExtensions = (() => {
  const extensions = [];
  const context = document.createElement("canvas").getContext("webgl") || document.createElement("canvas").getContext("experimental-webgl");
  if (context) {
    const exts = context.getSupportedExtensions();
    for (let i = 0; i < exts.length; i += 1) {
      extensions.push(exts[i]);
    }
  }
  return extensions.join(", ");
})();

export const deviceInfo = () => {
  return {
    httpBrowserColorDepth: window.screen.colorDepth,
    httpBrowserJavaEnabled: navigator.javaEnabled(),
    httpBrowserJavaScriptEnabled: true,
    httpBrowserLanguage: navigator.language,
    httpBrowserScreenHeight: window.screen.height,
    httpBrowserScreenWidth: window.screen.width,
    httpBrowserTimeDifference: new Date().getTimezoneOffset(),
    httpDeviceChannel: "Browser",
    userAgentBrowserValue: navigator.userAgent,
  };
};

// Combina todas as informações estáticas em uma string
const combinedData =
  browserName +
  browserVersion +
  os +
  deviceType +
  preferredLanguage +
  screenResolution +
  cpuCores +
  colorDepth +
  pixelDepth +
  gpuInfo +
  webglVersion +
  mobileInfo +
  htmlVersion +
  userAgentMasked +
  tlsCompatibility +
  supportsWebSockets +
  ecmascriptVersion +
  supportsWebRTC +
  supportsWebWorkers +
  supportsServiceWorkers + // alterado para dar suporte a aba anônima no fire fox
  platform +
  osVersion +
  browserVendor +
  browserProduct +
  browserBuildID +
  fonts +
  domBlockers +
  fontPreferences +
  audio +
  screenFrame +
  canvas +
  osCpu +
  deviceMemory +
  hardwareConcurrency +
  timezone +
  sessionStorage +
  localStorage +
  indexedDB +
  openDatabase +
  cpuClass +
  plugins +
  touchSupport +
  vendor +
  vendorFlavors +
  cookiesEnabled +
  colorGamut +
  invertedColors +
  forcedColors +
  monochrome +
  contrast +
  reducedMotion +
  hdr +
  math +
  pdfViewerEnabled +
  architecture +
  applePay +
  privateClickMeasurement +
  webGlBasics +
  webGlExtensions;

export default async function browserHash() {
  return SHA256(combinedData);
}