import { render, staticRenderFns } from "./AppointmentsLayout.vue?vue&type=template&id=3f4737f4&scoped=true&"
import script from "./AppointmentsLayout.vue?vue&type=script&lang=js&"
export * from "./AppointmentsLayout.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentsLayout.vue?vue&type=style&index=0&id=3f4737f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f4737f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBottomNavigation,VBtn,VIcon,VSpacer})
