import storage from "../storage";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  state: () => ({
    modalContainer: false,
    appBottomSheet: false,
    mapViewModal: false,
    deliveryModal: false,
    appBadge: storage.appBadge(),
    orderSuccess: false,
    // Auth
    appLoading: false,
    appLoadingLabel: "",
    accessToken: storage.accessToken(),
    refreshToken: storage.refreshToken(),
    isAuthenticated: !!storage.accessToken(),
    // Settings
    settings: storage.settings(),
  }),
  getters,
  actions,
  mutations,
};
