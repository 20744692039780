/* eslint-disable eqeqeq */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import uuidString from "uuid-by-string";
import Big from "@/plugins/big";
import storage from "@/plugins/storage";
import schema from "@/http/apollo/schema";
import { useConfigStore } from "@/storex/configStore";

const SHOPPING_KEY = "shopping_cart";
// Store the results of the filtered items to avoid unnecessary requests
const shoppingResults = new Map();

function nextTick(callback = null) {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback?.();
      resolve();
    }, 10);
  });
}

function calcTotal(d) {
  return d.items.reduce((a, c) => {
    const acc = Big(a);
    const price = Big(
      c.item?.discountInfo?.price ||
      c.item?.listPrice ||
      c.item?.promotionalPrice ||
      c.item.price,
    );
    const quantity = Big(c.quantity);
    return acc.add(price.mul(quantity)).toFixedNumber();
  }, 0);
}

function shoppingLocalHandler({ initShoppingCart, options }) {
  async function getLocalData() {
    await nextTick();

    if (initShoppingCart.items?.length) {
      return initShoppingCart;
    }

    const data = storage.getItem(SHOPPING_KEY);
    if (data) {
      return JSON.parse(data);
    }
    return initShoppingCart;
  }

  async function postLocal() {
    const d = await getLocalData();
    const i = d.items.findIndex(({ item }) => (item.id === options.item.id));

    const next = {
      quantity: options.quantity,
      item: options.item,
    };

    if (i < 0) {
      d.items.push(next);
    } else {
      d.items[i] = next;
    }

    d.total = calcTotal(d);
    storage.setItem(SHOPPING_KEY, JSON.stringify(d));
    return d;
  }

  async function removeLocal() {
    const d = await getLocalData();
    d.items.splice(options.index, 1);
    d.total = calcTotal(d);
    storage.setItem(SHOPPING_KEY, JSON.stringify(d));
    return d;
  }

  async function removeAllLocal() {
    await nextTick();
    storage.removeItem(SHOPPING_KEY);
    return {
      total: 0,
      items: [],
    };
  }

  return {
    load() {
      return getLocalData();
    },

    update() {
      return postLocal();
    },

    remove() {
      return removeLocal();
    },

    removeAll() {
      return removeAllLocal();
    },
  };
}

function shoppingServerHandler({ initShoppingCart, options }) {
  function getPostData() {
    if (options.moveToServer) {
      return initShoppingCart.items.map((v) => ({
        itemId: v.item.id,
        quantity: v.quantity,
      }));
    }
    return [
      {
        itemId: options.item.id,
        quantity: options.quantity,
      },
    ];
  }

  async function fetchServer() {
    try {
      const {
        data: { me },
      } = await window.$apollo.query({
        query: schema.store.SHOPPING_CART,
      });
      return me.shoppingCart;
    } catch (e) {
      return initShoppingCart;
    }
  }

  async function postServer() {
    const items = getPostData();
    try {
      await window.$apollo.mutate({
        mutation: schema.store.SET_SHOPPING_CART_ITEM,
        variables: { items },
      });

      if (options.moveToServer) {
        storage.removeItem(SHOPPING_KEY);
      }

      return fetchServer();
    } catch (e) {
      return initShoppingCart;
    }
  }

  async function removeServer() {
    try {
      await window.$apollo.mutate({
        mutation: schema.store.UNSET_SHOPPING_CART_ITEM,
        variables: {
          id: options.id,
        },
      });
      return fetchServer();
    } catch (e) {
      return initShoppingCart;
    }
  }

  async function removeAllServer() {
    try {
      await window.$apollo.mutate({
        mutation: schema.store.CLEAR_SHOPPING_CART,
      });
      return fetchServer();
    } catch (e) {
      return initShoppingCart;
    }
  }

  return {
    load() {
      return fetchServer();
    },

    update() {
      return postServer();
    },

    remove() {
      return removeServer();
    },

    removeAll() {
      return removeAllServer();
    },
  };
}

function shoppingHandler({
  isAuthenticated,
  initShoppingCart,
  options,
}) {
  if (isAuthenticated) {
    return shoppingServerHandler({ initShoppingCart, options });
  }
  return shoppingLocalHandler({ initShoppingCart, options });
}

async function getFilteredItems(data, storeId, listPriceId) {
  if (!listPriceId || !data.items.length) {
    return data;
  }

  const ids = data.items.map((v) => v.item.id);
  const qtd = data.items.reduce((a, c) => a + c.quantity, 0);
  const key = uuidString(`${ids.join("-")}-${qtd}-${storeId}-${listPriceId}`);

  if (shoppingResults.has(key)) {
    // Return the cached data
    return shoppingResults.get(key);
  }

  const { data: { items } } = await window.$apollo.query({
    query: schema.items.FILTERED_ITEMS_QUERY,
    variables: {
      deleted: false,
      first: 100,
      page: 1,
      ids,
      storeId,
      listPriceId,
    },
  });
  data.items.forEach((v) => {
    const item = items.data.find((i) => i.id === v.item.id);
    if (item) {
      v.item.listPrice = item.listPrice;
      v.item.price = item.price;
      v.item.promotionalPrice = item.promotionalPrice;
    }
  });
  // Calculate the total again
  data.total = calcTotal(data);
  // Cache the filtered data
  shoppingResults.set(key, data);
  return data;
}

export default {
  async setShopping({ commit }, data) {
    const config = useConfigStore();
    const nextData = await getFilteredItems(
      data,
      config.currentStore,
      config.listPriceId,
    );
    commit("shopping", nextData);
    commit("loading", false);
  },

  async getShoppingCart({ commit, dispatch, getters, rootGetters }) {
    commit("loading", true);
    const data = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
    }).load();
    await dispatch("setShopping", data);
  },

  async setShoppingItem({ commit, dispatch, getters, rootGetters }, values) {
    const timeout = setTimeout(() => {
      commit("appLoading", true, { root: true });
    }, 100);

    const data = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
      options: values,
    }).update();
    await dispatch("setShopping", data);

    clearTimeout(timeout);
    commit("appLoading", false, { root: true });
  },

  async removeShoppingItem({ commit, dispatch, getters, rootGetters }, values) {
    const timeout = setTimeout(() => {
      commit("appLoading", true, { root: true });
    }, 100);

    const data = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
      options: values,
    }).remove();
    await dispatch("setShopping", data);
    if (!data.items?.length) {
      commit("checkout/reset", null, { root: true });
    }

    clearTimeout(timeout);
    commit("appLoading", false, { root: true });
  },

  async removeAllShoppingCart({ commit, dispatch, getters, rootGetters }) {
    const timeout = setTimeout(() => {
      commit("appLoading", true, { root: true });
    }, 100);

    const data = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
    }).removeAll();
    await dispatch("setShopping", data);
    commit("checkout/reset", null, { root: true });

    clearTimeout(timeout);
    commit("appLoading", false, { root: true });
  },

  async setShoppingCartServer({ dispatch, getters, rootGetters }) {
    const data = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
      options: { moveToServer: true },
    }).update();
    await dispatch("setShopping", data);
  },

  async generateItems({ commit, dispatch, getters, rootGetters }, items) {
    const timeout = setTimeout(() => {
      commit("appLoading", true, { root: true });
    }, 100);

    // clean current cart
    const removed = await shoppingHandler({
      isAuthenticated: rootGetters.isAuthenticated,
      initShoppingCart: getters.shopping,
    }).removeAll();
    await dispatch("setShopping", removed);

    for (const val of items) {
      const data = await shoppingHandler({
        isAuthenticated: rootGetters.isAuthenticated,
        initShoppingCart: getters.shopping,
        options: val,
      }).update();
      await dispatch("setShopping", data);
    }

    clearTimeout(timeout);
    commit("appLoading", false, { root: true });
  },
};
