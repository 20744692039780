import { gql } from "@apollo/client/core";

export const ITEM_QUERY = gql`
  query($id: ID! $storeId: ID!) {
    item(id: $id storeId: $storeId) {
      code
      description
      stockQuantity
      id
      images
      name
      price
      promotionalPrice
      attrs
      categories {
        id
        name
        parentId
        status
        childs {
          id
          name
          parentId
          status
        }
      }
      variants(deleted: false) {
        id
        attrs
      }
    }
  }
`;

export const FILTERED_ITEMS_QUERY = gql`
  query(
    $deleted: Boolean
    $first: Int!
    $page: Int!
    $storeId: ID!
    $listPriceId: ID
    $ids: [ID!]
  ) {
    items(
      deleted: $deleted
      first: $first
      page: $page
      storeId: $storeId
      listPriceId: $listPriceId
      ids: $ids
    ) {
      data {
        id
        price
        promotionalPrice
        listPrice
      }
    }
  }
`;

export const ITEMS_ID_BY_CATEGORY = gql`
  query(
    $categories: [Int!]
    $storeId: ID!
    $ids: [ID!]
  ) {
    items(
      categories: $categories
      deleted: false
      first: 50
      page: 1
      storeId: $storeId
      ids: $ids
    ) {
      data {
        id
      }
    }
  }
`;

export const ITENS_QUERY = gql`
  query(
    $categories: [Int!]
    $deleted: Boolean
    $first: Int!
    $page: Int!
    $storeId: ID!
    $listPriceId: ID
    $ids: [ID!]
    $q: String
    $orderBy: [OrderByClause!]
    $where: QueryItemsWhereWhereConditions
  ) {
    items(
      categories: $categories
      deleted: $deleted
      first: $first
      orderBy: $orderBy
      page: $page
      storeId: $storeId
      listPriceId: $listPriceId
      ids: $ids
      q: $q
      where: $where
    ) {
      data {
        attrs
        code
        description
        id
        images
        name
        price
        promotionalPrice
        stockQuantity
        storeId
        listPrice
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
`;

export const LIST_PRICE_QUERY = gql`
  query($itemId: ID!, $listPriceId: ID!) {
    listPriceItem(itemId: $itemId, listPriceId: $listPriceId) {
      id
      listPriceId
      itemId
      price
    }
  }
`;

const schema = {
  FILTERED_ITEMS_QUERY,
  ITEM_QUERY,
  ITENS_QUERY,
  LIST_PRICE_QUERY,
  ITEMS_ID_BY_CATEGORY,
};

export default schema;
