<template>
  <v-expansion-panels
    :dark="dark"
    :value="value"
    @change="$emit('input', $event)"
    accordion
    flat
    focusable
    multiple
  >
    <slot></slot>
  </v-expansion-panels>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "menu-items-container",
  props: ["value", "dark"],
});
</script>
