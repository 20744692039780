const inputAlert = {
  show({
    actions = [],
    onDismiss = null,
    value = "",
    label = "Informar",
    type = null,
  }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("app-bottom-sheet", {
        actions,
        component: "app-input-alert",
        onDismiss,
        value,
        label,
        type,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("app-bottom-sheet", { dismiss: true });
  },
};

window.$inputAlert = inputAlert;

export default {
  install(Vue) {
    Vue.prototype.$inputAlert = inputAlert;
  },
};
