const url = window.Smart.env.GOOGLE_GEOCODE;

const location = {
  show({
    position,
    positionCallback,
    shouldGetPositionOnShow = false,
  }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("map-view-modal", {
        position,
        positionCallback,
        shouldGetPositionOnShow,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("map-view-modal");
  },

  isLocationSupported() {
    return !!navigator.geolocation;
  },

  async getAddressFromCoordinates(coords) {
    try {
      const res = await fetch(`${url}&address=${coords.latitude},${coords.longitude}`);
      if (!res.ok) {
        return {};
      }
      const results = await res.json().then((json) => json.results[0]);
      return {
        address: results.formatted_address,
        latitude: results.geometry.location.lat,
        longitude: results.geometry.location.lng,
      };
    } catch {
      return {};
    }
  },

  async getPostalCodeFromCoordinates(coords) {
    try {
      const res = await fetch(`${url}&address=${coords.latitude},${coords.longitude}`);
      if (!res.ok) {
        return null;
      }
      const results = await res.json().then((json) => json.results[0]);
      const postalCode = results.address_components.find((component) => {
        return component.types.includes("postal_code");
      });
      return postalCode ? postalCode.long_name : null;
    } catch {
      return null;
    }
  },

  getCurrentPosition() {
    if (!this.isLocationSupported()) {
      return null;
    }

    const geoOptions = {
      enableHighAccuracy: true,
      maximumAge: 30 * 1000,
      timeout: 20 * 1000,
    };

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        geoOptions,
      );
    });
  },
};

window.$location = location;

export default {
  install(Vue) {
    Vue.prototype.$location = location;
  },
};
