<template>
  <v-layout class="alert-container" row="row" justify-center="justify-center">
    <v-flex lg6="lg6" md8="md8" sm10="sm10" xs10="xs10">
      <v-alert
        class="mt-2"
        :icon="$vuetify.breakpoint.smAndUp ? undefined : false"
        :type="type"
        dismissible="dismissible"
        elevation="1"
        transition="fade-transition"
        v-model="alert"
        >{{ text }}</v-alert
      >
    </v-flex>
  </v-layout>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "app-messages",

  data: () => ({
    action: {},
    alert: false,
    text: "",
    type: "info",
    onDismiss: null,
  }),

  created() {
    this.timeout = -1;
    this.$events.$on("app-messages", this.handleEvent);
  },

  beforeDestroy() {
    this.$events.$off("app-messages", this.handleEvent);
  },

  watch: {
    alert(v) {
      if (!v && this.onDismiss) {
        setTimeout(this.onDismiss, 250);
      }
    },
  },

  methods: {
    handleEvent(e) {
      clearTimeout(this.timeout);

      if (this.alert && !e.dismiss) {
        this.alert = false;
      }

      this.$nextTick(() => {
        if (e.dismiss) {
          this.alert = false;
        } else {
          this.action = e.action.text ? e.action : {};
          this.text = e.text;
          this.type = e.type;
          this.alert = true;
          this.onDismiss = e.onDismiss;

          this.timeout = setTimeout(() => {
            this.alert = false;
          }, e.duration);
        }
      });
    },
  },
});
</script>

<style>
.alert-container {
  top: 20px;
  left: 0;
  max-height: 120;
  position: fixed;
  right: 0;
  z-index: 99998;
}

@media only screen and (min-width: 600px) {
  .alert-container {
    top: 80px;
  }
}
</style>
