<template>
  <div class="observe-container" v-observe-visibility="options">
    <loading-circle v-if="loading" color="rgba(0,0,0,0.36)"></loading-circle>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { LoadingCircle } from "@/components/styled";

export default defineComponent({
  name: "observe-visibility",
  props: ["loading"],
  components: {
    "loading-circle": LoadingCircle,
  },

  computed: {
    options() {
      return {
        callback: (isVisible) => this.$emit("visibility-changed", isVisible),
        throttle: 300,
        intersection: {
          rootMargin: "100px",
        },
      };
    },
  },
});
</script>

<style scoped>
.observe-container {
  align-items: center;
  display: flex;
  height: 120px;
  justify-content: center;
  padding: 16px;
}
</style>
