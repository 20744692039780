const inputAlertChange = {
  show({
    actions = [],
    onDismiss = null,
    value = "",
    total = 0,
    label = "Valor",
    placeholder = "0,00",
  }, delay = 100) {
    setTimeout(() => {
      window.$events.$emit("app-bottom-sheet", {
        actions,
        component: "app-input-alert-change",
        onDismiss,
        value,
        total,
        label,
        placeholder,
      });
    }, delay);
  },

  dismiss() {
    window.$events.$emit("app-bottom-sheet", { dismiss: true });
  },
};

window.$inputAlertChange = inputAlertChange;

export default {
  install(Vue) {
    Vue.prototype.$inputAlertChange = inputAlertChange;
  },
};
