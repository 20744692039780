<template>
  <div :style="styleComputed" />
</template>

<script>
import { defineComponent } from "vue";
import gmapsInit from "@/plugins/gmaps";

export default defineComponent({
  name: "map-view",
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    customMarker: Object,
    position: Object,
  },

  data: () => ({
    positionZoom: null,
  }),

  async mounted() {
    this.init();
  },

  beforeDestroy() {
    this.google.maps.event.removeListener(this.clickListener);
  },

  computed: {
    styleComputed() {
      if (this.flat) {
        const h = this.$vuetify.breakpoint.smAndDown ? "180px" : "270px";
        return `
          border-radius: 6px !important;
          height: ${h};
          max-width: 100%;
        `;
      }

      const { height } = this.$vuetify.breakpoint;
      const h = height * 0.5;

      return `
        border-radius: 6px !important;
        height: ${h}px;
        max-width: 100%;
      `;
    },
  },

  watch: {
    position(v) {
      this.handlePosition({
        lat: v.latitude,
        lng: v.longitude,
        zoom: true,
      });
    },
  },

  methods: {
    async init() {
      try {
        this.google = await gmapsInit();
        const position = {
          lat: this.position?.latitude || -23.544889,
          lng: this.position?.longitude || -46.638381,
        };
        this.map = new this.google.maps.Map(this.$el, {
          center: position,
          gestureHandling: "cooperative",
          zoom: this.position ? 15 : 6,
          disableDefaultUI: this.flat,
          // controls
          zoomControl: !this.flat,
          mapTypeControl: !this.flat,
          scaleControl: !this.flat,
          streetViewControl: !this.flat,
          rotateControl: !this.flat,
          fullscreenControl: !this.flat,
        });

        if (this.position) {
          this.marker = new this.google.maps.Marker({
            position,
            map: this.map,
            icon: this.customMarker?.icon,
          });
        }

        this.setListeners();
      } catch (error) {
        // error
      }
    },

    handlePosition({ lat, lng, zoom }) {
      const position = { lat, lng };

      if (!this.marker) {
        this.marker = new this.google.maps.Marker({
          position,
          map: this.map,
          icon: this.customMarker?.icon,
        });
      } else {
        this.marker.setPosition(position);
      }

      setTimeout(() => {
        this.map.panTo(position);
        if (!this.positionZoom) {
          this.positionZoom = 15;
          zoom && this.map.setZoom(this.positionZoom);
        }
      }, 200);

      // Update new position
      const nextPosition = {
        latitude: lat,
        longitude: lng,
      };
      this.$emit("update:next-position", nextPosition);
    },

    setListeners() {
      if (this.flat || this.customMarker) {
        return;
      }

      this.clickListener = this.map.addListener("click", (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        this.handlePosition({ lat, lng });
      });
    },
  },
});
</script>
