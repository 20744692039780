import { gql } from "@apollo/client/core";

export const PET = gql`
  query ($id: ID!) {
    pet(id: $id) {
      id
      name
      species
      color
      coat
      status
      size
      dateOfBirth
      gender
      observations
      breed
      weight
      avatar
      updatedAt
    }
  }
`;

export const PETS = gql`
  query ($orderBy: [OrderByClause!]) {
    pets(orderBy: $orderBy) {
      id
      name
      species
      color
      coat
      status
      size
      dateOfBirth
      gender
      observations
      breed
      weight
      avatar
      updatedAt
    }
  }
`;

export const CREATE_PET = gql`
  mutation (
    $species: String!
    $color: String
    $coat: String
    $status: PetStatus
    $size: String
    $name: String!
    $dateOfBirth: Date!
    $gender: String
    $breed: String
    $weight: Float
    $avatar: Upload
  ) {
    setPet(
      species: $species
      color: $color
      coat: $coat
      status: $status
      size: $size
      name: $name
      dateOfBirth: $dateOfBirth
      gender: $gender
      breed: $breed
      weight: $weight
      avatar: $avatar
    ) {
      id
      species
      color
      coat
      status
      size
      name
      dateOfBirth
      gender
      breed
      avatar
    }
  }
`;

export const UPDATE_PET = gql`
  mutation (
    $id: ID!
    $species: String
    $color: String
    $coat: String
    $status: PetStatus
    $size: String
    $name: String
    $dateOfBirth: Date
    $gender: String
    $breed: String
    $weight: Float
    $avatar: Upload
  ) {
    updatePet(
      id: $id
      species: $species
      color: $color
      coat: $coat
      status: $status
      size: $size
      name: $name
      dateOfBirth: $dateOfBirth
      gender: $gender
      breed: $breed
      weight: $weight
      avatar: $avatar
    ) {
      id
      species
      color
      coat
      status
      size
      name
      dateOfBirth
      gender
      breed
      avatar
    }
  }
`;

export const STATS = gql`
  query petStats(
    $petId: ID!
    $startDate: Date
    $endDate: Date
  ) {
    petStats(
      petId: $petId
      startDate: $startDate
      endDate: $endDate
    ) {
      totals
      weighing
    }
  }
`;

const schema = {
  PET,
  PETS,
  CREATE_PET,
  UPDATE_PET,
  STATS,
};

export default schema;
