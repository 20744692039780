/* eslint-disable no-underscore-dangle */

export const getStringAddress = ({
  address,
  addressNumber,
  province,
  city,
  state,
  postalCode,
}) => {
  const l = [];
  address && l.push(address);
  addressNumber && l.push(addressNumber);
  province && l.push(province);
  city && l.push(`${city}${state && (` ${state}`)}`);
  postalCode && l.push(postalCode);
  return l.join(", ");
};

export const getTitleAddress = (val) => window.$utils.capitalize(val.type);

function AddressFactory(payload) {
  if (!payload) {
    return [];
  }

  const d0 = {
    title: "Principal",
    subtitle: getStringAddress(payload),
    hasPostalCode: !!payload?.postalCode,
  };

  Object.keys(payload).forEach((key) => {
    if (key !== "addresses") {
      d0[key] = payload[key];
    }
  });

  const data = [d0];
  const addresses = payload.addresses.filter((v) => !v.deleted);
  addresses.forEach((val) => {
    data.push({
      ...val,
      title: getTitleAddress(val),
      subtitle: getStringAddress(val),
      hasPostalCode: !!val?.postalCode,
    });
  });

  return data;
}

export default AddressFactory;
