export default {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  appBadge(state) {
    return state.appBadge;
  },
  accessToken(state) {
    return state.accessToken;
  },
  notificationActive(state) {
    return state.settings.notification;
  },
  appLoading(state) {
    return state.appLoading;
  },
  appLoadingLabel(state) {
    return state.appLoadingLabel;
  },
  appBottomSheet(state) {
    return state.appBottomSheet;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
  baseUrl() {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    return `${origin}${pathname}#`;
  },
};
