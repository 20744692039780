/* eslint-disable import/extensions */
import Vue from "vue";
import VueRouter from "vue-router";

import Store from "../views/Store/index";

const About = () => import("../views/About/index.vue");
const Pet = () => import("../views/Pets/Pet.vue");
const Pets = () => import("../views/Pets/index.vue");
const Appointments = () => import("../views/Appointments/index.vue");
const Appointment = () => import("../views/Appointments/Appointment.vue");
const Vaccines = () => import("../views/Appointments/Vaccines.vue");
const Vaccine = () => import("../views/Appointments/Appointment.vue");
const Account = () => import("../views/Account/index.vue");
const AccountEdit = () => import("../views/Account/Edit.vue");
const AccountPassword = () => import("../views/Account/Password.vue");
const AccountManage = () => import("../views/Account/Manage.vue");
const Cards = () => import("../views/Account/Cards.vue");
const Address = () => import("../views/Address/Edit.vue");
const Addresses = () => import("../views/Address/index.vue");
const Checkout = () => import("../views/Checkout/index.vue");
const Item = () => import("../views/StoreItem/index.vue");
const LoginOptions = () => import("../views/LoginOptions/index.vue");
const Notifications = () => import("../views/Notifications/index.vue");
const Order = () => import("../views/Orders/Order.vue");
const Orders = () => import("../views/Orders/index.vue");
const Search = () => import("../views/Search/index.vue");
const Shopping = () => import("../views/Shopping/index.vue");
const NotFound = () => import("../components/NotFound.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter(to, from, next) {
      next("/store");
    },
  },
  {
    path: "/store",
    name: "store",
    component: Store,
    meta: { ecommerce: true },
  },
  {
    path: "/store/search",
    name: "search",
    component: Search,
    meta: { ecommerce: true, title: "Busca" },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    meta: { title: "Notificações" },
  },
  {
    path: "/account/edit",
    name: "account-edit",
    component: AccountEdit,
    meta: { requiresAuth: true, title: "Meus dados" },
  },
  {
    path: "/account/manage",
    name: "account-manage",
    component: AccountManage,
    meta: { requiresAuth: true, title: "Gerenciar meus dados" },
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: { requiresAuth: true, title: "Minha conta" },
  },
  {
    path: "/appointments",
    name: "appointments",
    component: Appointments,
    meta: { requiresAuth: true, appointments: true, title: "Agenda" },
  },
  {
    path: "/appointments/:id",
    name: "appointment",
    component: Appointment,
    meta: { requiresAuth: true, appointments: true, title: "Agendamento" },
  },
  {
    path: "/vaccines",
    name: "vaccines",
    component: Vaccines,
    meta: { requiresAuth: true, appointments: true, title: "Vacinas" },
  },
  {
    path: "/vaccines/:id",
    name: "vaccine",
    component: Vaccine,
    meta: { requiresAuth: true, appointments: true, title: "Vacina" },
  },
  {
    path: "/pets",
    name: "pets",
    component: Pets,
    meta: { requiresAuth: true, appointments: true, title: "Meus Pets" },
  },
  {
    path: "/pets/:id",
    name: "pet",
    component: Pet,
    meta: { requiresAuth: true, appointments: true, title: "Meu Pet" },
  },
  {
    path: "/account/addresses",
    name: "addresses",
    component: Addresses,
    meta: { requiresAuth: true, title: "Meus endereços" },
  },
  {
    path: "/account/address",
    name: "address",
    component: Address,
    meta: { requiresAuth: true, title: "Meu endereço" },
  },
  {
    path: "/account/cards",
    name: "cards",
    component: Cards,
    meta: { requiresAuth: true, title: "Meus cartões" },
  },
  {
    path: "/account/orders",
    name: "orders",
    component: Orders,
    meta: { requiresAuth: true, ecommerce: true, title: "Meus pedidos" },
  },
  {
    path: "/account/order",
    name: "order",
    component: Order,
    meta: { requiresAuth: true, ecommerce: true, title: "Meu pedido" },
  },
  {
    path: "/account/password",
    name: "account-password",
    component: AccountPassword,
    meta: { requiresAuth: true, title: "Alterar senha" },
  },
  {
    path: "/account/login-options",
    name: "login-options",
    component: LoginOptions,
    meta: { title: "Entrar" },
  },
  {
    path: "/store/item",
    name: "item",
    component: Item,
    meta: { ecommerce: true },
  },
  {
    path: "/store/shopping",
    name: "shopping",
    component: Shopping,
    meta: { ecommerce: true, title: "Meu carrinho" },
  },
  {
    path: "/store/checkout",
    name: "checkout",
    component: Checkout,
    meta: { requiresAuth: true, ecommerce: true, title: "Pedido" },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { title: "Sobre nós" },
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: { title: "Página não encontrada" },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const $store = window.$store;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAppointments = to.matched.some((record) => record.meta.appointments);
  const isEcommerce = to.matched.some((record) => record.meta.ecommerce);
  const appointmentsActive = window.Smart.appointments;
  const ecommerceActive = window.Smart.ecommerce;

  if (to.query?.coupon_code) {
    window.$events.$emit("coupon-code", to.query.coupon_code);
  }

  if (requiresAuth && !$store.getters.isAuthenticated) {
    next({ name: "login-options", query: { redirect: to.fullPath } });
  } else if (isAppointments && !appointmentsActive) {
    if (!ecommerceActive) {
      next({ name: "not-found" });
    } else {
      next({ name: "store" });
    }
  } else if (isEcommerce && !ecommerceActive) {
    if (!appointmentsActive) {
      next({ name: "not-found" });
    } else {
      next({ name: "appointments" });
    }
  } else if ($store.state.modalContainer) {
    window.$events.$emit("modal-container", false);
    next(false);
  } else if ($store.getters.appBottomSheet) {
    window.$events.$emit("app-bottom-sheet");
    next(false);
  } else if ($store.state.mapViewModal) {
    window.$events.$emit("map-view-modal");
    next(false);
  } else if ($store.state.deliveryModal) {
    window.$events.$emit("delivery-modal");
  } else {
    next();
  }
});

export default router;
