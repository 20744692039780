<template>
  <v-container
    class="loading-container"
    fluid="fluid"
    fill-height="fill-height"
  >
    <v-layout
      class="loading-container"
      justify-center="justify-center"
      align-center="align-center"
      style="height: 300px"
    >
      <loading-circle color="rgba(0,0,0,0.36)"></loading-circle>
    </v-layout>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import { LoadingCircle } from "@/components/styled";

export default defineComponent({
  name: "app-loading",
  components: {
    "loading-circle": LoadingCircle,
  },
});
</script>

<style scoped>
.loading-container {
  background-color: #fafafa !important;
}
</style>
