import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";

const $http = axios.create({
  baseURL: window.Smart.env.BASE_URL,
  timeout: 90 * 1000,
});

Vue.use(VueAxios, $http);
Vue.use(VueAuthenticate, {
  baseURL: window.Smart.env.BASE_URL,
  loginUrl: window.Smart.env.OAUTH_API,
  providers: {
    google: {
      clientId: window.Smart.env.GOOGLE_CLIENT_ID,
    },
    facebook: {
      clientId: window.Smart.env.FACEBOOK_CLIENT_ID,
    },
  },
});

$http.interceptors.request.use((request) => {
  const $store = window.$store;
  if (request.url.includes("webpush")) {
    if ($store.getters.accessToken) {
      request.headers.Authorization = `Bearer ${$store.getters.accessToken}`;
    }
    return request;
  }
  if (request.url.includes("oauth")) {
    request.data.client_id = window.Smart.env.CLIENT_ID;
    request.data.client_secret = window.Smart.env.CLIENT_SECRET;
  } else if ($store.getters.accessToken) {
    request.headers.Authorization = `Bearer ${$store.getters.accessToken}`;
  }
  return request;
});

const $axios = axios.create({
  timeout: 10 * 1000,
});

$axios.interceptors.request.use((req) => {
  const $store = window.$store;
  if (req.url.includes("api/") && $store.getters.accessToken) {
    req.headers.Authorization = `Bearer ${$store.getters.accessToken}`;
  }
  return req;
});

window.$axios = $axios;

const axiosPlugin = {
  install(VueInstance) {
    VueInstance.prototype.$axios = $axios;
  },
};

Vue.use(axiosPlugin);

export default $http;
