export default (num, size) => {
  let tamanho = size - 2;
  let numeros = num.substring(0, tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  const digitos = num.substring(tamanho);

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * (pos -= 1);
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== digitos.charAt(0)) {
    return false;
  }

  tamanho += 1;
  numeros = num.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * (pos -= 1);
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) {
    return false;
  }
  return true;
};
