import { gql } from "@apollo/client/core";

export const APPOINTMENT = gql`
  query ($id: ID!) {
    appointment(id: $id) {
      addressId
      attachments
      dateTime
      deleted
      dateTimeEnd
      id
      location
      observations
      pet {
        avatar
        breed
        coat
        color
        dateOfBirth
        gender
        id
        name
        observations
        size
        species
        status
        updatedAt
        userId
        weight
      }
      petId
      serviceId
      services {
        createdAt
        deleted
        description
        duration
        id
        type
        price
        storeId
        updatedAt
      }
      specificData
      status
      statusManager
      type
      description
      userId
      weight
    }
  }
`;

export const APPOINTMENTS = gql`
  query (
    $orderBy: [OrderByClause!]
    $where: QueryAppointmentsWhereWhereConditions
    $first: Int!
    $page: Int
  ) {
    appointments(orderBy: $orderBy, first: $first, page: $page, where: $where) {
      data {
        dateTime
        deleted
        dateTimeEnd
        id
        location
        observations
        pet {
          avatar
          id
          name
          gender
          status
          updatedAt
        }
        petId
        serviceId
        services {
          description
          duration
          id
          type
          price
        }
        specificData
        status
        statusManager
        type
        description
        weight
      }
      paginatorInfo {
        currentPage
        hasMorePages
        perPage
        total
      }
    }
  }
`;

export const WORKING_HOURS = gql`
  query workingHours($storeId: ID!, $date: Date) {
    workingHours(storeId: $storeId, date: $date)
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation(
    $petId: Int!
    $dateTime: DateTime!
    $serviceId: Int!
    $addressId: Int
    $location: String
    $observations: String
  ) {
    createAppointment(
      petId: $petId
      dateTime: $dateTime
      serviceId: $serviceId
      addressId: $addressId
      location: $location
      observations: $observations
    ) {
      id
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation ($id: ID!, $statusManager: AppointmentStatusManager!) {
    updateAppointment(id: $id, statusManager: $statusManager) {
      id
    }
  }
`;

const schema = {
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  APPOINTMENT,
  APPOINTMENTS,
  WORKING_HOURS,
};

export default schema;
