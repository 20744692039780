const vars = {
  storage: {
    search_history: "search_history",
  },

  EMAIL: "email",
  NOME_DOCUMENTO: "name+doc",
  TELEFONE: "tel",
};

window.$vars = vars;

export default {
  install(Vue) {
    Vue.prototype.$vars = vars;
  },
};
