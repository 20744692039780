<template>
  <div>
    <banners :visible="bannersVisible"></banners>
    <app-cep v-if="bannersVisible"></app-cep>
    <v-container class="scroll-y">
      <v-layout
        class="flex-column"
        v-if="empty"
        align-center
        justify-center
        style="height: 300px"
      >
        <h1 class="grey--text text--darken-1" :style="emptyStyle">
          Nenhum resultado para a busca
        </h1>
      </v-layout>
      <v-layout class="flex-column mx-auto" style="max-width: 1280px" v-else>
        <store-skeleton-loader
          v-if="!items.promoItemsLoaded || items.loading"
        />
        <slide-group
          @click-title="showPromo"
          icon-color="yellow darken-2"
          icon="mdi-flash"
          title="Produtos em promoção"
          v-bind:data="items.promoItems"
          v-else-if="items.promoItemsLoaded && items.hasPromo"
        >
          <template v-slot:item="{ item }">
            <div :class="slideGroupItemClass">
              <item-card :item="item" @add-cart="addToShopping(item)" />
            </div>
          </template>
        </slide-group>

        <store-skeleton-loader v-if="!brands.loaded || items.loading" />
        <slide-group-animated
          animated
          title="Navegue pelas marcas"
          v-bind:data="brands.data"
          v-else-if="brands.loaded && brands.hasBrands"
        >
          <template v-slot:item="{ item }">
            <div :style="slideGroupBrandStyle">
              <brand-card
                :label="item.name"
                :src="item.imageUrl"
                :value="item.id"
              />
            </div>
          </template>
        </slide-group-animated>

        <store-skeleton-loader
          v-if="!items.rankingItemsLoaded || items.loading"
        />
        <slide-group
          @click-title="searchItems"
          icon="mdi-chevron-right"
          title="Mais procurados"
          v-bind:data="items.rankingItems"
          v-else-if="items.rankingItemsLoaded"
        >
          <template v-slot:item="{ item }">
            <div :class="slideGroupItemClass">
              <item-card :item="item" @add-cart="addToShopping(item)" />
            </div>
          </template>
        </slide-group>

        <div v-if="items.categorizedItemsLoaded">
          <slide-group
            :key="category.id"
            :title="category.name"
            @click-title="onCategoryClick(category)"
            icon="mdi-chevron-right"
            v-bind:data="category.data"
            v-for="category in items.categorizedItems"
          >
            <template v-slot:item="{ item }">
              <div :class="slideGroupItemClass">
                <item-card :item="item" @add-cart="addToShopping(item)" />
              </div>
            </template>
          </slide-group>
        </div>

        <observe-visibility
          :loading="items.loadingMore"
          @visibility-changed="visibilityChanged"
          v-if="categories.data.length"
        />

        <fab-button-whatsapp></fab-button-whatsapp>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import AppCep from "@/components/AppCep";
import Banners from "@/components/Banners";
import FabButtonWhatsapp from "@/components/FabButtonWhatsapp";
import ItemCard from "@/components/ItemCard";
import BrandCard from "@/components/BrandCard";
import SlideGroup from "@/components/SlideGroup";
import SlideGroupAnimated from "@/components/SlideGroupAnimated";
import StoreSkeletonLoader from "@/components/StoreSkeletonLoader";
import ObserveVisibility from "@/components/ObserveVisibility";
import { useAccount } from "@/composables/account";
import { useItemsStore } from "@/storex/itemsStore";
import { useBrandsStore } from "@/storex/brandsStore";
import { useCategoriesStore } from "@/storex/categoriesStore";

export default defineComponent({
  name: "store-view",
  components: {
    AppCep,
    Banners,
    FabButtonWhatsapp,
    ItemCard,
    BrandCard,
    SlideGroup,
    SlideGroupAnimated,
    StoreSkeletonLoader,
    ObserveVisibility,
  },

  setup() {
    useAccount({ dedupingInterval: 2000 });
    const items = useItemsStore();
    const brands = useBrandsStore();
    const categories = useCategoriesStore();
    return { items, brands, categories };
  },

  created() {
    this.onStart();
  },

  computed: {
    itemsForPage() {
      return this.$vuetify.breakpoint.xsOnly ? 20 : 24;
    },

    empty() {
      return this.items.empty;
    },

    emptyStyle() {
      return `font-size: ${this.$vuetify.breakpoint.xsOnly ? 1.2 : 1.8}rem;`;
    },

    bannersVisible() {
      return true;
    },

    categoriesStyle() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "max-width: 1185px;";
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "max-width: 900px;";
      }
      return null;
    },

    slideGroupItemClass() {
      const v = this.$vuetify.breakpoint.name;
      return `slide-group-item slide-group-item-${v}`;
    },

    slideGroupBrandStyle() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "max-width: 180px; padding: 2px 6px";
      }
      return "max-width: 280px; padding: 2px 6px";
    },
  },

  methods: {
    ...mapActions("shopping", ["setShoppingItem"]),

    async onStart() {
      this.brands.fetchData();
      await this.items.fetchPromoData();
      await this.items.fetchRankingData();
    },

    async addToShopping(item) {
      await this.setShoppingItem({ item, quantity: 1 });
      this.$snackbar.show({
        text: "Item adicionado ao carrinho",
        action: {
          text: "VER CARRINHO",
          onPress: () => this.$router.push({ name: "shopping" }),
        },
      });
    },

    visibilityChanged(isVisible) {
      isVisible && this.items.fetchCategorizedData();
    },

    onCategoryClick(v) {
      this.$router.push({ name: "search", query: { c: v.id } });
    },

    showPromo() {
      this.$router.push({ name: "search", query: { promo: 1 } });
    },

    searchItems() {
      this.$router.push({ name: "search" });
    },
  },
});
</script>

<style scoped>
.promo-text {
  text-align: center;
  font-size: 0.9rem !important;
}
.slide-group-item {
  padding: 2px 6px;
}
.slide-group-item-xs {
  width: 180px;
}
.slide-group-item-sm {
  width: 186px;
}
.slide-group-item-md {
  width: 289.3px;
}
.slide-group-item-lg {
  width: 288px;
}
.slide-group-item-xl {
  width: 254.5px;
}
.fade-enter-active {
  animation: bounce-in 0.8s;
}
.fade-leave-active {
  animation: bounce-in 0.8s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
