/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
export default (number, decimals, usd) => {
  const dec_point = usd ? "." : ",";
  const thousands_sep = usd ? "," : ".";

  number = (`${number}`).replace(/[^0-9+\-Ee.]/g, "");
  const n = (!isFinite(number) ? 0 : parseFloat(number));
  const prec = (!isFinite(decimals) ? 0 : Math.abs(decimals));
  const sep = (typeof thousands_sep === "undefined" ? "," : thousands_sep);
  const dec = (typeof dec_point === "undefined" ? "." : dec_point);
  let s = "";
  const toFixedFix = (nn, precn) => {
    const k = Math.pow(10, precn);
    return `${Math.round(nn * k) / k}`;
  };

  s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};
