import { defineStore } from "pinia";
import { ITENS_QUERY } from "@/http/apollo/schema/itemsSchema";
import { getTreeIds } from "@/lib/category";
import { useCategoriesStore } from "./categoriesStore";
import { useConfigStore } from "./configStore";
import { useAccountStore } from "./accountStore";

const orderBy = [
  { column: "stock_quantity", order: "DESC" },
  { column: "ranking", order: "DESC" },
];

const promoWhereClause = {
  column: "PROMOTIONAL_PRICE",
  operator: "GT",
  value: 0,
};

const stockWhereClause = {
  column: "STOCK_QUANTITY",
  operator: "GT",
  value: 0,
};

const initialVars = Object.freeze({
  categories: [],
  deleted: false,
  first: 20,
  page: 1,
});

export const useItemsStore = defineStore("itemsStore", {
  state: () => ({
    loading: true,
    loadingMore: false,
    items: [],
    promoItemsData: null,
    rankingItemsData: null,
    categorizedItemsData: null,
  }),

  getters: {
    promoItems(state) {
      return state.promoItemsData || [];
    },
    rankingItems(state) {
      return state.rankingItemsData || [];
    },
    categorizedItems(state) {
      return state.categorizedItemsData?.filter((c) => !!c.data?.length) || [];
    },
    promoItemsLoaded(state) {
      return !!state.promoItemsData || !!this.listPriceId;
    },
    rankingItemsLoaded(state) {
      return !!state.rankingItemsData;
    },
    categorizedItemsLoaded(state) {
      return !!state.categorizedItemsData;
    },
    hasPromo(state) {
      return !!state.promoItemsData?.length && !this.listPriceId;
    },
    empty(state) {
      return !state.loading && !state.promoItemsData?.length && !state.rankingItemsData?.length;
    },
    listPriceId() {
      const config = useConfigStore();
      return config.listPriceId;
    },
  },

  actions: {
    async getInitialData() {
      const account = useAccountStore();
      await account.loadInitialUserData();
    },
    async fetchPromoData() {
      await this.getInitialData();
      const config = useConfigStore();

      // Se houver tabela de preços, não exibir a categoria de promoções
      if (config.listPriceId || this.promoItemsData?.length) {
        return;
      }

      const where = { AND: [promoWhereClause] };
      if (config.hideItemsWithoutStock) {
        where.AND.push(stockWhereClause);
      }

      try {
        this.loading = true;
        const query = ITENS_QUERY;
        const variables = {
          ...initialVars,
          orderBy,
          storeId: config.currentStore,
          where,
        };
        const { data } = await window.$apollo.query({ query, variables });
        this.promoItemsData = this.getSortedData(data.items);
      } catch {
        this.promoItemsData = [];
      } finally {
        this.loading = false;
      }
    },

    async fetchRankingData() {
      await this.getInitialData();

      if (this.rankingItemsData?.length) {
        return;
      }
      const config = useConfigStore();

      try {
        this.loading = true;
        const query = ITENS_QUERY;
        const variables = {
          ...initialVars,
          orderBy,
          storeId: config.currentStore,
          listPriceId: config.listPriceId,
        };

        if (config.hideItemsWithoutStock) {
          variables.where = stockWhereClause;
        }

        const { data } = await window.$apollo.query({ query, variables });
        this.rankingItemsData = this.getSortedData(data.items);
      } catch {
        this.rankingItemsData = [];
      } finally {
        this.loading = false;
      }
    },

    async fetchCategorizedData() {
      await this.getInitialData();

      if (this.loadingMore) {
        return;
      }
      const config = useConfigStore();
      const categories = useCategoriesStore();
      this.loadCategorizedItems(categories.data);

      const item = this.getNextItem();
      if (!item) {
        return;
      }
      const category = categories.data.find((c) => c.id === item.id);

      try {
        this.loadingMore = true;
        const query = ITENS_QUERY;
        const ids = getTreeIds(category);
        const variables = {
          ...initialVars,
          categories: ids,
          orderBy,
          storeId: config.currentStore,
          listPriceId: config.listPriceId,
        };

        if (config.hideItemsWithoutStock) {
          variables.where = stockWhereClause;
        }

        const { data } = await window.$apollo.query({ query, variables });
        item.data = this.getSortedData(data.items);
        const index = this.categorizedItemsData.findIndex((c) => {
          return c.id === item.id;
        });
        this.categorizedItemsData[index] = item;
      } finally {
        this.loadingMore = false;
      }
    },

    getNextItem() {
      if (!this.categorizedItemsData?.length) {
        return null;
      }
      return this.categorizedItemsData.find((c) => !c.data);
    },

    loadCategorizedItems(data) {
      if (!this.categorizedItemsData) {
        this.categorizedItemsData = data.map((c) => ({
          id: c.id,
          name: c.name,
          icon: c.icon,
          data: null,
        }));
      }
    },

    getSortedData({ data }) {
      return data.sort(() => Math.random() - 0.5);
    },

    resetItems() {
      this.items = [];
      this.promoItemsData = null;
      this.rankingItemsData = null;
      this.categorizedItemsData = null;
    },
  },
});