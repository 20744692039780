<template>
  <div class="root">
    <v-skeleton-loader type="heading" v-bind="titleAttrs" />
    <div class="content">
      <v-skeleton-loader
        class="mx-auto"
        type="image"
        v-bind="cardAttrs"
        v-for="item in items"
        :key="`skeleton-item-${item}`"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "store-skeleton-loader",

  computed: {
    cardAttrs() {
      let minWidth = 279;
      if (this.$vuetify.breakpoint.xlOnly) {
        minWidth = 244;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        minWidth = this.$vuetify.breakpoint.width * 0.26;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        minWidth = this.$vuetify.breakpoint.width * 0.4;
      }
      return {
        class: "mb-6",
        boilerplate: true,
        minWidth,
      };
    },
    titleAttrs() {
      return {
        boilerplate: true,
      };
    },

    items() {
      const { xsOnly, mdAndDown, lgAndDown } = this.$vuetify.breakpoint;
      if (xsOnly) {
        return 2;
      }
      if (mdAndDown) {
        return 3;
      }
      if (lgAndDown) {
        return 4;
      }
      return 5;
    },
  },
});
</script>

<style scoped>
.root {
  margin: 0 4px;
}
.content {
  display: flex;
  flex-direction: row;
}
:deep(.v-skeleton-loader__heading) {
  margin: 8px 6px 14px 6px;
  width: 180px;
}
:deep(.v-skeleton-loader__image) {
  height: 300px;
}
</style>
