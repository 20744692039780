/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

const FILTER_STORES = window.Smart.env.CATEGORIES_FILTER_STORES;
const FILTER_TERMS = window.Smart.env.CATEGORIES_FILTER_TERMS;
const FILTER_PLATFORMS = window.Smart.env.CATEGORIES_FILTER_PLATFORMS;

export function findCategory(categories, id) {
  if (!id) {
    return null;
  }
  for (const c of categories) {
    if (parseInt(id) === parseInt(c.id)) {
      return c;
    }
    if (c.childs?.length) {
      const find = findCategory(c.childs, id);
      if (find) {
        return find;
      }
    }
  }
  return null;
}

export function getTreeIds(c) {
  const ids = [];
  if (!c) {
    return ids;
  }
  ids.push(c.id);
  if (c.childs?.length) {
    for (const child of c.childs) {
      ids.push(...getTreeIds(child));
    }
  }
  return ids.map((id) => parseInt(id));
}

/**
 * Transforma uma lista de objetos com atributo "parentId"
 * em uma arvore com atributo children
 *
 * @param {any[]} list
 * @returns {any[]}
 */
export function createTree(list) {
  function getChilds(parentId) {
    const tree = [];
    for (const c of list) {
      if (c.parentId === parentId) {
        tree.push(Object.assign(c, {
          childs: getChilds(parseInt(c.id)),
        }));
      }
    }
    return tree;
  }
  return getChilds(null);
}

export function getReverseTree(categories, id, list) {
  const item = findCategory(categories, id);

  if (item) {
    if (!list.length) {
      item.menu = true;
      list.push(item);
    } else {
      if (item?.childs) {
        delete item.childs;
      }
      list.unshift(item);
    }
  } else {
    return list;
  }

  if (item.parentId) {
    return getReverseTree(categories, item.parentId, list);
  }

  return list;
}

export function getCategoryIds(categories, id) {
  return getTreeIds(findCategory(categories, id));
}

export function handleCategories(data) {
  if (!FILTER_STORES) {
    return createTree(data);
  }
  const stores = FILTER_STORES.split(",") || [];

  const findStore = (v = "") => {
    return stores.some((s) => v.includes(s));
  };
  const findPlatform = () => {
    const p = FILTER_PLATFORMS || "";
    if (p.includes("all")) {
      return true;
    }
    const userAgent = navigator.userAgent.toLowerCase();
    if (p.includes("ios")) {
      return /iphone|ipad|ipod/.test(userAgent);
    }
    if (p.includes("android")) {
      return /android/.test(userAgent);
    }
    return false;
  };

  let filteredCategories = data;
  const hasStore = window.Smart.urls.some(findStore);
  const shouldFilter = hasStore && findPlatform();

  if (shouldFilter) {
    const terms = FILTER_TERMS.split(",") || [];
    filteredCategories = filteredCategories.filter((v) => {
      const s = v.name.toLowerCase();
      return !terms.some((t) => s.includes(t));
    });
  }

  return createTree(filteredCategories);
}
