<template>
  <div class="app-search">
    <v-text-field
      :dense="$vuetify.breakpoint.xsOnly"
      :placeholder="$t('search.placeholder')"
      @keypress.enter="submit"
      class="app-search-input"
      clearable="clearable"
      hide-details="hide-details"
      prepend-inner-icon="mdi-magnify"
      ref="searchRef"
      rounded="rounded"
      solo="solo"
      type="search"
      v-model="searchValue"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "app-search",
  data: () => ({
    loading: false,
    searchValue: null,
    searchInput: null,
    timeout: -1,
    wait: false,
  }),

  created() {
    this.searchValue = this.$route.query.q || null;
  },

  watch: {
    "$route.query.q": "onQuery",

    searchValue(v) {
      const q = v?.toLowerCase?.();

      if (q === this.$route?.query?.q) {
        return;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.wait = true;
        const nextQuery = {
          name: "search",
          query: {
            ...this.$route.query,
            q: q ?? "",
          },
        };
        if (this.$route.name !== "search") {
          this.$router.push(nextQuery);
        } else {
          this.$router.replace(nextQuery);
        }
      }, 400);
    },
  },

  methods: {
    submit() {
      const q = this.$refs.searchRef?.$refs?.input?.value
        ?.trim()
        ?.toLowerCase?.();
      this.$refs.searchRef?.blur?.();

      if (q === this.$route?.query?.q) {
        return;
      }

      this.wait = true;
      this.$router.push({
        name: "search",
        query: {
          ...this.$route.query,
          q: q ?? "",
        },
      });
    },

    onQuery(v) {
      if (this.wait) {
        this.wait = false;
      } else {
        this.searchValue = v;
      }
    },
  },
});
</script>

<style scoped>
.app-search {
  display: flex;
  flex: 1;
  justify-content: center;
}

.app-search-input {
  max-width: 900px;
}
@media only screen and (max-width: 1264px) {
  .app-search-input {
    max-width: 650px;
  }
}
@media only screen and (max-width: 960px) {
  .app-search-input {
    max-width: 500px;
  }
}
</style>
