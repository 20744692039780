function Card() {
  const self = this;
  this.mask = { DEFAULT_CC: "#### #### #### ####", DEFAULT_CVC: "###" };
  this.type = {
    VISA: {
      name: "visa",
      label: "Visa",
      detector: /^4/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 99,
      src: require("@/assets/card_icon_visa.png"),
    },
    MASTERCARD: {
      name: "mastercard",
      label: "MasterCard",
      detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 99,
      src: require("@/assets/card_icon_mastercard.png"),
    },
    AMEX: {
      name: "amex",
      label: "America Express",
      detector: /^3[47][0-9]{13}$/,
      cardLength: 15,
      cvcLength: 4,
      maskCC: "#### ###### #####",
      maskCVC: "####",
      order: 99,
      src: require("@/assets/card_icon_amex.png"),
    },
    DISCOVER: {
      name: "discover",
      label: "Discover",
      detector: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 2,
      src: require("@/assets/card_icon_discover.png"),
    },
    HIPERCARD: {
      name: "hipercard",
      label: "HiperCard",
      detector: /^606282|384100|384140|384160/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 4,
      src: require("@/assets/card_icon_hipercard.png"),
    },
    DINERS: {
      name: "diners",
      label: "Diners Club",
      detector: /^(300|301|302|303|304|305|36|38)/,
      cardLength: 14,
      cvcLength: 3,
      maskCC: "#### ###### ####",
      maskCVC: self.mask.DEFAULT_CVC,
      order: 5,
      src: require("@/assets/card_icon_diners.png"),
    },
    JCB_15: {
      name: "jcb_15",
      label: "JCB",
      detector: /^2131|1800/,
      cardLength: 15,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 6,
      src: require("@/assets/card_icon_jcb.png"),
    },
    JCB_16: {
      name: "jcb_16",
      label: "JCB",
      detector: /^35(?:2[89]|[3-8]\d)/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 7,
      src: require("@/assets/card_icon_jcb.png"),
    },
    ELO: {
      name: "elo",
      label: "Elo",
      detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 1,
      src: require("@/assets/card_icon_elo.png"),
    },
    AURA: {
      name: "aura",
      label: "Aura",
      detector: /^((?!504175))^((?!5067))(^50[0-9])/,
      cardLength: 19,
      cvcLength: 3,
      maskCC: "###################",
      maskCVC: self.mask.DEFAULT_CVC,
      order: 3,
      src: require("@/assets/card_icon_aura.png"),
    },
    CABAL: {
      name: "cabal",
      label: "Cabal",
      detector: /(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)/,
      cardLength: 16,
      cvcLength: 3,
      maskCC: self.mask.DEFAULT_CC,
      maskCVC: self.mask.DEFAULT_CVC,
      order: 99,
      src: require("@/assets/card_icon_cabal.png"),
    },
  };
}

Card.prototype = {
  getType(value) {
    const cardNo = value.replace(/ /g, "");
    const orderedTypes = this.getOrderedTypes();
    let resultType = null;

    Object.keys(orderedTypes).forEach((key) => {
      if (!resultType && this.validator.number(cardNo, this.type[key])) {
        resultType = this.type[key];
      }
    });

    return resultType;
  },
  getTypeByName(name = "") {
    return this.type[name.toUpperCase()];
  },
  getOrderedTypes() {
    const types = {};
    let order = 1;

    while (order < 100) {
      const currentOrder = order;
      Object.keys(this.type).forEach((key) => {
        if (this.type[key].order === currentOrder) {
          types[key] = this.type[key];
        }
      });
      order += 1;
    }
    return types;
  },
  getList() {
    const types = [];
    Object.keys(this.type).forEach((key) => {
      types.push(this.type[key]);
    });
    return types;
  },
  validateNumber(value) {
    const cardNo = value.replace(/ /g, "");
    const type = this.getType(cardNo);
    return (
      type && type.cardLength === cardNo.length && this.validator.luhn(cardNo)
    );
  },
  validateCvc(cardNo, cvcNo) {
    cardNo = cardNo.replace(/ /g, "");
    const type = this.getType(cardNo);
    return type && this.validator.cvc(cvcNo, type);
  },
  validateExpireDate(expirationMonth, expirationYear) {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    expirationMonth = parseInt(expirationMonth);
    expirationYear = parseInt(expirationYear);
    if (expirationMonth > 0 && expirationYear > 0 && expirationYear >= year) {
      if (expirationYear === year) {
        return expirationMonth > month;
      }
      return true;
    }
    return false;
  },
  validator: {
    number(cardNo, type) {
      return type.detector.test(cardNo);
    },
    luhn(cardNo) {
      let numberProduct;
      let checkSumTotal = 0;
      for (
        let digitCounter = cardNo.length - 1;
        digitCounter >= 0;
        digitCounter -= 2
      ) {
        checkSumTotal += parseInt(cardNo.charAt(digitCounter), 10);
        numberProduct = String(cardNo.charAt(digitCounter - 1) * 2);
        for (
          let productDigitCounter = 0;
          productDigitCounter < numberProduct.length;
          productDigitCounter += 1
        ) {
          checkSumTotal += parseInt(
            numberProduct.charAt(productDigitCounter),
            10,
          );
        }
      }
      return checkSumTotal % 10 === 0;
    },
    cvc(cvcNo, type) {
      return type.cvcLength === (`${cvcNo}`).length;
    },
  },
};

function Validator() {
  this.cardInstance = new Card();
}

Validator.prototype = {
  getCardType(cardNumber) {
    return this.isValidCardNumber(cardNumber)
      ? this.cardInstance.getType(cardNumber)
      : false;
  },
  getCardTypeName(name = "") {
    return this.cardInstance.getTypeByName(name);
  },
  getOrderedTypes() {
    return this.cardInstance.getOrderedTypes();
  },
  getList() {
    return this.cardInstance.getList();
  },
  isValidCardNumber(cardNumber) {
    return this.cardInstance.validateNumber(cardNumber);
  },
  isValidSecurityCode(cardNumber, securityCode) {
    return this.cardInstance.validateCvc(cardNumber, securityCode);
  },
  isValidExpireDate(expirationMonth, expirationYear) {
    return this.cardInstance.validateExpireDate(expirationMonth, expirationYear);
  },
  isValidCardData(cardData, error) {
    if (!cardData) {
      error("Cartão inválido");
      return false;
    }
    if (!cardData.cardHolderName || cardData.cardHolderName.length < 5) {
      error("Nome do titular inválido");
      return false;
    }
    if (!this.isValidCardNumber(cardData.cardNumber)) {
      error("Número do cartão inválido");
      return false;
    }
    if (!this.isValidSecurityCode(cardData.cardNumber, cardData.cardSecurityCode)) {
      error("Código de segurança inválido");
      return false;
    }
    if (!this.isValidExpireDate(cardData.expiryMonth, cardData.expiryYear)) {
      error("Data de validade incorreta");
      return false;
    }
    return true;
  },
};

const validator = new Validator();

export default validator;
