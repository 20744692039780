<template>
  <transition appear="appear" name="bounce" mode="out-in">
    <v-btn
      :class="backTopClass"
      :style="backTopStyle"
      :small="$vuetify.breakpoint.xsOnly"
      @click="toTop"
      top
      color="secondary"
      dark
      fixed
      rounded
      v-scroll="onScroll"
      v-show="fab"
    >
      <span class="backTopTextClass">Voltar ao topo</span>
    </v-btn>
  </transition>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "back-to-top",

  data: () => ({
    fab: false,
  }),

  computed: {
    fabIconColor() {
      const theme = this.$helper.lightOrDark(
        this.$vuetify.theme.themes.light.secondary
      );
      return theme === "light" ? "primary--text" : "white--text";
    },

    backTopClass() {
      const textClass = this.fabIconColor;
      return `backToTop ${textClass}`;
    },

    backTopStyle() {
      const w = this.$vuetify.breakpoint.width / 2;
      const el = this.$vuetify.breakpoint.xsOnly ? 137.667 : 163.667;
      const f = el / 2;
      return `
        right: ${w - f}px;
      `;
    },

    backTopTextClass() {
      return "body-2 strong";
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 500;
    },

    toTop() {
      this.$vuetify.goTo(0, { duration: 0 });
    },
  },
});
</script>

<style scoped>
.backToTop {
  top: 24px;
  z-index: 10;
}
.strong {
  font-weight: bold;
}
</style>
