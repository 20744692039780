<template>
  <v-alert
    :color="color"
    :dismissible="dismissible"
    :value="currentValue"
    @input="handleInput"
    class="ma-2"
    text="text"
  >
    <v-row align="center" no-gutters>
      <v-col class="grow">
        {{ text }}
      </v-col>
      <v-col class="shrink" v-if="actionVisible">
        <v-btn :color="color" @click="actionClick" outlined>
          {{ actionText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { useAccountStore } from "@/storex/accountStore";

export default defineComponent({
  name: "app-alerts",

  setup() {
    const accountStore = useAccountStore();

    return {
      accountStore,
    };
  },

  computed: {
    ...mapGetters("checkout", ["pendingOrder"]),

    currentValue() {
      if (this.pendingOrder) {
        const routes = ["checkout", "addresses", "address", "order", "cards"];
        return !routes.includes(this.$route.name);
      }
      return this.accountStore.verificationAlert ?? false;
    },

    text() {
      if (this.pendingOrder) {
        return "Você possui um pedido em aberto. Deseja finalizar sua compra?";
      }
      if (this.accountStore.verificationAlert) {
        return "Seu usuário ainda não foi confirmado, por favor verifique seu e-mail.";
      }
      return "";
    },

    actionText() {
      if (this.pendingOrder) {
        return "Ver pedido";
      }
      return "";
    },

    actionVisible() {
      return this.pendingOrder;
    },

    color() {
      if (this.pendingOrder) {
        return "primary";
      }
      if (this.accountStore.verificationAlert) {
        return "warning";
      }
      return "primary";
    },

    dismissible() {
      return !this.pendingOrder;
    },
  },

  methods: {
    handleInput(e) {
      if (this.pendingOrder) {
        return;
      }
      if (this.accountStore.verificationAlert) {
        this.accountStore.verificationAlert = e;
      }
    },

    actionClick() {
      if (this.pendingOrder) {
        this.$router.push({ name: "checkout" });
      }
    },
  },
});
</script>
