import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    loading: true,
    paymentMethods: {},
    shippingOptions: {},
    subTotal: 0,
    subTotalLabel: "",
    totalWithoutPromoItems: 0,
    userNotes: "",
    recaptcha: false,
    defaultDiscountRule: null,
    discountRules: [],
    currentPostalCode: null,
    stepper: 1,
  }),
  getters,
  actions,
  mutations,
};
