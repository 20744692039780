<template>
  <v-card
    :href="`${baseUrl}/item?id=${item.id}`"
    :height="cardHeight"
    :ripple="false"
    elevation="1"
  >
    <div
      class="pt-3"
      :style="`background: ${hasPicture(computedItem) ? '' : '#eaedf1'};`"
    >
      <v-img
        :height="imageHeight"
        :lazy-src="getPicture(computedItem, true)"
        :src="getPicture(computedItem)"
        contain="contain"
      ></v-img>
    </div>
    <v-card-text>
      <div
        :class="[
          'item-title',
          'grey--text',
          'text--darken-3',
          computedItem.discountInfo ? 'two-lines' : 'three-lines',
        ]"
      >
        {{ $utils.titleCase(computedItem.name) }}
      </div>
      <div
        :class="[
          'item-title',
          'item-subtitle mt-1',
          'grey--text',
          'text--darken-1',
          computedItem.discountInfo ? 'two-lines' : 'three-lines',
        ]"
        v-if="hasAttrs(computedItem)"
      >
        {{ getAttrs(computedItem) }}
      </div>
    </v-card-text>
    <v-card-text style="position: absolute; bottom: 0px">
      <div
        :class="getItemClass(computedItem)"
        :style="getItemStyle(computedItem)"
        v-if="
          (computedItem.promotionalPrice || computedItem.discountInfo) &&
          hasEstoque(computedItem) &&
          !item.listPrice
        "
      >
        {{ getItemValue(computedItem) }}
      </div>
      <div :class="getItemClass()" :style="getItemStyle()">
        {{
          getItemValue(
            computedItem,
            computedItem.promotionalPrice,
            computedItem.discountInfo
          )
        }}
      </div>
      <div
        :class="['green--text', 'discount-info-text']"
        v-if="computedItem.discountInfo"
      >
        {{ computedItem.discountInfo.label }}
      </div>
      <div
        :class="['grey--text', 'text--darken-3', 'discount-info-subtext']"
        v-if="computedItem.discountInfo"
      >
        ou {{ getItemSubValue(computedItem) }} nas demais formas
      </div>
    </v-card-text>
    <v-btn
      :class="cartBtnClass"
      @click.stop="handleAddCart"
      color="primary"
      icon
      title="Por no carrinho"
      v-if="hasEstoque(computedItem)"
    >
      <v-icon>mdi-cart-arrow-down</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { getDiscountInfo } from "@/lib/items";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "item-card",
  props: ["item"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    ...mapGetters("checkout", ["defaultDiscountRule"]),

    computedItem() {
      if (!this.defaultDiscountRule) {
        return this.item;
      }
      return getDiscountInfo(this.item, this.defaultDiscountRule);
    },

    cardHeight() {
      return "352px";
    },

    imageHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.computedItem.discountInfo ? "150px" : "180px";
      }
      return "180px";
    },

    cartBtnClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "cart-btn cart-btn-sm";
      }
      return "cart-btn cart-btn-md";
    },

    baseUrl() {
      return `${this.$store.getters.baseUrl}/store`;
    },
  },

  methods: {
    getItemValue(v, promotionalPrice = null, discountInfo = null) {
      if (!this.hasEstoque(v)) {
        return this.$t("store.button.unavailable");
      }
      if (promotionalPrice || discountInfo) {
        const price = discountInfo?.price || v.listPrice || promotionalPrice;
        return this.$i18n.locale === "en"
          ? this.$utils.USD(price)
          : this.$utils.BRL(price);
      }
      return this.$i18n.locale === "en"
        ? this.$utils.USD(v.listPrice || v.price)
        : this.$utils.BRL(v.listPrice || v.price);
    },

    getItemSubValue(v) {
      const val = v.listPrice || v.promotionalPrice || v.price;
      return this.$i18n.locale === "en"
        ? this.$utils.USD(val)
        : this.$utils.BRL(val);
    },

    getItemClass(v = {}) {
      if (v.promotionalPrice || v.discountInfo) {
        return "grey--text text--darken-1";
      }
      return "grey--text text--darken-3";
    },

    getItemStyle(v = {}) {
      if (v.promotionalPrice || v.discountInfo) {
        return `
          font-size: 0.8rem;
          text-decoration: line-through;
          line-height: 15px;
        `;
      }
      return `
        font-size: 1.15rem;
        font-weight: 600;
        line-height: 26px;
      `;
    },

    hasEstoque(v) {
      if (this.config.ignoreStock) {
        return true;
      }
      if (!v?.stockQuantity || v.stockQuantity < 1) {
        return false;
      }
      return true;
    },

    getAttrs(v) {
      const attrs = v.attrs ? JSON.parse(v.attrs) : [];
      return attrs.map((a) => a.value).join(" ");
    },

    hasAttrs(v) {
      return v.attrs ? !!JSON.parse(v.attrs)?.[0]?.value : false;
    },

    getPicture(v, min) {
      const uri = JSON.parse(v.images);
      const source = uri[0]
        ? uri[0]
        : require("@/assets/shop-placeholder-min.png");
      if (min && uri[0]) {
        return source.replace(".jpg", "-min.jpg");
      }
      return source;
    },

    hasPicture(v) {
      const uri = JSON.parse(v.images);
      return !!uri?.length;
    },

    handleAddCart(e) {
      e.preventDefault();
      this.$emit("add-cart");
    },
  },
});
</script>

<style scoped>
.item-title {
  /* font-size: 1.3em; */
  font-size: 0.98rem;
  line-height: 20px;
  margin-left: -4px;
  margin-right: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.two-lines {
  -webkit-line-clamp: 2; /* number of lines to show */
}
.three-lines {
  -webkit-line-clamp: 3; /* number of lines to show */
}
.item-subtitle {
  font-size: 15px;
  line-height: 16px;
}
.cart-btn {
  background: white;
  position: absolute;
}
.cart-btn-sm {
  bottom: 8px;
  right: 4px;
}
.cart-btn-md {
  bottom: 10px;
  right: 12px;
}

.discount-info-text {
  font-size: 0.78rem;
  line-height: 15px;
  margin-right: -10px;
  font-weight: 500;
}
.discount-info-subtext {
  font-size: 0.7rem;
  line-height: 15px;
}
</style>
