<template>
  <v-carousel
    :height="carouselHeight"
    :show-arrows="false"
    cycle="cycle"
    hide-delimiter-background="hide-delimiter-background"
    interval="4000"
    v-if="visible && banners"
  >
    <v-carousel-item :key="`banner-${i}`" v-for="(banner, i) in banners">
      <v-img :src="banner.src" height="100%"></v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { defineComponent } from "vue";
import { useConfigStore } from "@/storex/configStore";

export default defineComponent({
  name: "banners",
  props: ["visible"],

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    banners() {
      if (!this.config.storeBanners) {
        return null;
      }
      const banners = this.config.storeBanners;
      let list;
      if (this.$vuetify.breakpoint.xsOnly) {
        list = banners.mobile?.length ? banners.mobile : banners.web;
      } else {
        list = banners.web?.length ? banners.web : banners.mobile;
      }
      return list?.length ? list : null;
    },

    carouselHeight() {
      const { width } = this.$vuetify.breakpoint;

      if (this.$vuetify.breakpoint.xsOnly) {
        const h16 = this.$utils.consts.ASPECT_16_9 * width;
        return `${h16 * this.$utils.consts.ASPECT_CALC_16_9}px`;
      }

      const h = this.$utils.consts.ASPECT_43_9 * width;
      return `${h * this.$utils.consts.ASPECT_CALC_43_9}px`;
    },
  },
});
</script>
