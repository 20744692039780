<template>
  <v-snackbar
    :color="$vuetify.theme.dark ? '#181818' : color || '#212121'"
    :multi-line="multiline"
    :timeout="duration"
    :bottom="!top"
    :top="top"
    v-model="visible"
    elevation="16"
  >
    {{ textComputed }}
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="$vuetify.theme.dark ? 'white' : action.textColor || 'b2b2df'"
        @click="onActionPress"
        text="text"
        v-bind="attrs"
        v-if="action.text"
        >{{ actionTextComputed }}</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "snackbar",
  data() {
    return {
      visible: false,
      action: {},
      color: null,
      duration: 3000,
      multiline: false,
      top: false,
      text: "",
      t: null,
      onDismiss: null,
    };
  },

  mounted() {
    this.$events.$on("snackbar", this.handleEvent);
  },

  beforeDestroy() {
    this.$events.$off("snackbar", this.handleEvent);
  },

  computed: {
    actionTextComputed() {
      return this.action.t ? this.$t(this.action.t) : this.action.text;
    },

    textComputed() {
      return this.t ? this.$t(this.t) : this.text;
    },
  },

  watch: {
    visible(v) {
      if (!v && this.onDismiss) {
        this.action = {};
        setTimeout(this.onDismiss, 250);
      }
    },
  },

  methods: {
    onActionPress() {
      this.visible = false;
      if (this.action && this.action.onPress) {
        setTimeout(() => this.action.onPress(), 100);
      }
    },

    handleEvent(e) {
      if (e.dismiss) {
        this.visible = false;
      } else {
        if (this.visible) {
          this.visible = false;
        }
        this.$nextTick(() => {
          if (e.action.text || e.action.t) {
            this.action = e.action;
          } else {
            this.action = {};
          }
          this.color = e.color;
          this.duration = e.duration;
          this.multiline = e.multiline;
          this.top = e.top;
          this.onDismiss = e?.onDismiss;
          this.t = e.t;
          this.text = e.text;
          this.visible = true;
        });
      }
    },
  },
});
</script>
