<template>
  <div v-if="amount">
    <v-banner class="v-banner--is-mobile" sticky="sticky">
      <span
        @click="handleClick"
        class="body-2 info--text"
        v-if="!hideDetails"
        style="cursor: pointer"
        >{{ title }}</span
      >

      <div v-if="locationStore.deliveryDeadline">
        <span class="body-2">
          Prazo de Entrega <b>{{ locationStore.deliveryDeadline }}</b>
        </span>
      </div>

      <br v-if="frete < 0 && !restante && !minimo" />

      <div @click="handleClick" class="mt-2 progress" v-else>
        <v-progress-linear
          :value="locationStore.compraMinima"
          color="orange"
          height="20"
          style="flex: 1"
          v-if="!indeterminate && locationStore.compraMinima"
        />
        <v-progress-linear
          :indeterminate="indeterminate"
          :value="locationStore.compraGratis"
          color="#66BB6A"
          height="20"
          style="flex: 4"
        >
          <strong style="color: #154b19" v-if="frete > 0">
            Valor do frete {{ $utils.decimalBRL(frete) }}
          </strong>
        </v-progress-linear>

        <v-icon class="ml-3" color="#66BB6A">{{ iconComputed }}</v-icon>
      </div>

      <span class="body-2" v-if="indeterminate">Carregando...</span>

      <span class="body-2" v-else-if="minimo || restante">
        <template v-if="!minimo && restante">
          <span>Compre mais</span>
          <span class="strong-green">{{ restanteComputed }}</span>
          <span>e ganhe {{ " " }}</span>
          <span class="strong-green">FRETE GRÁTIS</span>
        </template>

        <template v-else>
          <span>Compre mais</span>
          <span class="strong-orange">{{ minimoComputed }}</span>
          <span>e receba no {{ " " }}</span>
          <span class="strong-orange">seu endereço {{ " " }}</span>

          <template v-if="restante">
            <br />
            <span>E mais {{ " " }}</span>
            <span class="strong-green">{{ restanteComputed }}</span>
            <span>e ganhe {{ " " }}</span>
            <span class="strong-green">FRETE GRÁTIS</span>
          </template>
        </template>
      </span>

      <span class="body-2" v-else>
        <span v-if="!data.postalCode">CEP não informado</span>
        <span class="strong-green" v-else-if="frete === 0">FRETE GRÁTIS</span>
        <span v-else-if="frete === -1">Não entregamos nesta região</span>
      </span>

      <v-checkbox
        :input-value="useLocationCheckout"
        @change="onUseLocationCheck"
        class="ml-1"
        dense
        v-if="checkboxVisible"
      >
        <template #label>
          <div :class="checkboxClass">
            Utilizar minha localização atual como destino de entrega
          </div>
        </template>
      </v-checkbox>
      <div :class="locationBtnClass">
        <v-icon slot="icon" color="error" size="24" v-if="locationBtnAlert"
          >mdi-alert-circle</v-icon
        >
        <v-spacer />
        <location-button
          :position="data"
          @local-click="getCurrentPosition"
          large
          v-if="useLocationCheckout && checkboxVisible"
        />
      </div>
    </v-banner>
  </div>

  <div @click="handleClick" style="cursor: pointer" v-else>
    <v-banner single-line="single-line" sticky="sticky">
      <span class="caption" style="color: #424242">{{ title }}</span>
      <template #actions>
        <location-button :position="data" @local-click="getCurrentPosition" />
      </template>
    </v-banner>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useLocationStore } from "@/storex/checkout/locationStore";
import postalCodeData from "@/http/postalCodeData";
import LocationButton from "./LocationButton";

export default defineComponent({
  name: "app-cep",
  props: {
    amount: Number,
    currentPostalCode: String,
    addressId: Number,
    hideDetails: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LocationButton,
  },

  setup() {
    const locationStore = useLocationStore();
    return { locationStore };
  },

  mounted() {
    this.loadData();
  },

  computed: {
    data() {
      return this.locationStore.data;
    },

    frete() {
      return this.locationStore.frete;
    },

    minimo() {
      return this.locationStore.minimo;
    },

    restante() {
      return this.locationStore.restante;
    },

    indeterminate() {
      return this.locationStore.indeterminate;
    },

    useLocationCheckout() {
      return this.locationStore.useLocationCheckout;
    },

    iconComputed() {
      return this.frete === 0 && !this.indeterminate
        ? "mdi-truck-check"
        : "mdi-truck-outline";
    },

    checkboxClass() {
      if (this.useLocationCheckout) {
        return "grey--text text--darken-4 body-2";
      }
      return "grey--text text--darken-1 body-2";
    },

    checkboxVisible() {
      return this.showCheckbox && !this.indeterminate && this.frete > -1;
    },

    locationBtnAlert() {
      return (
        this.useLocationCheckout && this.checkboxVisible && !this.data?.coords
      );
    },

    locationBtnClass() {
      return this.locationBtnAlert ? "location-btn-alert" : "location-btn";
    },

    title() {
      if (this.data.postalCode) {
        const mun = `Enviar para ${this.data.city}`;
        const state = this.data.state;
        const postalCode = this.$utils.cepMask(this.data.postalCode);
        if (!this.data.city) {
          return `Enviar para ${postalCode}`;
        }
        return `${mun} ${state} - ${postalCode}`;
      }

      if (this.data.coords) {
        return "Local salvo (informar CEP)";
      }

      return "Informar CEP para envio";
    },

    restanteComputed() {
      return ` ${this.$utils.decimalBRL(this.restante)} `;
    },

    minimoComputed() {
      return ` ${this.$utils.decimalBRL(this.minimo)} `;
    },
  },

  watch: {
    amount() {
      this.locationStore.cleanFrete();
      this.calcFrete();
    },
  },

  methods: {
    loadData() {
      const userLocation = this.locationStore.userLocation;
      if (userLocation) {
        this.locationStore.data = { ...userLocation };
        this.calcFrete(true);
      } else if (this.currentPostalCode) {
        this.calcFrete(true);
      }
      setTimeout(() => {
        !this.data.postalCode && this.locationStore.setIndeterminate(false);
      }, 400);
    },

    handleClick() {
      this.$inputAlert.show({
        value: this.$utils.cepMask(this.data.postalCode, true),
        type: "postalCode",
        label: "CEP para envio",
        actions: [
          { t: "alert.button.cancel" },
          {
            t: "alert.button.confirm",
            onPress: (v) => this.inputResult(v),
          },
        ],
      });
    },

    async calcFrete(init = false) {
      if (this.amount && this.currentPostalCode && init) {
        this.inputResult(this.currentPostalCode);
        return;
      }

      try {
        await this.locationStore.calcFrete(this.amount, this.addressId);
        this.$nextTick(() => {
          this.$emit("shipping-change", this.locationStore.frete);
        });
      } catch {
        this.$emit("shipping-change", -1);
      } finally {
        setTimeout(() => this.locationStore.setIndeterminate(false), 100);
      }
    },

    async inputResult(postalCode) {
      try {
        if (!postalCode || postalCode.length < 8) {
          return;
        }
        this.locationStore.cleanFrete();

        postalCode = window.$utils.cepMask(postalCode, true);
        const values = await postalCodeData(postalCode);

        values.postalCode = postalCode;
        values.coords = this.data.coords;
        this.locationStore.data = values;
        this.locationStore.setUserLocation(values);
      } catch {
        // ingore
      } finally {
        this.calcFrete();
      }
    },

    onValidatePosition() {
      if (this.data?.coords) {
        return;
      }
      const onPress = () => this.getCurrentPosition();
      const onCancel = () => this.locationStore.setLocationCheckout(false);
      const t = [
        "Será necessário definir no mapa o local",
        "onde quer receber a sua encomenda",
      ];

      this.$alert.show(
        {
          text: t.join(" "),
          actions: [
            { text: "Cancelar", onPress: onCancel },
            { text: "Abrir mapa", onPress },
          ],
        },
        400
      );
    },

    getCurrentPosition() {
      this.$location.show({
        position: this.data?.coords,
        positionCallback: this.handleCurrentPosition,
      });
    },

    handleCurrentPosition(coords) {
      if (!coords?.latitude) {
        return;
      }
      this.locationStore.setCurrentPosition(coords);
      this.$snackbar.show({
        text: "Sua localização foi salva",
        action: {
          text: "OK",
        },
      });
    },

    onUseLocationCheck(event) {
      this.locationStore.setLocationCheckout(!!event);
      event && setTimeout(this.onValidatePosition, 100);
    },
  },
});
</script>

<style scoped>
.strong-green {
  color: #66bb6a;
  font-weight: bold;
}

.strong-orange {
  color: #ef6c00;
  font-weight: bold;
}

.location-btn {
  display: flex;
  flex-direction: row;
}

.location-btn-alert {
  display: flex;
  flex-direction: row;
  border: 2px solid rgba(255, 82, 82, 0.4);
  border-radius: 6px;
  padding: 6px;
}

.progress {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
</style>
