import { defineStore } from "pinia";
import storage from "@/plugins/storage";
import schema from "@/http/apollo/schema";
import { handleCategories } from "@/lib/category";

const Smart = window.Smart || {};
const CATEGORIES_KEY = "store_categories";

const loadCategories = () => {
  const data = storage.getItem(CATEGORIES_KEY);
  return data ? JSON.parse(data) : null;
};

export const useCategoriesStore = defineStore("categoriesStore", {
  state: () => ({
    loading: false,
    rawData: loadCategories(),
  }),

  getters: {
    data: (state) => state.rawData || [],
    loaded: (state) => !!state.rawData,
  },

  actions: {
    async fetchData() {
      try {
        this.loading = true;
        const {
          data: { categories },
        } = await window.$apollo.query({
          query: schema.store.CATEGORIES_ALL,
          variables: {
            storeId: Smart.store?.id,
            orderBy: [
              { column: "parent_id", order: "DESC" },
              { column: "sequence", order: "ASC" },
              { column: "name", order: "ASC" },
            ],
          },
        });

        if (categories) {
          this.rawData = handleCategories(categories);
          storage.setItem(CATEGORIES_KEY, JSON.stringify(this.rawData));
        } else {
          this.rawData = [];
          storage.removeItem(CATEGORIES_KEY);
        }
      } finally {
        this.loading = false;
      }
    },
  },
});