<template>
  <v-dialog
    v-model="visible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat>
        <v-spacer />
        <v-icon size="30" color="light-blue">mdi-motorbike-electric</v-icon>
        <v-spacer />
      </v-toolbar>

      <v-card-title>
        <v-spacer />
        <span>Seu pedido está a caminho</span>
        <v-spacer />
      </v-card-title>

      <v-card-text class="pb-0">
        <p class="text-center">Acompanhe o andamento da entrega pelo mapa</p>
      </v-card-text>

      <v-container>
        <map-view
          :position="position"
          :customMarker="marker"
          class="delivery-modal"
          v-if="mapVisible"
        />

        <v-card-actions :style="bottomStyle">
          <v-spacer />
          <v-btn @click="visible = false" color="light-blue" text>Fechar</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import MapView from "./MapView";
import DeliveryIcon from "../assets/motorbike-electric.png";
import { isTokenValid } from "../http/apollo/anonymously";

const baseUrl = window.Smart.env.WEBSOCKET;

export default defineComponent({
  name: "delivery-modal",
  components: {
    MapView,
  },

  data: () => ({
    connected: false,
    loading: true,
    mapVisible: false,
    orderId: null,
    position: null,
    visible: false,
    marker: {
      icon: DeliveryIcon,
    },
  }),

  created() {
    this.nudgeTimeoutId = -1;
    this.$events.$on("delivery-modal", this.handleEvent);
  },
  beforeDestroy() {
    this.$events.$off("delivery-modal", this.handleEvent);
  },

  computed: {
    bottomStyle() {
      const bottom = this.$vuetify.breakpoint.xsOnly ? "34px" : "20px";
      let mr = "0";
      if (this.$vuetify.breakpoint.mdAndUp) {
        mr = "32px";
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        mr = "64px";
      }

      return `
        bottom: ${bottom};
        flex-direction: row;
        left: 0;
        margin-right: ${mr};
        position: fixed;
        right: 0;
      `;
    },
  },

  watch: {
    visible(v) {
      this.$nextTick(() => this.$store.commit("delivery-modal", v));
      setTimeout(() => {
        this.mapVisible = v;
      }, 400);

      if (v) {
        this.loading = true;
        this.connect();
      } else {
        this.disconnect();
      }
    },
  },

  methods: {
    handleEvent(e) {
      if (!e) {
        this.visible = false;
      } else {
        this.orderId = e.orderId;
        this.visible = true;
      }
    },

    async connect() {
      try {
        if (!isTokenValid(this.$store.getters.accessToken)) {
          await this.$store.dispatch("refresh");
        }

        if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
          return;
        }

        const token = this.$store.getters.accessToken;
        const id = this.orderId;
        const url = `${baseUrl}/delivery/${id}?t=${token}`;
        this.ws = new WebSocket(url);

        this.ws.onmessage = (e) => {
          const data = e?.data ? JSON.parse(e.data) : null;
          this.handleMessage(data);
        };

        this.ws.onopen = () => {
          this.connected = true;
          this.loading = false;
        };

        this.ws.onclose = () => {
          this.connected = false;
        };
      } catch (e) {
        this.$snackbar.show({
          text: "Não foi possível conectar",
          action: { text: "OK" },
        });
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    },

    disconnect() {
      if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
        this.ws.close();
      }
    },

    handleMessage({ type, data }) {
      if (!type) {
        return;
      }

      if (type === "ping") {
        this.sendMessage(JSON.stringify({ type: "pong" }));
        return;
      }

      if (type === "location") {
        const { latitude, longitude } = data.coords;
        this.position = { latitude, longitude };
        return;
      }

      if (type === "chat") {
        this.snackbar(`Chat message: ${data.message}`);
      }
    },

    sendMessage(data) {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(data);
      }
    },

    snackbar(text) {
      this.$snackbar.show({
        text,
        action: { text: "OK" },
      });
    },
  },
});
</script>

<style scoped>
.icon-left {
  visibility: hidden;
}

.delivery-modal {
  margin: 10px 0;
}
</style>
