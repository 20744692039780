<template>
  <div>
    <transition appear="appear" name="bounce" mode="out-in" v-if="whatsapp">
      <v-btn
        class="white--text"
        :color="$colors.whatsapp"
        :style="fabStyle"
        @click="openWhatsapp"
        bottom
        dark
        fab
        fixed
        key="whatsapp"
        right
      >
        <v-icon size="28">mdi-whatsapp</v-icon>
      </v-btn>
    </transition>
    <back-to-top />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BackToTop from "@/components/BackToTop";
import { useConfigStore } from "@/storex/configStore";
import { postMessageSync, MessageEvents } from "../../plugins/nativeBridge";

export default defineComponent({
  name: "fab-button-whatsapp",
  components: {
    BackToTop,
  },

  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    isCheckoutLayout() {
      return (
        (this.$route.path.includes("checkout") ||
          this.$route.query?.redirect?.includes("checkout")) &&
        !this.$route.path.includes("login-options")
      );
    },

    fabStyle() {
      if (this.isCheckoutLayout) {
        return "z-index: 10; bottom: 44px;";
      }
      const v = this.$vuetify.breakpoint.xsOnly ? 90 : 36;
      return `z-index: 10; bottom: ${v}px;`;
    },

    whatsapp() {
      return this.config.storeDetails?.whatsapp;
    },
  },

  methods: {
    openWhatsapp() {
      if (this.whatsapp) {
        const smAndDown = this.$vuetify.breakpoint.smAndDown;
        const tel = this.whatsapp.replace(/\D/g, "");
        const endpoint = `.whatsapp.com/send?l=pt_BR&phone=${tel}`;
        const api = `https://${smAndDown ? "api" : "web"}${endpoint}`;
        const onPress = () => {
          setTimeout(() => this.openUrl(api), 300);
        };
        this.$alert.show({
          text: "Iniciar um atendimento pelo whatsapp?",
          actions: [{ text: "Abrir whatsapp", onPress }],
        });
      }
    },

    openUrl(url) {
      if (window.isNativeApp) {
        postMessageSync(MessageEvents.OPEN_URL, url);
      } else {
        window.open(url, "_blank");
      }
    },
  },
});
</script>
